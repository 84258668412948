import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: true,
  comment_payload: [],
  errorMsg: null,
};

const commentSlice = createSlice({
  name: REDUCER.COMMENT,
  initialState,
  reducers: {
    COMMENT_REQUEST(state) {
      state.loading = true;
    },
    COMMENT_SUCCESS(state, action) {
      state.loading = false;
      state.comment_payload = action.payload.data;
      state.errorMsg = null;
    },
    COMMENT_UPDATE_SUCCESS(state, action) {
      const updatedComments = state.comment_payload.concat(action.payload.data);
      state.loading = false;
      state.comment_payload = updatedComments;
    },
    COMMENT_FAILED(state, action) {
      state.loading = false;
      state.comment_payload = [];
      state.errorMsg = action.payload;
    },
  },
});

export const commentActions = commentSlice.actions;

export default commentSlice;
