import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: true,
  shineToggle: false,
  popular_timeline_payload: [],
  upcoming_payload: [],
  errorMsg: null,
};

const popularTimelineSlice = createSlice({
  name: REDUCER.TIMELINE,
  initialState,
  reducers: {
    TIMELINE_REQUEST(state) {
      state.loading = true;
    },
    POPULAR_TIMELINE_SUCCESS(state, action) {
      state.loading = false;
      state.popular_timeline_payload = action.payload;
      state.shineToggle = action.payload;
      state.errorMsg = null;
    },
    UPCOMING_SUCCESS(state, action) {
      state.loading = false;
      state.upcoming_payload = action.payload;
      state.errorMsg = null;
    },
    UPCOMING_FAIL(state, action) {
      state.loading = false;
      state.upcoming_payload = [];
      state.errorMsg = action.payload;
    },

    POPULAR_TIMELINE_FAIL(state, action) {
      state.loading = false;
      state.shineToggle = false;
      state.errorMsg = action.payload;
    },
  },
});

export const popularTimelineActions = popularTimelineSlice.actions;

export default popularTimelineSlice;
