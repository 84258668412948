import { api, BASEURL } from "../../../services/api_routes";
import { sharedActions } from "./sharedSlice";

export const getShared = (userId) => {
  return async (dispatch) => {
    dispatch(sharedActions.SHARED_REQUEST());

    try {
      const response = await fetch(`${BASEURL}${api.shared}?userId=${userId}`);

      response.json().then((success) => {
        dispatch(sharedActions.SHARED_SUCCESS(success.data));
      });
    } catch (error) {
      dispatch(sharedActions.SHARED_FAILED(error.message));
    }
  };
};

// https://us-central1-cheers2heroes-245a3.cloudfunctions.net/api/users/mySharedStories?userId=UEUOZ8JEJkUzbYqcPEPptdXf5bp1
