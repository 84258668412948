import React from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../persistence/redux/modal/modalSlice";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import Shine from "../component/svgs/ShineAltSVG";

const ExploreSlideShow = ({ popular }) => {
  const dispatch = useDispatch();

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };
  const SlickWrapper = styled.div`
    width: 100%;
    margin: auto;
    border-radius: 2.9375rem;
    .card-container {
      width: 20%;
      padding: 10px;
    }

    .slick-initialized .slick-slide {
      padding: 10px;
      margin: 0;
      width: 100%;
      height: 100%;
    }

    .slick-slider .slick-track {
      display: flex;
    }

    .slick-slide img {
      width: 100%;
    }

    .content {
      background-color: white;
      padding: 1rem 1rem 3rem;
      border-bottom-left-radius: 0.9375rem;
      border-bottom-right-radius: 0.9375rem;
  
      p {
        font-size: 1.3rem;
      }
    
    }
    .content-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 1.5rem;

      .liked {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          margin-right: 1rem;
        }
      }
    }

        @media (max-width: 480px) {
     .slick-slider {
    width: 100% !important;

}
    }

    @media (min-width: 600px) {
      .slick-initialized .slick-slide {
        display: flex;
        background-color
    

      }
    }
  `;

  const upcomingItems = popular?.map((uc, i) => {
    const Shortener = () => {
      return uc?.description?.length > 250
        ? uc?.description?.substring(0, 100) + "..."
        : uc?.description;
    };
    return (
      <div className="card-container" key={uc?.id}>
        <img
          src={uc?.media[0]?.url}
          loading="lazy"
          width="486"
          height="324"
          alt=""
        />
        <div className="content">
          <div className="content-row">
            <h2>{uc?.title}</h2>
            <div className="liked">
              <p>{uc.totalLikes}</p>
              <Shine width="30px" />
            </div>
          </div>

          <p>{Shortener()}</p>
        </div>
      </div>
    );
  });

  return (
    <SlickWrapper>
      <Slider {...settings}>{upcomingItems}</Slider>
    </SlickWrapper>
  );
};

export default ExploreSlideShow;
