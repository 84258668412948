import React from "react";
import styled from "styled-components";

const ShineAltSVG = ({
  width = 40,
  height = 40,
  colorFill = "white",
  ...props
}) => {
  const SVGWrapper = styled.div`
    .active {
      fill: #10405c;
    }

    @media (min-width: 960px) {
      .active {
        fill: var(--color-grey-dark);
      }
      .active--inner {
        fill: var(--color-primary);
      }
    }
  `;
  return (
    <SVGWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 28.109 28.109"
      >
        <path
          id="Path_4010"
          data-name="Path 4010"
          d="M14.054,0A14.054,14.054,0,1,1,0,14.054,14.054,14.054,0,0,1,14.054,0Z"
          fill="#2cb7d2"
          opacity="30%"
        />
        <g
          id="Group_10270"
          data-name="Group 10270"
          transform="translate(8.09 8.09)"
        >
          <circle
            id="Ellipse_186"
            data-name="Ellipse 186"
            cx="3.442"
            cy="3.442"
            r="3.442"
            transform="translate(2.581 2.581)"
            fill="#2cb7d2"
          />
          <circle
            id="Ellipse_187"
            data-name="Ellipse 187"
            cx="0.86"
            cy="0.86"
            r="0.86"
            transform="translate(5.163 0)"
            fill="#2cb7d2"
          />
          <circle
            id="Ellipse_193"
            data-name="Ellipse 193"
            cx="0.86"
            cy="0.86"
            r="0.86"
            transform="translate(2.266 1.237) rotate(-30)"
            fill="#2cb7d2"
          />
          <circle
            id="Ellipse_197"
            data-name="Ellipse 197"
            cx="0.86"
            cy="0.86"
            r="0.86"
            transform="translate(0.377 3.757) rotate(-60)"
            fill="#2cb7d2"
          />
          <circle
            id="Ellipse_190"
            data-name="Ellipse 190"
            cx="0.86"
            cy="0.86"
            r="0.86"
            transform="translate(12.047 5.163) rotate(90)"
            fill="#2cb7d2"
          />
          <circle
            id="Ellipse_192"
            data-name="Ellipse 192"
            cx="0.86"
            cy="0.86"
            r="0.86"
            transform="translate(10.81 2.266) rotate(60)"
            fill="#2cb7d2"
          />
          <circle
            id="Ellipse_196"
            data-name="Ellipse 196"
            cx="0.86"
            cy="0.86"
            r="0.86"
            transform="translate(8.289 0.377) rotate(30)"
            fill="#2cb7d2"
          />
          <circle
            id="Ellipse_188"
            data-name="Ellipse 188"
            cx="0.86"
            cy="0.86"
            r="0.86"
            transform="translate(5.163 10.326)"
            fill="#2cb7d2"
          />
          <circle
            id="Ellipse_191"
            data-name="Ellipse 191"
            cx="0.86"
            cy="0.86"
            r="0.86"
            transform="translate(7.429 10.18) rotate(-30)"
            fill="#2cb7d2"
          />
          <circle
            id="Ellipse_195"
            data-name="Ellipse 195"
            cx="0.86"
            cy="0.86"
            r="0.86"
            transform="translate(9.319 8.919) rotate(-60)"
            fill="#2cb7d2"
          />
          <circle
            id="Ellipse_189"
            data-name="Ellipse 189"
            cx="0.86"
            cy="0.86"
            r="0.86"
            transform="translate(1.721 5.163) rotate(90)"
            fill="#2cb7d2"
          />
          <circle
            id="Ellipse_194"
            data-name="Ellipse 194"
            cx="0.86"
            cy="0.86"
            r="0.86"
            transform="translate(1.867 7.43) rotate(60)"
            fill="#2cb7d2"
          />
          <circle
            id="Ellipse_198"
            data-name="Ellipse 198"
            cx="0.86"
            cy="0.86"
            r="0.86"
            transform="translate(3.127 9.319) rotate(30)"
            fill="#2cb7d2"
          />
        </g>
      </svg>
    </SVGWrapper>
  );
};

export default ShineAltSVG;
