import { BASEURL, api } from "../../../services/api_routes";
import { FETCH_OPTIONS } from "../../../constants/appConstants";
import { singleTimelineActions } from "./singleTimelineSlice";
import { modalActions } from "../modal/modalSlice";
import { timelineActions } from "./timelineSlice";

export const createNewTimeline = (newTimeline) => {
  return async (dispatch) => {
    dispatch(singleTimelineActions.SINGLETIMELINE_REQUEST());

    try {
      const response = await fetch(
        `${BASEURL}${api.timeline}`,
        FETCH_OPTIONS.postData(newTimeline, null, null)
      );
      response.json().then((success) => {
        const createdTimeline = {
          time: "Now",
          stories: [success.data],
        };

        dispatch(timelineActions.ADD_NEW_TIMELINE(createdTimeline));
        dispatch(singleTimelineActions.SINGLETIMELINE_SUCCESS(createdTimeline));
        dispatch(modalActions.DISMISS_MODAL());
      });
    } catch (error) {
      dispatch(singleTimelineActions.SINGLETIMELINE_FAILED(error.message));
    }
  };
};

export const getSingleTimeline = (timelineId, userId) => {
  return async (dispatch) => {
    dispatch(singleTimelineActions.SINGLETIMELINE_REQUEST());

    let url = "";
    if (userId !== null) {
      url = `${BASEURL}${api.timeline}/${timelineId}?userId=${userId}`;
    } else {
      url = `${BASEURL}${api.timeline}/${timelineId}`;
    }
    const response = await fetch(url);
    response
      .json()
      .then((success) => {
        dispatch(singleTimelineActions.SET_SINGLETIMELINE(success.data));
      })
      .catch((error) => {
        dispatch(singleTimelineActions.SINGLETIMELINE_FAILED(error.message));
      });
  };
};

export const searchUsers = (searchtem) => {
  return async (dispatch) => {
    let url = `${BASEURL}${api.users}/search/?searchTerm=${searchtem}`;

    const response = await fetch(url);
    response
      .json()
      .then((success) => {
        dispatch(singleTimelineActions.SEARCH_USER_SUCCESS(success.data));
      })
      .catch((error) => {});
  };
};
