import React, { useContext } from "react";
import ThemeContext from "../../context/themeContext";
import logo from "../../assets/icons/C2H_logo.png";
import { motion } from "framer-motion";

import styled from "styled-components";

const SplashWrapper = styled.section`
  height: 100vh;
  display: flex;
  background-color: var(--color-white);
  align-items: center;
  justify-content: center;
  div h2 {
    color: var(--color-primary);
  }
  .outer_progbar {
    margin-top: 2rem;
    width: 100%;
    background-color: var(--color-primary);
    height: 3px;
    overflow: hidden;
    .inner_progbar {
      width: 30%;
      background-color: var(--color-secondary);
      height: 3px;
    }
  }
`;

const Splash = () => {
  const {} = useContext(ThemeContext);

  return (
    <SplashWrapper>
      <div>
        <img src={logo} alt="" />
        <h2>Cheers2Heroes</h2>
        <div className="outer_progbar">
          <motion.div
            initial={{ width: 0 }}
            transition={{
              duration: 5,
              times: [0, 0.2, 0.25, 0.5, 0.6, 0.8, 1],
            }}
            animate={{
              width: ["0%", "20%", "25%", "50%", "60%", "80%", "100%"],
            }}
            className="inner_progbar"
          ></motion.div>
        </div>
      </div>
    </SplashWrapper>
  );
};

export default Splash;
