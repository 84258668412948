import React from "react";
import styled from "styled-components";

const TimelineSearchStyle = styled.div`
  background-color: var(--color-grey-dark);
  margin-bottom: 0.625rem;
  padding: 0.7rem;
  display: flex;
  align-items: center;
  .img-container {
    width: 80px;
    height: 80px;
    border-radius: 7px;
    overflow: hidden;
    margin-right: 1rem;
    flex-shrink: 0;
  }

  h2 {
    color: var(--color-primary);
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 1.2rem;
    color: var(--color-primary);
  }
  @media (min-width: 600px) {
  }
`;
const TimelineSearch = ({ postResult }) => {
  // let singleImage;
  // if (postResult.media[index]?.type === "image") {
  //   singleImage = postResult.media[index].url;
  // }

  const imageFilter = () => {
    if (postResult.media.length === 0) return;
    else {
      const _imageList = postResult.media.filter(
        (mObj) => mObj.type === "image"
      );
      if (_imageList.length === 0) {
        return;
      }
      return _imageList[0]?.url ?? "";
    }
  };

  return (
    <TimelineSearchStyle>
      <div className="img-container">
        <img src={imageFilter()} alt="" width="100%" height="100%" />
      </div>
      <div>
        <p>
          <h2>{postResult.title}</h2>
          {postResult.description}
        </p>
      </div>
    </TimelineSearchStyle>
  );
};

export default TimelineSearch;
