import React from "react";
import styled from "styled-components";
import Shine from "../svgs/ShineAltSVG";

const ExploreWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 0;
  margin-bottom: 10px;
  page-break-inside: avoid;
  .content-holder {
    ${(props) => props.theme.main};
    background-color: #17ad90;
  }

  /* border-radius: 2rem; */
  /* padding-bottom: 2rem; */
  /* height: 100vh; */
`;
const ExploreImgContainer = styled.div`
  border-radius: 10px;
  img {
    border-radius: 10px;
  }
  /* height: 200px; */
  /* overflow: hidden; */
  /* border: 1px solid red; */
`;
const ExploreTimelineNoImage = ({ timeline }) => {
  return (
    <ExploreWrapper>
      <div>
        <div
          className="content-holder"
          style={{ padding: "1rem", color: "white" }}
        >
          <h2 style={{ color: "white", marginBottom: ".9rem" }}>
            {timeline.title}
          </h2>
          <div>{timeline.description}</div>
        </div>
        <div
          style={{
            display: "flex",
            marginLeft: "1rem",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "1rem" }}>
            <Shine width="25px" />
          </div>
          <div>
            <p>{timeline.totalLikes}</p>
          </div>
        </div>
      </div>
    </ExploreWrapper>
  );
};
export default ExploreTimelineNoImage;
