import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  recognition_payload: [],
  errorMsg: "",
};

const recognitionSlice = createSlice({
  name: REDUCER.RECOGNITION,
  initialState,
  reducers: {
    RECOGNITION_REQUEST(state) {
      state.loading = true;
    },
    RECOGNITION_SUCCESS(state, action) {
      state.loading = false;
      state.recognition_payload = action.payload;
    },
    RECOGNITION_FAILED(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
    },
  },
});

export const recognitionActions = recognitionSlice.actions;

export default recognitionSlice;
