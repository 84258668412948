import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Formik, Field, Form } from "formik";
import TextAreaInput from "../shared/custom/TextAreaInput";
import { updateProfile, updateProfilePhoto } from "../persistence/redux";
import { UserPlaceholder } from "../component/svgs/icons";
import { storage } from "../utils/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { authActions } from "../persistence/redux/auth/authSlice";
import { SpinnerSVG } from "./svgs/SpinnerSVG";
import { modalActions } from "../persistence/redux/modal/modalSlice";

export default function ProfileUpdate() {
  // const { loading } = useSelector((state) => state.profile_update);
  const payload = useSelector((state) => state.auth);
  const { email, profession, summary, profilePhoto, id, username } =
    useSelector((state) => state.auth.user_payload);
  const [imageFile, setImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [avatarEditor, setAvatarEditor] = useState("");
  const [imageBlob, setImageBlob] = useState("");
  const inputFile = useRef(null);

  const dispatch = useDispatch();

  const cropper = async (e) => {
    const selectedMedia = e.target.files[0];
    const reader = new FileReader();
    if (selectedMedia === null) return;

    if (selectedMedia) {
      reader.readAsDataURL(selectedMedia);
      reader.addEventListener("load", () => {
        setPreviewImage(reader.result);
      });
    }
  };

  const upload = (blob) => {
    const mediaName = id;

    const imagesRef = ref(storage, `/profilemedia/${mediaName}`);
    const metadata = {
      contentType: "image/jpeg",
    };
    const uploadTask = uploadBytesResumable(imagesRef, blob, metadata);

    uploadTask.on(
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const userObj = {
            email,
            summary,
            username,
            profession,
            id,
            profilePhoto: downloadURL,
          };
          dispatch(authActions.AUTH_REQUEST());
          dispatch(updateProfilePhoto(userObj));
        });
      }
    );
  };

  useEffect(() => {
    if (previewImage) {
      const cropperpayload = {
        componentName: "ImageCropper",
        isOpen: true,
        isClose: false,
        extras: {
          image: previewImage,
          upload,
          setAvatarEditor,
          setImageBlob,
        },
      };

      dispatch(modalActions.MODAL_SAVE(cropperpayload));
    }
  }, [dispatch, previewImage]);

  const submitHandler = (values, { resetForm }) => {
    // debugger;
    dispatch(authActions.AUTH_REQUEST());
    dispatch(updateProfile(values));
    resetForm({ values: { email: "", summary: "", phone: "" } });
  };

  return (
    <AppContainer>
      <ProfileEditWrapper>
        <Formik
          initialValues={{
            username,
            summary,
            firstName: "",
            lastName: "",
            email,
            profession,
            id,
          }}
          onSubmit={submitHandler}
          enableReinitialize={true}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <div className="profile-image-section">
                  <div
                    style={{ textAlign: "center" }}
                    className="img-container"
                  >
                    {payload.loading ? (
                      <SpinnerSVG
                        color="var(--color-primary)"
                        style={{
                          margin: "auto",
                          height: "55px",
                          width: "25px",
                        }}
                      />
                    ) : profilePhoto || imageFile ? (
                      <img
                        src={profilePhoto ? profilePhoto : imageFile}
                        alt=""
                        width="105px"
                        height="105px"
                      />
                    ) : (
                      <UserPlaceholder />
                    )}
                  </div>
                  <div>
                    <input
                      type="file"
                      id="file"
                      accept="/*,image/*"
                      ref={inputFile}
                      style={{ display: "none" }}
                      onChange={cropper} //was upload function;
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      inputFile.current.click();
                    }}
                    type="button"
                    className="btn edit-btn"
                  >
                    Edit
                  </button>

                  <h1 className="heading">{username}</h1>
                  <h3>{profession ? profession : ""}</h3>
                </div>
                <div className="input-wrapper">
                  <Field
                    component={TextAreaInput}
                    placeholder="A short summary about yourself to the audience"
                    name="summary"
                  />

                  <label className="form-label">Occupation</label>
                  <div className="form-group">
                    <Field
                      className="form-control"
                      type="text"
                      name="profession"
                    />
                  </div>
                  <label className="form-label">Phone number</label>
                  <div className="form-group">
                    <Field className="form-control" type="text" name="phone" />
                  </div>
                  <label className="form-label">Email Address</label>
                  <div className="form-group">
                    <Field
                      className="form-control"
                      type="email"
                      name="email"
                      disabled
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-hero">
                  Update
                </button>
              </Form>
            );
          }}
        </Formik>
      </ProfileEditWrapper>
    </AppContainer>
  );
}

const AppContainer = styled.div`
height: 90vh;
  
background-color: var(--color-white);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  flex-direction: row;
  position: fixed;
  width: 100%;
  bottom: 0;
  overflow: scroll;
  padding-bottom: 2rem !important;
  [disabled] {
		color: var(--color-grey-darker);
	}

  
	form {
		width: 90%;
		margin: 4.375rem auto 2rem;
	}
  h3 {
		text-align: center;
		font-weight: 300;
	}
	.input-wrapper {
		margin-top: 2rem;
	}

	.form-label {
		font-size: 1.2rem;
		margin-bottom: 0.6rem;
		font-weight: bold;
	}

	.form-control {
		border-radius: 0.4rem;
		text-align: left;
	}
	textarea.form-control {
		border-radius: 0.4rem;
		height: 15rem;
		font-family: "Lato";
	}

	.profile-image-section {
		width: 85%;
		margin: auto;
	}

	.img-container {
		overflow: hidden;
		width: 35%;
		max-width: 45%;
		max-height: 45%;
		margin: auto;
    padding:5px
	}

	.img-container > img {
		background: white;
		object-fit: contain;
    min-width: 94px;
    min-height: 94px;
    max-width: 105px;
    max-height:105px;
		/* padding: 5px; */
		border-radius: 50%;
		border: 5px solid white;
		box-shadow: 0 4px 6px 2px #bbb;
	}

	button.edit-btn {
		color: var(--color-primary);
		background-color: #efefef;
		margin: auto;
		display: block;
		margin-top: -2rem;
		z-index: 2;
		position: relative;
	}
	.heading {
		text-align: center;
		font-size: 1.7rem;
		margin: 0;
		margin-top: 1rem;
	}
	h3 {
		text-align: center;
		font-weight: 300;
	}
	.input-wrapper {
		margin-top: 2rem;
	}

	.form-label {
		font-size: 1.2rem;
		margin-bottom: 0.6rem;
		font-weight: bold;
	}

	.form-control {
		border-radius: 0.4rem;
		text-align: left;
	}
	textarea.form-control {
		border-radius: 0.4rem;
		height: 15rem;
		font-family: "Lato";
	}


  @media screen and (min-width: 600px) {
    width: 80%;
		border-top-left-radius: 1.5rem;
		border-top-right-radius: 1.5rem;
		border-bottom-left-radius: 1rem;
		border-bottom-right-radius: 1rem;
    height: 80vh;
    margin: 0 auto ;
    position: sticky;
  .app-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    max-width: 75%;
    position: static;
    margin-right: auto;
    margin-left: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .app-container::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) {
		width: 70%;
    height:89vh;
		border-top-left-radius: 1.5rem;
		border-top-right-radius: 1.5rem;
    margin: 0 auto ;
    position: sticky;
    background-color: var(--color-white);
		form {
			max-width: 60%;
			/* margin: 4.375rem auto 0; */
		}
	
	}

`;

const ProfileEditWrapper = styled.div``;
