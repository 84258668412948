import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: true,
  shineToggle: false,
  timeline_payload: [],
  searchedTimeline_payload: null,
  errorMsg: null,
};

const timelineSlice = createSlice({
  name: REDUCER.TIMELINE,
  initialState,
  reducers: {
    TIMELINE_REQUEST(state) {
      state.loading = true;
    },

    ADD_NEW_TIMELINE(state, action) {
      state.timeline_payload = [action.payload, ...state.timeline_payload];
    },

    TIMELINE_SUCCESS(state, action) {
      state.loading = false;
      state.timeline_payload = action.payload;
      state.shineToggle = action.payload;
      state.errorMsg = null;
    },

    TIMELINE_FAILED(state, action) {
      state.loading = false;
      state.shineToggle = false;
      state.errorMsg = action.payload;
    },
    UPDATE_SINGLETIMELINE(state, action) {
      state.loading = false;
      state.timeline_payload = action.payload;
      state.errorMsg = action.payload;
    },

    SEARCH_TIMELINE_SUCCESS(state, action) {
      state.loading = false;
      state.searchedTimeline_payload = action.payload;
      state.errorMsg = null;
    },

    SEARCH_TIMELINE_FAILED(state, action) {
      state.loading = false;
      state.searchedTimeline_payload = {};
      state.errorMsg = action.payload;
    },
    CLEAR_SEARCH_TIMELINE(state, action) {
      state.loading = false;
      state.searchedTimeline_payload = null;
    },
  },
});

export const timelineActions = timelineSlice.actions;

export default timelineSlice;
