import React from "react";
import C2H from "../assets/icons/C2H_logo.png";
export default function SingleImage({ media }) {
  const randomImage = (_mediaList) => {
    if (_mediaList.length === 0) return "";
    else {
      const _imageList = media.filter((mObj) => mObj.type === "image");
      if (_imageList.length === 0) {
        return "";
      }
      return _imageList[0]?.url ?? "";
    }
  };

  let image = randomImage(media);
  return (
    <div>
      {image === "" ? (
        <img src={C2H} alt="" width={60} height={100} />
      ) : (
        <img src={image} alt="" width={60} height={100} />
      )}
    </div>
  );
}
