import { getAuth, signOut } from "@firebase/auth";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { APP_ROUTES } from "../constants/appConstants";
import { authActions } from "../persistence/redux/auth/authSlice";
import { loginActions } from "../persistence/redux/login/loginSlice";
import { signupActions } from "../persistence/redux/login/signupSlice";
import { modalActions } from "../persistence/redux/modal/modalSlice";
import { app } from "../utils/firebase";

const NotificationMenuStyles = styled.div`
  background: #fff;
  position: absolute;
  bottom: 0;
  padding: 4rem 2rem 0;
  width: 100%;
  overflow-y: scroll;
  height: 50vh;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  h1 {
    color: var(--color-primary);
    width: 50%;
    margin: 0 auto 2rem;
    text-align: center;
  }
  a {
    padding: 2rem;
    background-color: #efefef;
    color: #000000;
    display: block;
    margin-bottom: 0.625rem;

    span {
      color: var(--color-danger);
    }
  }
  button.btn {
    width: 22.125rem;
    margin: 2rem auto 0;
    text-align: center;
    display: block;
  }
  .misc-content {
    margin-top: 3rem;
    h1 {
      margin-bottom: 0.4rem;
    }
    p {
      text-align: center;
      font-size: 1.4rem;
      color: #818181;
      letter-spacing: 0.5rem;
    }
  }
`;

const NotificationMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user_payload, isAuthenticated } = useSelector((state) => state.auth);

  const handleSignupClick = () => {
    dispatch(signupActions.CLEAR_SIGNUP());

    const payload = {
      componentName: "Signup",
      isOpen: true,
      isClose: false,
    };
    dispatch(modalActions.MODAL_SAVE(payload));
  };
  const handleLogoutClick = () => {
    const _auth = getAuth(app);
    signOut(_auth)
      .then(() => {
        dispatch(loginActions.CLEAR_LOGIN());

        dispatch(signupActions.CLEAR_SIGNUP());

        dispatch(authActions.CLEAR_AUTH());
        history.replace(APP_ROUTES.ROOT);
      })
      .catch((error) => {
        alert("Couldn't log out!");
      });
  };
  const notifications = useSelector(
    (state) => state.notification.notificationPayload[0]
  );
  console.log("notifications", notifications);
  return (
    <NotificationMenuStyles>
      <h1>Menu</h1>
      <div>
        <a
          href="#"
          onClick={() => {
            history.push(APP_ROUTES.NOTIFICATION_PAGE_MOBILE);
          }}
        >
          Notifications
          <span>({notifications?.notifications?.length})</span>
        </a>
        <a href="#">Terms and Conditions</a>
      </div>

      <button
        onClick={user_payload !== null ? handleLogoutClick : handleSignupClick}
        className="btn btn-hero"
      >
        {user_payload !== null ? "Logout" : "Signup"}
      </button>
      <div className="misc-content">
        <h1>Cheers2Heroes</h1>
        <p>V 1.0.0</p>
      </div>
    </NotificationMenuStyles>
  );
};

export default NotificationMenu;
