import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ErrorBoundaryIcon } from "../component/svgs/icons";

const ErrorBoundaryWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    font-size: 2rem;
    font-weight: bolder;
    margin-bottom: 1.5rem;
  }
  a:hover {
    background-color: #185e87;
    cursor: pointer;
  }
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorBoundaryWrapper>
          <div>
            <ErrorBoundaryIcon style={{ textAlign: "center" }} />
            <p>Oops! something terribly went wrong!</p>
            <a
              onClick={() => window.location.reload()}
              href="#"
              className="btn btn-hero"
            >
              Retry Again!
            </a>
          </div>
        </ErrorBoundaryWrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
