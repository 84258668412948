import styled from "styled-components";

// Used for wrapping a page that needs to show on desktop
export const ResizeForDesktop = styled.div`
  width: ${({ isDesktop }) => (isDesktop ? "50%" : "100%")};
  height: ${({ isDesktop }) => (isDesktop ? "90%" : "100%")};
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

// Used for wrapping a page component
export const Screen = styled.div`
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  position: ${({ position }) => (position ? position : "relative")};
  bottom: ${({ isMobile }) => (isMobile ? 0 : "5rem")};
  height: ${({ isMobile }) => (isMobile ? "90vh" : "100vh")};
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: ${({ t }) => t.spacing.f3};
  width: ${({ t }) => t.spacing.f3};
`;

export const SpacerXXSmall = styled.div`
  height: ${({ t }) => t.spacing.f11};
  width: ${({ t }) => t.spacing.f11};
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: ${({ t }) => t.spacing.f4};
  width: ${({ t }) => t.spacing.f4};
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: ${({ t }) => t.spacing.f5};
  width: ${({ t }) => t.spacing.f5};
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: ${({ t }) => t.spacing.f6};
  width: ${({ t }) => t.spacing.f6};
`;

// Used for providing space between components
export const SpacerXLarge = styled.div`
  height: ${({ t }) => t.spacing.f7};
  width: ${({ t }) => t.spacing.f7};
`;

// Used for providing padding for component
export const PaddingSmall = styled.div`
  height: 100%;
  width: 100%;
  padding: ${({ t }) => t.spacing.f6};
`;

// Used for providing padding for component
export const PaddingMedium = styled.div`
  height: 100%;
  width: 100%;
  padding: ${({ t }) => t.spacing.f7};
`;

// Used for providing padding for component
export const PaddingLarge = styled.div`
  height: 100%;
  width: 100%;
  padding: ${({ t }) => t.spacing.f8};
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ color }) => (color ? color : "none")};
  width: 100%;
  overflow: "hidden";

  /* background-image: ${({ image }) => (image ? `url(${image})` : "none")}; */
  /* background-size: cover;
  background-position: center; */
`;
export const ContainerAlt = styled.div`
  /* display: flex; */
  /* flex: ${({ flex }) => (flex ? flex : 0)}; */
  /* flex-direction: ${({ fd }) => (fd ? fd : "column")}; */
  /* justify-content: ${({ jc }) => (jc ? jc : "flex-start")}; */
  /* align-items: ${({ ai }) => (ai ? ai : "flex-start")}; */
  background-color: ${({ color }) => (color ? color : "none")};
  width: 100%;
  overflow: "hidden";

  /* background-image: ${({ image }) => (image ? `url(${image})` : "none")}; */
  /* background-size: cover;
  background-position: center; */
`;

// Used for providing a wrapper around a component
export const CurvedContentContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  width: 100%;
  background-color: ${({ t }) => t.color.grey.bg};
  overflow: "hidden";
  border-top-left-radius: ${({ t, left }) =>
    left ? t.radius.f7 : t.radius.f0};
  border-top-right-radius: ${({ t, right }) =>
    right ? t.radius.f7 : t.radius.f0};
`;

export const ConcaveContentContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  width: 100%;
  overflow: hidden;
  min-height: 160px;
  bottom: 0%;
  left: 0%;
  right: 0%;
  z-index: 1;
  background: radial-gradient(
    110% 90% at top,
    transparent 50%,
    ${({ bgColor, t }) => (bgColor ? bgColor : t.color.grey.bg)} 0%
  );
`;

export const TextHero = styled.h1`
  color: ${({ t }) => t.color.light.fg[2]};
  font-size: ${({ t }) => t.size.f7};
  font-weight: ${({ t }) => t.weight.f8};
  text-align: ${({ ta }) => (ta ? ta : "left")};
`;

export const TextDescription = styled.p`
  color: ${({ t }) => t.color.grey.fg[0]};
  font-size: ${({ t }) => t.size.f4};
  font-weight: ${({ t }) => t.weight.f4};
  text-align: ${({ ta }) => (ta ? ta : "left")};
`;

export const TextCaption = styled.p`
  color: ${({ t, color }) => (color ? color : t.color.grey.fg[0])};
  font-size: ${({ t }) => t.size.f3};
  font-weight: ${({ t }) => t.weight.f7};
  text-align: ${({ ta }) => (ta ? ta : "left")};
`;

export const TextTitle = styled.p`
  color: ${({ t, color }) => (color ? color : t.color.grey.fg[0])};
  font-size: ${({ t }) => t.size.f6};
  font-weight: ${({ t }) => t.weight.f7};
  text-align: ${({ ta }) => (ta ? ta : "left")};
`;

export const TextInputTitle = styled.p`
  color: ${({ t }) => t.color.grey.fg[0]};
  font-size: ${({ t }) => t.size.f3};
  font-weight: ${({ t }) => t.weight.f5};
  text-align: ${({ ta }) => (ta ? ta : "left")};
`;

export const TextError = styled.p`
  color: ${({ t }) => t.color.error.fg[0]};
  font-size: ${({ t }) => t.size.f3};
  font-weight: ${({ t }) => t.weight.f8};
  text-align: ${({ ta }) => (ta ? ta : "left")};
`;

export const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background: ${({ t }) => t.color.middle_grey.bg};
`;

export const StyledInput = styled.input`
  border: 1px solid ${({ t }) => t.color.light.fg[2]};
  background-color: ${({ t }) => t.color.light.bg};
  border-radius: ${({ t }) => t.radius.f2};
  width: ${({ widthPercent }) => (widthPercent ? widthPercent : "100%")};
  height: 54px;
  padding-left: ${({ t }) => t.size.f5};
  padding-right: ${({ t }) => t.size.f5};
  outline: none;
  font-size: ${({ t }) => t.size.f4};
  font-weight: ${({ t }) => t.weight.f4};
  :focus {
    outline: none !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  }
`;
