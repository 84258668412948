import React, { useContext } from "react";

import styled from "styled-components";

import * as s from "../styles/globalStyles";

import ThemeContext from "../context/themeContext";

const StyledErrorLabel = styled.div`
  visibility: visible;
  border: none;
  border-radius: ${({ t }) => t.radius.f2};
  background-color: ${({ t }) => t.color.error.bg};
  padding-left: ${({ t }) => t.spacing.f4};
  padding-top: 0rem;
  padding-bottom: ${({ t }) => t.spacing.f1};
  justify-content: "flex-start";
  align-items: ${({ ai }) => (ai ? ai : "center")};
  overflow: auto;
  margin-bottom: 0;
  width: 100%;
`;

export const ErrorLabel = ({ message = "" }) => {
  const { theme: t } = useContext(ThemeContext);

  if (message === "") {
    return (
      <>
        <s.SpacerMedium t={t} data-testid="error_spacer" />
      </>
    );
  }

  return (
    <>
      <StyledErrorLabel t={t}>
        <s.TextError t={t} data-testid="error_label">
          {message}
        </s.TextError>
      </StyledErrorLabel>
    </>
  );
};
