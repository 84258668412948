import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AvatarEditor from "react-avatar-editor";
import { modalActions } from "../../persistence/redux/modal/modalSlice";

import styled from "styled-components";

const ImageCropper = ({ extras }) => {
  let [avatar, setAvatar] = useState("");

  const { image, upload, setAvatarEditor } = extras;

  const dispatch = useDispatch();

  const handleCrop = () => {
    avatar.getImageScaledToCanvas().toBlob((blob) => {
      let imageUrl = URL.createObjectURL(blob);
      setAvatarEditor(imageUrl);
      upload(blob);
    });

    dispatch(modalActions.DISMISS_MODAL());
  };

  setAvatar = (editor) => (avatar = editor);

  return (
    <CropperWrapper onClick={(e) => e.stopPropagation()}>
      <div
        style={{
          backgroundColor: "var(--color-primary)",
          padding: "2rem",
          color: "white",
          fontSize: "1.5rem",
        }}
      >
        Crop image
      </div>
      <div className="cropper_container">
        <div style={{ margin: "auto" }}>
          {image && (
            <AvatarEditor
              ref={setAvatar}
              image={image}
              border={50}
              width={250}
              height={250}
              scale={1.2}
            />
          )}
        </div>
      </div>
      <div className="btn-container">
        <button
          onClick={handleCrop}
          style={{ width: "150px" }}
          className="crop-btn"
        >
          crop image
        </button>
      </div>
    </CropperWrapper>
  );
};

const CropperWrapper = styled.div`
  width: 500px;
  height: 500px;
  background-color: white;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  .cropper_container {
    display: flex;
    padding: 1.5rem;
  }
  .btn-container {
    margin: 0 auto;
    height: 46px;
    width: 30%;
    border-radius: 0.6rem;

    button.crop-btn {
      height: 100%;
      background-color: var(--color-primary);
      color: var(--color-secondary);
      border: 0;
      border-radius: 0.6rem;
      text-align: center;
      cursor: pointer;
    }
    button.crop-btn:hover {
      background-color: #0a2637;
      color: var(--color-secondary);
    }
  }
`;
export default ImageCropper;
