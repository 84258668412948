import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  user_payload: null,
  accessToken: "",
  errorMsg: "",
};

const signupSlice = createSlice({
  name: REDUCER.SIGNUP,
  initialState,
  reducers: {
    SIGNUP_REQUEST(state) {
      state.loading = true;
    },
    SIGNUP_SUCCESS(state, action) {
      state.loading = false;
      state.user_payload = action.payload;
      state.accessToken = "";
      state.errorMsg = "";
    },
    SIGNUP_FAILED(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
    },
    CLEAR_SIGNUP(state) {
      state.loading = false;
      state.errorMsg = "";
      state.user_payload = null;
    },
  },
});

export const signupActions = signupSlice.actions;

export default signupSlice;
