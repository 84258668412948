import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import ExploreTimeline from "./ExploreTimeline";
import Search from "../../shared/Search";
import ThemeContext from "../../context/themeContext";
import { useSelector, useDispatch } from "react-redux";
import { modalActions } from "../../persistence/redux/modal/modalSlice";
import ExploreSlideShow from "../../shared/ExploreSlideShow";
import SearchedTimeline from "./SearchedTimeline";
import ExploreTimelineNoImage from "./ExploreTimelineNoImage";
import BottomNavBar from "../bottom_navbar/BottomNavBar";

const ExploreWrapper = styled.div`
  background-color: #efefef;
  border-top-right-radius: 3rem;
  border-top-left-radius: 3rem;
  padding-top: 1rem;
  position: absolute;
  bottom: 0;
  height: 90vh;
  width: 100%;
  overflow-y: scroll;
  --ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  h1 {
    margin-left: 1.5rem;
    color: var(--color-primary);
    /* text-transform: uppercase; */
  }
  @media (min-width: 600px) {
    width: 80%;
    margin: auto;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background-color: #efefef;
    overflow-x: hidden;
    position: static;
    padding-bottom: 15rem;
  }

  .masonry-grid-container {
    column-count: 2;
    padding: 1rem;
  }

  @media screen and (min-width: 600px) {
    .masonry-grid-container {
      column-count: 3;
    }
  }
  @media screen and (min-width: 1200px) {
    .masonry-grid-container {
      column-count: 4;
    }
  }
`;

const ExplorePage = () => {
  const { theme: t, isDesktop, isMobile } = useContext(ThemeContext);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const { popular, searchTimeline } = useSelector(
    ({ popular_timeline, timeline }) => {
      return {
        popular: popular_timeline,
        searchTimeline: timeline.searchedTimeline_payload,
      };
    }
  );

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    900: 2,
  };

  useEffect(() => {}, [
    popular.upcoming_payload,
    popular.popular_timeline_payload,
  ]);

  const items = popular.upcoming_payload.map((nt) => {
    return nt.media.length > 0 ? (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          const payload = {
            componentName: "PostDetail",
            isOpen: true,
            isClose: false,
            extras: {
              timeline: nt,
            },
          };
          dispatch(modalActions.ADD_SELECTED_TIMELINE(nt));

          dispatch(modalActions.MODAL_SAVE(payload));
        }}
      >
        <ExploreTimeline timeline={nt} />
      </div>
    ) : (
      <div>
        <ExploreTimelineNoImage timeline={nt} />
      </div>
    );
  });

  return (
    <ExploreWrapper>
      <>
        <Search
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          searchUserAndTimeline={searchTimeline}
        />

        {searchTerm.length > 0 ? (
          <SearchedTimeline
            searchTimeline={searchTimeline}
            searchTerm={searchTerm}
          />
        ) : (
          <>
            <h1>Most Popular Today</h1>
            <ExploreSlideShow popular={popular.popular_timeline_payload} />
          </>
        )}

        <h1>Upcoming</h1>

        <div className="masonry-grid-container">{items}</div>
        {isMobile && <BottomNavBar />}
      </>
    </ExploreWrapper>
  );
};

export default ExplorePage;
