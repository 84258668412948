import { useSelector } from "react-redux";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";

export const ProtectedRoute = (props) => {
  const location = useLocation();
  const { isAuthenticated } = useSelector((state) => state.auth);

  return isAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/",
        state: { from: location },
      }}
    />
  );
};
