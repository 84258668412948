import React from "react";
import styled from "styled-components";
import { modalActions } from "../../persistence/redux/modal/modalSlice";

function BackButton({ _dispatch }) {
  return (
    <ButtonContainer>
      <Button onClick={() => _dispatch(modalActions.DISMISS_MODAL())}>
        <span>Back</span>
      </Button>
    </ButtonContainer>
  );
}

export default BackButton;

const ButtonContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 999;
  left: 50%;
  top: -7%;
  transform: translateX(-50%);
`;

const Button = styled.button`
  padding: 10px;
  width: 95px;
  background-color: transparent;
  color: #fff;
  border-radius: 30px;
  border: 1px solid white;
  cursor: pointer;

  span {
    color: white;
    font-size: 1.4rem;
  }
`;
