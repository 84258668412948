import { commentActions } from "./commentSlice";
import { BASEURL, api } from "../../../services/api_routes";
import { FETCH_OPTIONS } from "../../../constants/appConstants";
import { getSingleTimeline } from "../";

export const createComment = (timelineComment) => {
  return async (dispatch) => {
    const commentPayload = await fetch(
      `${BASEURL}${api.comment}`,
      FETCH_OPTIONS.postData(timelineComment, null, null)
    );

    commentPayload
      .json()
      .then((data) => {
        dispatch(
          getSingleTimeline(
            timelineComment.storyId,
            timelineComment.commentorId
          )
        );
        dispatch(commentActions.COMMENT_UPDATE_SUCCESS(data));
      })
      .catch((error) => {
        dispatch(commentActions.COMMENT_FAILED(error.message));
      });
  };
};

export const getComments = (timelineId) => {
  return async (dispatch) => {
    dispatch(commentActions.COMMENT_REQUEST());

    const comments = await fetch(
      `${BASEURL}${api.timeline}/${timelineId}${api.comment}`
    );

    comments
      .json()
      .then((data) => {
        dispatch(commentActions.COMMENT_SUCCESS(data));
      })
      .catch((error) => {});
  };
};

// export const likeComment = (likeTimelinePayload) => {
//   return async (dispatch) => {
//     const response = await fetch(
//       `${BASEURL}${api.shine}`,
//       FETCH_OPTIONS.postData(likeTimelinePayload, null, API_CONST.PUT)
//     );

//     response
//       .json()
//       .then((data) => {
//       })
//       .catch((error) => {
//       });
//   };
// };
