import React, { useEffect } from "react";
import { modalActions } from "../../persistence/redux/modal/modalSlice";
import styled from "styled-components";
import NotificationList from "./NotificationList";
import { useSelector } from "react-redux";
import { getNotifications } from "../../persistence/redux";
import { IoClose } from "react-icons/io5";
import NoNotificationFound from "../../pages/404/NoNotificationFound";

const NotificationBound = styled.div`
  background: #efefef;
  position: absolute;
  bottom: 0;
  padding: 2rem;
  width: 100%;
  overflow-y: scroll;
  height: 90vh;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;

  @media (min-width: 600px) {
    border-radius: 0;
    width: 45%;
    position: absolute;
    right: 0;
    background-color: white;
    height: 100vh;
  }
  @media (min-width: 1024px) {
    border-radius: 0;
    width: 25%;
    position: absolute;
    right: 0;
    background-color: white;
    height: 100vh;
  }
`;
const NotificationPage = ({ dispatch }) => {
  const notificationDta = useSelector((store) => store.notification);
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    isAuthenticated && dispatch(getNotifications());
  }, [dispatch, isAuthenticated]);

  const notificationItems =
    notificationDta.notificationPayload.length > 0 ? (
      notificationDta.notificationPayload?.map((payload, index) => (
        <div key={index}>
          <h2>{payload.time}</h2>
          <NotificationList notificationsData={payload.notifications} />
        </div>
      ))
    ) : (
      <NoNotificationFound />
    );

  return (
    <NotificationBound onClick={(e) => e.stopPropagation()}>
      <div
        style={{
          backgroundColor: "#efefef",
          width: "25px",
          height: "25px",
          borderRadius: ".2rem",
          marginBottom: "1rem",
          marginLeft: "auto",
          cursor: "pointer",
        }}
        onClick={() => dispatch(modalActions.DISMISS_MODAL())}
      >
        <IoClose
          style={{
            fontSize: "2.5rem",
            fill: "var(--color-primary)",
          }}
        />
      </div>
      {notificationItems}
    </NotificationBound>
  );
};

export default NotificationPage;
