import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: true,
  liked_timeline_payload: [],
  errorMsg: null,
};

const liketimelineSlice = createSlice({
  name: REDUCER.LIKED_TIMELINE,
  initialState,
  reducers: {
    LIKE_TIMELINE_REQUEST(state) {
      state.loading = true;
    },
    LIKE_TIMELINE_SUCCESS(state, action) {
      state.loading = false;
      state.liked_timeline_payload = action.payload;
      state.errorMsg = null;
    },

    LIKE_TIMELINE_FAILED(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
    },
  },
});

export const liketimelineActions = liketimelineSlice.actions;

export default liketimelineSlice;
