import { popularTimelineActions } from "./popularTimelineSlice";
import { BASEURL, api } from "../../../services/api_routes";

export const getPopularTimeline = (limit, page, userId) => {
  return async (dispatch) => {
    dispatch(popularTimelineActions.TIMELINE_REQUEST());
    let url = "";
    if (userId !== null || userId !== undefined) {
      //us-central1-cheers2heroes-245a3.cloudfunctions.net/api/popularTimelineStories?limit=2&page=1&userId=oreYUvT3v9Ng0CQLJiZiilv0bjf2
      url = `${BASEURL}${api.popularTimeline}?limit=${limit}&page=${page}&userId=${userId}`;
    } else {
      url = `${BASEURL}${api.timeline}?limit=${limit}&page=${page}`;
    }

    try {
      const payloadData = await fetch(url);

      const jsonValue = await payloadData.json();

      if (payloadData.status === 200) {
        dispatch(
          popularTimelineActions.POPULAR_TIMELINE_SUCCESS(jsonValue.data)
        );
      } else {
        dispatch(
          popularTimelineActions.POPULAR_TIMELINE_FAIL(
            "Cannot load timeline data..."
          )
        );
      }
    } catch (error) {}
  };
};
export const getUpcomingTimeline = (limit, page, userId) => {
  // console.log("Inside get timeline", userId);
  return async (dispatch) => {
    dispatch(popularTimelineActions.TIMELINE_REQUEST());
    let url = "";
    if (userId !== null || userId !== undefined) {
      //us-central1-cheers2heroes-245a3.cloudfunctions.net/api/popularTimelineStories?limit=2&page=1&userId=oreYUvT3v9Ng0CQLJiZiilv0bjf2
      url = `${BASEURL}${api.popularTimeline}?limit=${limit}&page=${page}&userId=${userId}`;
    } else {
      url = `${BASEURL}${api.timeline}?limit=${limit}&page=${page}`;
    }

    try {
      const payloadData = await fetch(url);

      const jsonValue = await payloadData.json();

      if (payloadData.status === 200) {
        dispatch(popularTimelineActions.UPCOMING_SUCCESS(jsonValue.data));
      } else {
        console.log("error from catch", jsonValue);
        dispatch(
          popularTimelineActions.UPCOMING_FAIL("Cannot load timeline data...")
        );
      }
    } catch (error) {}
  };
};
