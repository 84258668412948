import { timelineActions } from "./timelineSlice";
import { BASEURL, api } from "../../../services/api_routes";
import { FETCH_OPTIONS, API_CONST } from "../../../constants/appConstants";
import { getSingleTimeline } from "../";
import { getLikedTimelines } from "./likedtimelineAction";

export const getTimeline = (limit, page, userId) => {
  return async (dispatch) => {
    dispatch(timelineActions.TIMELINE_REQUEST());
    let url = "";
    if (userId !== null) {
      url = `https://us-central1-cheers2heroes-245a3.cloudfunctions.net/api/timelineStories?limit=${limit}&page=${page}&userId=${userId}`;
    } else {
      url = `https://us-central1-cheers2heroes-245a3.cloudfunctions.net/api/timelineStories?limit=${limit}&page=${page}`;
    }
    const response = await fetch(url);

    response
      .json()
      .then((successResp) => {
        dispatch(timelineActions.TIMELINE_SUCCESS(successResp.data));
      })
      .catch((error) => {
        dispatch(timelineActions.TIMELINE_FAILED(error.message));
      });
  };
};
export const updateTimeline = (timeline, saveableTimelines) => {
  return async (dispatch) => {
    const index = saveableTimelines.findIndex(
      (element) => element.id === timeline.id
    );
    saveableTimelines.splice(index, 1, timeline);
    dispatch(timelineActions.UPDATE_SINGLETIMELINE(timeline));
  };
};

export const likeTimeline = (likeTimelinePayload) => {
  return async (dispatch) => {
    if (likeTimelinePayload.userId && likeTimelinePayload.storyId) {
      const response = await fetch(
        `${BASEURL}${api.shine}?userId=${likeTimelinePayload.userId}&storyId=${likeTimelinePayload.storyId}`,
        FETCH_OPTIONS.postData({}, null, API_CONST.PUT)
      );

      response
        .json()
        .then((data) => {
          dispatch(
            getSingleTimeline(
              likeTimelinePayload.storyId,
              likeTimelinePayload.userId
            )
          );
          //update the liked timeline store
          //call getLiked timeline
          dispatch(getLikedTimelines(likeTimelinePayload.userId));
        })
        .catch((error) => {});
    }
  };
};

export const getTimelineByCategory = (limit, page, userId, categoryName) => {
  return async (dispatch) => {
    dispatch(timelineActions.TIMELINE_REQUEST());

    let url = "";
    if (userId !== null) {
      url = `https://us-central1-cheers2heroes-245a3.cloudfunctions.net/api/timelineStories?category=${categoryName}&limit=${limit}&page=${page}&userId=${userId}`;
    } else {
      url = `https://us-central1-cheers2heroes-245a3.cloudfunctions.net/api/timelineStories?category=${categoryName}&limit=${limit}&page=${page}`;
    }
    const response = await fetch(url);

    response
      .json()
      .then((successResp) => {
        dispatch(timelineActions.TIMELINE_SUCCESS(successResp.data));
      })
      .catch((error) => {
        dispatch(timelineActions.TIMELINE_FAILED(error.message));
      });
  };
};

export const searchTimeline = (searchTerm) => {
  return async (dispatch) => {
    const response = await fetch(
      `${BASEURL}${api.searchTimelineStories}?searchTerm=${searchTerm}`
    );

    response
      .json()
      .then((res) => {
        dispatch(timelineActions.SEARCH_TIMELINE_SUCCESS(res.data));
      })
      .catch((error) => {});
  };
};
