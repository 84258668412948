import { authActions } from "./authSlice";
import { modalActions } from "../modal/modalSlice";
import { FacebookAuthProvider } from "firebase/auth";

import {
  FETCH_OPTIONS,
  THIRD_PARTY_PROVIDERS,
} from "../../../constants/appConstants";
import { BASEURL, api } from "../../../services/api_routes";

export const updateUser = (newUser) => {
  return async (dispatch) => {
    dispatch(authActions.AUTH_SUCCESS(newUser));
  };
};
export const clearAuth = () => {
  return async (dispatch) => {
    dispatch(authActions.CLEAR_AUTH());
  };
};

export const updateThirdPartyLoginUser = (userCredentials, whichProvider) => {
  return async (dispatch) => {
    dispatch(authActions.AUTH_REQUEST());

    let _userBase = {
      profession: "",
      firstName: "",
      totalLinks: 0,
      sharedStories: [],
      lastName: "",
      summary: "",
      isAdmin: false,
      totalShared: 0,
      totalAccolades: 0,
      username: "",
      profilePhoto: "",
    };

    let _user = {};

    switch (whichProvider) {
      case THIRD_PARTY_PROVIDERS.GOOGLE:
        const _accessToken = await userCredentials.user.getIdToken(true);
        _user = {
          ..._userBase,
          accessToken: _accessToken,
          email: userCredentials.user.email,
          username: userCredentials.user.displayName,
          profilePhoto: userCredentials.user.photoURL,
          id: userCredentials.user.uid,
        };
        console.log("@@@@@@@  GOOGLE DATA@@", _user);
        break;

      case THIRD_PARTY_PROVIDERS.APPLE:
        break;

      case THIRD_PARTY_PROVIDERS.FACEBOOK:
        const credential =
          FacebookAuthProvider.credentialFromResult(userCredentials);
        const accessToken = credential.accessToken;

        _user = {
          ..._userBase,
          accessToken: accessToken,
          email: userCredentials.user.email,
          username: userCredentials.user.displayName,
          profilePhoto: userCredentials.user.photoURL,
          id: userCredentials.user.uid,
        };
        break;

      default:
        _user = {
          ..._userBase,
          userCredentials,
        };
        break;
    }

    const response = await fetch(
      `${BASEURL}${api.users}/${_user.id}`,
      FETCH_OPTIONS.getData()
    );

    response
      .json()
      .then((res) => {
        if (res.success) {
          console.log(res.data);
          let final_user_data = {
            ..._user,
            ...res.data,
          };
          // console.log('final data',final_user_data);
          dispatch(authActions.AUTH_UPDATE(final_user_data));
        }
      })
      .catch((error) => {});

    // dispatch(authActions.AUTH_UPDATE(_user));

    dispatch(modalActions.DISMISS_MODAL());
  };
};
