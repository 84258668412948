import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: true,
  category_payload: [],
  errorMsg: null,
  selectedCategory: "",
  selectedSortedBy: "",
};

const categorySlice = createSlice({
  name: REDUCER.CATEGORY,
  initialState,
  reducers: {
    CATEGORY_REQUEST(state) {
      state.loading = true;
    },
    CATEGORY_SUCCESS(state, action) {
      state.loading = false;
      state.category_payload = action.payload;
      state.errorMsg = null;
    },
    CATEGORY_FAILED(state, action) {
      state.loading = false;
      state.category_payload = [];
      state.errorMsg = action.payload;
    },
    SET_TIMELINEFILTER(state, action) {
      state.selectedSortedBy = action.payload;
    },
    CLEAR_TIMELINEFILTER(state, action) {
      state.selectedSortedBy = "";
    },
    setCategory(state, action) {
      state.selectedCategory = action.payload;
    },
  },
});

export const categoryActions = categorySlice.actions;

export default categorySlice;
