import React from "react";
import styled from "styled-components";
import Notification from "./Notification";

const NotificationListWrapper = styled.div`
  width: 100%;
`;

const NotificationList = ({ notificationsData, dispatch }) => {
  return notificationsData.map((nt) => {
    return (
      <NotificationListWrapper key={nt?.id}>
        <Notification notification={nt} />
      </NotificationListWrapper>
    );
  });
};

export default NotificationList;
