import React from "react";

import ReactDOM from "react-dom";
import styled from "styled-components";
import Signup from "../component/Signup";
import { modalActions } from "../persistence/redux/modal/modalSlice";
import { MODAL_ROUTES } from "../constants/appConstants";
import PostDetail from "../component/post/PostDetail";
import Login from "../component/Login";
import Post from "../component/post/Post";
import NotificationPage from "../component/timeline/NotificationPage";
import SocialShare from "./SocialShare";
import ResetPassword from "../component/ResetPassword";
import InappropriatePost from "../component/InappropriatePostModal";
import NotificationMenu from "./NotificationMenu";
import ImageCropper from "./custom/ImageCropper";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100%;
  z-index: 999;
  padding-bottom: 12rem;
`;

const Modal = ({ isOpen, componentName, _dispatch, extras }) => {
  const handleComponent = () => {
    switch (componentName) {
      case MODAL_ROUTES.SIGNUP:
        return <Signup dispatch={_dispatch} />;
      case MODAL_ROUTES.LOGIN:
        return <Login dispatch={_dispatch} />;
      case MODAL_ROUTES.POSTDETAIL:
        return <PostDetail dispatch={_dispatch} />;
      case MODAL_ROUTES.POST:
        return <Post dispatch={_dispatch} />;
      case MODAL_ROUTES.NOTIFICATION:
        return <NotificationPage dispatch={_dispatch} />;
      case MODAL_ROUTES.NOTIFICATION_MENU:
        return <NotificationMenu dispatch={_dispatch} />;
      case MODAL_ROUTES.SOCIAL_SHARE:
        return <SocialShare dispatch={_dispatch} extras={extras} />;
      case MODAL_ROUTES.CROPPER:
        return <ImageCropper dispatch={_dispatch} extras={extras} />;
      case MODAL_ROUTES.INAPPROPRIATE_POST:
        return <InappropriatePost dispatch={_dispatch} extras={extras} />;
      case MODAL_ROUTES.RESET_PASSWORD:
        return <ResetPassword dispatch={_dispatch} />;
      default:
        return null;
    }
  };
  if (isOpen) {
    return ReactDOM.createPortal(
      <ModalWrapper
        onClick={() => {
          const payload = {
            isClose: true,
            isOpen: false,
            componentName: null,
          };
          _dispatch(modalActions.MODAL_SAVE(payload));
        }}
      >
        {handleComponent()}
        {/* <Signup /> */}
      </ModalWrapper>,
      document.querySelector("#modal")
    );
  } else {
    return null;
  }
};

export default Modal;
