import React, { useEffect, useState } from "react";
// Context
import { ThemeProvider } from "./context/themeContext";
// Redux
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTimeline,
  getCategories,
  getSuggestedTimeline,
  setModal,
  getPopularTimeline,
  getUpcomingTimeline,
} from "./persistence/redux/";
//Pages

import { persistor } from "./persistence/redux/store";
import { PersistGate } from "redux-persist/integration/react";

import "./App.css";
import Splash from "./pages/splash/Splash";
import AppRoutes from "./AppRoutes";
import categorySlice, {
  categoryActions,
} from "./persistence/redux/category/categorySlice";
import ErrorBoundary from "./utils/ErrorBoundary";

//Globals
export var deferredInstall = null;
export var isPWAInstalled = false;

if ("serviceWorker" in navigator) {
  //listen for `beforeinstallprompt` event
  window.addEventListener("beforeinstallprompt", (ev) => {
    ev.preventDefault();
    deferredInstall = ev;
  });

  //Detect when PWA was successfully installed
  window.addEventListener("appinstalled", () => {
    // Hide the app-provided install promotion
    isPWAInstalled = true;
    // Clear the deferredPrompt so it can be garbage collected
    deferredInstall = null;
    // Optionally, send analytics event to indicate successful install
  });
}

export function installPWA() {
  if (deferredInstall) {
    deferredInstall.prompt();

    deferredInstall.userChoice.then((choice) => {
      if (choice.outcome === "accepted") {
        //TODO:- Hide the Notification
        isPWAInstalled = true;
      } else {
      }
    });
  }
}

function App() {
  const dispatch = useDispatch();
  const initialTimeline = useSelector((state) => state.timeline);
  const auth = useSelector((state) => state.auth);
  const [isShowingSplash, setIsShowingSplash] = useState(true);

  let userId;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShowingSplash(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (auth.user_payload) {
      userId = auth?.user_payload?.id;
    }

    dispatch(setModal());
    dispatch(getTimeline(10, 1, userId));
    // dispatch(categoryActions.CLEAR_TIMELINEFILTER());
    dispatch(getPopularTimeline(5, 1, userId));
    dispatch(getUpcomingTimeline(5, 2, userId));
    dispatch(getSuggestedTimeline(3, 1, auth));
    dispatch(getCategories());
  }, [auth, dispatch]);

  return (
    <PersistGate persistor={persistor}>
      <ErrorBoundary>
        <ThemeProvider>
          <Router>
            {isShowingSplash ? (
              <Splash />
            ) : (
              <AppRoutes
              // style={{ overflow: "hidden" }}
              />
            )}
          </Router>
        </ThemeProvider>
      </ErrorBoundary>
    </PersistGate>
  );
}

export default App;
