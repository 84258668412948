import React from "react";
import styled from "styled-components";

function SortByShimmer() {
  return (
    <ShimmerContainer>
      <ShimmerDescription width="40%" />
      <ShimmerRow>
        <ShimmerPill />
        <ShimmerPill />
      </ShimmerRow>
      <ShimmerRow>
        <ShimmerPill />
        <ShimmerPill />
      </ShimmerRow>
      <ShimmerRow>
        <ShimmerPill />
        <ShimmerPill />
      </ShimmerRow>
    </ShimmerContainer>
  );
}

const ShimmerContainer = styled.div`
  height: 213px;
  width: 243px;
  background-color: var(--color-white);
  padding: 32px;
`;
const ShimmerPill = styled.div`
  height: 30px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 44%;
  padding: 0.9rem;
  background: var(--color-grey-dark);
  border-radius: 2rem;
`;
const ShimmerRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ShimmerDescription = styled.div`
  width: ${(props) => props.width || "100%"};
  border-radius: 4px;
  height: 16px;
  margin-top: 4px;
  margin-bottom: 16px;
  background: var(--color-grey-dark);
  /* background: red;  */
`;

export default SortByShimmer;
