import { signupActions } from "./signupSlice";
import { authActions } from "../auth/authSlice";
import { FETCH_OPTIONS, AUTH_CONST } from "../../../constants/appConstants";
import { BASEURL, api } from "../../../services/api_routes";
import { dismissModal } from "..";

export const createNewUser = (newUser) => {
  return async (dispatch) => {
    dispatch(signupActions.SIGNUP_REQUEST());

    console.log("user", newUser);
    const response = await fetch(
      `${BASEURL}${api.createNewUser}`,
      FETCH_OPTIONS.postData(newUser)
    );
    console.log("response", response);

    response
      .json()
      .then((data) => {
        console.log("dataatatata", data);
        if (data.success) {
          dispatch(signupActions.SIGNUP_SUCCESS(data.data));
          dispatch(authActions.AUTH_SUCCESS(data.data));

          dispatch(dismissModal());
        } else {
          console.log("elseeeee", data);
          dispatch(authActions.AUTH_FAILED(data.message));
          dispatch(signupActions.SIGNUP_FAILED(data.message));
        }
      })
      .catch((error) => {
        dispatch(authActions.AUTH_FAILED(AUTH_CONST.WRONG_CREDENTIALS_SIGNUP));
        dispatch(
          signupActions.SIGNUP_FAILED(AUTH_CONST.WRONG_CREDENTIALS_SIGNUP)
        );
        console.log("error is..", error);
      });
  };
};

export const clearSignup = () => {
  return async (dispatch) => {
    dispatch(signupActions.CLEAR_SIGNUP());
  };
};
