import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  errorMsg: "",
  notificationPayload: [],
};

const notificationSlice = createSlice({
  name: REDUCER.NOTIFICATION,
  initialState,
  reducers: {
    NOTIFICATION_REQUEST(state) {
      state.loading = true;
    },
    NOTIFICATION_SUCCESS(state, action) {
      state.loading = false;
      state.notificationPayload = action.payload;
      state.errorMsg = "";
    },
    NOTIFICATION_FAILED(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
    },
    NOTIFICATION_CLEAR(state) {
      state.loading = false;
      state.errorMsg = "";
      state.notificationPayload = [];
    },
  },
});

export const notificationActions = notificationSlice.actions;

export default notificationSlice;
