import React from "react";
import styled from "styled-components";
import Shine from "../svgs/ShineAltSVG";

const ExploreWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  page-break-inside: avoid;

  /* border-radius: 2rem; */
  /* padding-bottom: 2rem; */
  /* height: 100vh; */
`;
const ExploreImgContainer = styled.div`
  /* border-radius: 10px; */
  position: relative;
  width: 100%;
  padding-top: calc(100% * (1 / 1));
  height: 0;

  img {
    border-radius: 10px;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
  }
  /* height: 200px; */
  /* overflow: hidden; */
  /* border: 1px solid red; */
`;
const ExploreTimeline = ({ timeline }) => {
  return (
    <ExploreWrapper>
      <ExploreImgContainer>
        <img src={timeline.media[0].url} alt="" />
      </ExploreImgContainer>
      <div style={{ padding: "1rem" }}>
        <h2>{timeline.title}</h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "1rem" }}>
            <Shine width="25px" />
          </div>
          <div>
            <p>{timeline.totalLikes}</p>
          </div>
        </div>
      </div>
    </ExploreWrapper>
  );
};
export default ExploreTimeline;
