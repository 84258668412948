import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import LeftSideNav from "../../component/layout/LeftSideNav";
import RightSideNav from "../../component/layout/RightSideNav";
import TimelinePage from "../../component/timeline/TimelinePage";
import NoPostPlaceholder from "../../assets/images/no-post.PNG";
import BottomNavBar from "../../component/bottom_navbar/BottomNavBar";
import ThemeContext from "../../context/themeContext";

const Home = () => {
  const [showBottomNav, setShowBottomNav] = useState(true);
  const [prev, setPrev] = useState(0);
  const { isMobile } = useContext(ThemeContext);
  const reduxData = useSelector(
    ({ category, timeline, suggested_timeline, auth }) => {
      return {
        category: category,
        timeline: timeline.timeline_payload,
        auth,
        suggested: suggested_timeline.suggested_timeline_payload,
      };
    }
  );
  const timeline = useSelector((state) => state.timeline.timeline_payload);

  const dispatch = useDispatch();

  useEffect(() => {}, [timeline]);

  useEffect(() => {
    setPrev(window.scrollY);
  }, []);

  const handleScroll = (e) => {
    const window = e.target;
    if (prev > window.scrollTop) {
      // console.log("scrolling up");
      setShowBottomNav(true);
    } else if (prev < window.scrollTop) {
      // console.log("scrolling down");
      setShowBottomNav(false);
    }
    setPrev(window.scrollTop);
  };

  return (
    <>
      <div className="app-container">
        <LeftSideNav categoryData={reduxData.category} />
        {timeline.length === 0 ? (
          <NoPost>
            <img src={NoPostPlaceholder} alt="" />
          </NoPost>
        ) : (
          <>
            <TimelinePage
              onPageScroll={handleScroll}
              dispatch={dispatch}
              timelineData={timeline}
            />

            <RightSideNav
              dispatch={dispatch}
              suggestedTimelineData={reduxData.suggested}
              authenticate={reduxData.auth}
            />
          </>
        )}
      </div>
      {isMobile && showBottomNav && <BottomNavBar />}
    </>
  );
};

export default Home;

const NoPost = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 100%;
    width: 100%;
    margin-top: 10%;
    object-fit: contain;
  }
`;
