import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import "./signup.css";
import C2H from "../assets/icons/C2H_logo.png";
import { modalActions } from "../persistence/redux/modal/modalSlice";
import { createNewUser, updateThirdPartyLoginUser } from "../persistence/redux";
import { Formik, Field, Form } from "formik";
import * as s from "../styles/globalStyles";
import ThemeContext from "../context/themeContext";
import { ErrorLabel } from "../shared/ErrorLabel";
import * as Yup from "yup";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { FaFacebookF, FaGoogle } from "react-icons/fa";
import { app } from "../utils/firebase";
import { THIRD_PARTY_PROVIDERS } from "../constants/appConstants";
import { SpinnerSVG } from "./svgs/SpinnerSVG";
import { signupActions } from "../persistence/redux/login/signupSlice";
import { categoryActions } from "../persistence/redux/category/categorySlice";

const Signup = ({ dispatch }) => {
  const { theme: t } = useContext(ThemeContext);

  const signup_payload = useSelector((store) => store.signup);

  useEffect(() => {
    dispatch(signupActions.CLEAR_SIGNUP());
  }, [dispatch]);

  const handleSubmit = (values, {}) => {
    if (values !== null) {
      const newUser = {
        ...values,
      };
      dispatch(createNewUser(newUser));
      dispatch(categoryActions.CLEAR_TIMELINEFILTER());
    }
  };

  const lowercaseRegex = /(?=.*[a-z])/;
  const uppercaseRegex = /(?=.*[A-Z])/;
  const numericRegex = /(?=.*[0-9])/;

  const SignupSchema = Yup.object().shape({
    username: Yup.string()
      .min(2, "Too short!")
      .max(40, "Too long!")
      .required("Username is required!"),

    email: Yup.string()
      .lowercase()
      .email("Invalid email")
      .required("Email cannot be empty!"),
    password: Yup.string()
      .matches(lowercaseRegex, "at least one lowercase is required!")
      .matches(uppercaseRegex, "at least one uppercase is required!")
      .matches(numericRegex, "at least one number is required!")
      .min(8, "Password should be at least 8 characters long!")
      .required("Password cannot be empty!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Password must be the same!")
      .required("Confirm password cannot be empty!"),
  });

  const PasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password can't be empty!")
      .test(
        "len",
        "Password should be at least 6 characters",
        (val) => val.length >= 6
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password")],
      "Password must be the same!"
    ),
  });

  const handleGoogleLogin = () => {
    const googleProvider = new GoogleAuthProvider();
    const f_auth = getAuth(app);
    signInWithPopup(f_auth, googleProvider)
      .then((googleUser) => {
        dispatch(
          updateThirdPartyLoginUser(googleUser, THIRD_PARTY_PROVIDERS.GOOGLE)
        );
      })
      .catch((error) => {});
  };
  const handleFacebookLogin = () => {
    const facebookProvider = new FacebookAuthProvider();
    const f_auth = getAuth();

    signInWithPopup(f_auth, facebookProvider)
      .then((result) => {
        dispatch(
          updateThirdPartyLoginUser(result, THIRD_PARTY_PROVIDERS.FACEBOOK)
        );
      })
      .catch((error) => {});
  };

  // top: 50%;
  //   left: 50%;
  //   transform: translate(-50%,-50%);
  //   height: 90vh;
  return (
    <div
      className="signup container"
      color={"white"}
      style={{
        // maxWidth: isMobile ? "90%" : isDesktop ? "35%" : "50%",
        margin: "auto",
        borderRadius: "10px",
        overflowY: "scroll",
      }}
      ai={"center"}
      flex={1}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="inner-container">
        <div className="signup__logo" style={{ width: "20%" }}>
          <img src={C2H} alt="app logo" />
        </div>
        <s.SpacerXLarge t={t} />
        <s.Container
          fd={"row"}
          jc={"space-around"}
          style={{ maxWidth: "200px", margin: "0 auto" }}
        >
          <button onClick={handleGoogleLogin} className="btn-google icon">
            <FaGoogle style={{ fontSize: "1.8rem" }} />
          </button>
          <button onClick={handleFacebookLogin} className="btn-fb icon">
            <FaFacebookF style={{ fontSize: "1.8rem" }} />
          </button>
        </s.Container>
        <s.ContainerAlt jc={"space-between"} style={{ width: "100%" }}>
          <section className="section">
            <Formik
              initialValues={{
                username: "",
                email: "",
                password: "",
                confirmPassword: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={SignupSchema}
            >
              {({ errors, isSubmitting, touched }) => {
                return (
                  <Form style={{ marginTop: "3rem" }}>
                    <div className="form-group">
                      <Field
                        autoComplete="off"
                        name="username"
                        className="form-control"
                        placeholder="Username"
                      />
                      {errors.username && touched.username ? (
                        <ErrorLabel message={errors.username} />
                      ) : null}
                    </div>

                    <div className="form-group">
                      <Field
                        autoComplete="off"
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        name="email"
                      />
                      {errors.email && touched.email ? (
                        <ErrorLabel
                          style={{ marginBottom: "3rem" }}
                          message={errors.email}
                        />
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Field
                        autoComplete="off"
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        name="password"
                      />
                      {errors.password && touched.password ? (
                        <ErrorLabel
                          style={{ marginBottom: "3rem" }}
                          message={errors.password}
                        />
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Field
                        autoComplete="off"
                        className="form-control"
                        type="password"
                        placeholder="Confirm password"
                        name="confirmPassword"
                      />
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <ErrorLabel message={errors.confirmPassword} />
                      ) : null}
                    </div>
                    <ErrorLabel message={signup_payload.errorMsg} />

                    <span
                      className="tandc"
                      style={{
                        fontSize: "1.2rem",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "2rem",
                      }}
                    >
                      <input style={{ width: "2rem" }} type="checkbox" />
                      <span>
                        I agree to the
                        <a
                          href="#"
                          style={{ display: "inline", alignSelf: "end" }}
                        >
                          Terms and Conditions
                        </a>
                      </span>
                    </span>
                    <div className="logon-section">
                      <button type="submit" className="btn btn-hero">
                        {signup_payload.loading ? (
                          <SpinnerSVG
                            color="var(--color-secondary)"
                            style={{
                              margin: "auto",
                              height: "25px",
                              width: "25px",
                            }}
                          />
                        ) : (
                          "Sign up"
                        )}
                      </button>

                      <a
                        onClick={() => {
                          const payload = {
                            componentName: "Login",
                            isOpen: true,
                            isClose: false,
                          };
                          dispatch(modalActions.MODAL_SAVE(payload));
                        }}
                        className="account"
                        style={{ color: "var(--color-primary)" }}
                      >
                        Login
                      </a>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </section>
        </s.ContainerAlt>
      </div>
    </div>
  );
};
export default Signup;
