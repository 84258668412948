import React from "react";
import styled from "styled-components";

function ProfileSummaryShimmer() {
  return (
    <ShimmerContainer>
      <ShimmerAvatar />
      <ShimmerDescription width="80%" />
      <ShimmerDescription />
      <ShimmerDescription />
      <ShimmerDescription />
      <ShimmerRow>
        <ShimmerDescription width="40%" />
        <ShimmerDescription width="20%" />
      </ShimmerRow>
      <ShimmerRow mt="8px">
        <ShimmerDescription width="40%" />
        <ShimmerDescription width="20%" />
      </ShimmerRow>
      <ShimmerRow mt="8px">
        <ShimmerDescription width="40%" />
        <ShimmerDescription width="20%" />
      </ShimmerRow>
    </ShimmerContainer>
  );
}

const ShimmerContainer = styled.div`
  background-color: var(--color-white);
  width: 100%;
  padding: 32px;
  margin-bottom: 16px;
`;

const ShimmerAvatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 80%;
  background: var(--color-grey-dark);
  margin-bottom: 8px;
`;

const ShimmerRow = styled.div`
  margin-top: ${(props) => props.mt || "16px"};
  display: flex;
  justify-content: space-between;
`;
const ShimmerDescription = styled.div`
  width: ${(props) => props.width || "100%"};
  border-radius: 4px;
  height: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  background: var(--color-grey-dark);
  /* background: red;  */
`;

export default ProfileSummaryShimmer;
