import { format } from "date-fns";

export const getTimelineDateFormat = (
  date,
  dateFormat = "MM/dd/yyyy",
  isOnlyDayOfWeek = false
) => {
  const currentDate = format(new Date(), dateFormat);
  const timelineDate = format(new Date(date), dateFormat);

  let yesterdayDate = new Date();

  yesterdayDate.setDate(new Date().getDate() - 1);

  const yesterday = format(yesterdayDate, dateFormat);

  if (isOnlyDayOfWeek) {
    return timelineDate;
  } else {
    if (timelineDate === currentDate) {
      return "Today";
    } else if (timelineDate === yesterday) {
      return "Yesterday";
    } else {
      return timelineDate;
    }
  }
};
