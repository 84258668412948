import { modalActions } from "./modalSlice";

export const setModal = (componentName, isOpen, isClose) => {
  return (dispatch) => {
    const payload = {
      componentName: null,
      isOpen: false,
      isClose: true,
    };
    dispatch(modalActions.MODAL_SAVE(payload));
  };
};

export const dismissModal = () => {
  return (dispatch) => {
    dispatch(modalActions.DISMISS_MODAL());
  };
};
