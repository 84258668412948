import React from "react";
import styled from "styled-components";

function TimelineListMobileComponent() {
  return (
    <Shimmer>
      <ShimmerColumn>
        <ShimmerTitle />
        <ShimmerTitle width="40%" mt="4px" />
      </ShimmerColumn>
      <ShimmerRow>
        <ShimmerImage />
        <ShimmerContent>
          <ShimmerDescription />
          <ShimmerDescription />
          <ShimmerDescription />
          <ShimmerDescription />
        </ShimmerContent>
      </ShimmerRow>
      <ShimmerInterativities>
        <ShimmerButton />
        <ShimmerButton />
        <ShimmerButton />
        <ShimmerButton />
      </ShimmerInterativities>
    </Shimmer>
  );
}

export default TimelineListMobileComponent;

const Shimmer = styled.div`
  background-color: var(--color-white);
  width: 95%;
  /* height: 200px; */
  padding: 12px;
`;
const ShimmerInterativities = styled.div`
  display: flex;
  padding-top: 16px;
  justify-content: space-between;
`;
const ShimmerColumn = styled.div`
  /* height: 100px; */
`;
const ShimmerContent = styled.div`
  /* display: flex; */
  flex: 1;
`;
const ShimmerRow = styled.div`
  margin-top: 8px;
  display: flex;

  height: 100px;
  /* background: blue; */
`;

const ShimmerImage = styled.div`
  width: 100px;
  height: 100px;
  background: var(--color-grey-dark);
  margin-right: 8px;
  border-radius: 4px;
`;

const ShimmerDescription = styled.div`
  width: 100%;
  border-radius: 4px;
  height: 20px;
  margin-top: 4px;
  margin-bottom: 4px;
  background: var(--color-grey-dark);
  /* background: red;  */
`;

const ShimmerTitle = styled.div`
  border-radius: 4px;
  width: ${(props) => props.width || "70%"};
  height: 20px;
  margin-top: ${(props) => props.mt || "0px"};
  background: var(--color-grey-dark);
`;

const ShimmerButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  /* outline: none; */
  border: none;

  &::after {
    /* display: block; */
    position: absolute;
    content: "";
    width: 2px;
    height: 22px;
    background-color: var(--color-grey-dark);
    right: -10px;
    top: 20%;
  }
`;

const ShimmerButtonNoBar = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  /* outline: none; */
  border: none;
`;
