import React from "react";
import * as s from "../styles/globalStyles";
import styled from "styled-components";

const getMediaOrTileData = (dataObj) => {
  if (dataObj.media.length === 0) {
    return {
      title: dataObj.title,
      description: dataObj.description,
      storyId: dataObj.id,
    };
  } else if (dataObj.media[0].type === "image") {
    return dataObj.media[0].url;
  }
};

const TabTiles = ({ dataList }) => {
  return (
    <s.Container
      style={{
        width: "87%",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: ".8rem",
        margin: "auto",
        justifyContent: "center",
        overflowY: "scroll",
        // height: "50vh",
      }}
    >
      {dataList?.map((item, i) => {
        let media = getMediaOrTileData(item);
        if (typeof media === "string") {
          return (
            <TabTileImage key={i}>
              <img src={media} alt="profile display" width="113" height="113" />
            </TabTileImage>
          );
        } else {
          return (
            <TabTileNoImage key={i}>
              <h2>{item.title}</h2>
              <p>
                {item.description.length > 64
                  ? `${item.description.substring(0, 150)} ...`
                  : item.description}
              </p>
            </TabTileNoImage>
          );
        }
      })}
    </s.Container>
  );
};

export default TabTiles;

//liked images component
const TabTileImage = styled.div`
  width: 30%;
  border-radius: 1.2em;
  overflow: hidden;

  @media screen and (min-width: 1200px) {
    width: 20%;
  }
`;

const TabTileNoImage = styled.div`
  width: 30%;
  height: 114px;
  border-radius: 0.7rem;
  overflow: hidden;
  color: white;
  background-color: #734531;
  /* margin-top: 10px; */
  h2 {
    color: var(--color-white);
  }

  @media screen and (max-width: 480px) {
    min-height: 113px;
    font-size: 9px;
    h2 {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }

  @media screen and (min-width: 1200px) {
    width: 20%;
    font-size: 10px;
    h2 {
      font-size: 18px;
      margin-bottom: 5px;
    }
  }
`;
