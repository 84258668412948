import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import VideoPlayer from "./VideoPlayer";

const Slideshow = ({ media, showControls, startPlay }) => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    centerMode: true,
    slideToScroll: 1,
    slidesToShow: 1,
    centerPadding: "40px",
    autoplay: false,
    responsive: [
      {
        breakpoint: 1124,

        settings: {
          dot: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 960,
        settings: {
          dot: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          centerPadding: "5px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <SlickWrapper>
      <Slider {...settings}>
        {media?.map((m, i) => {
          return (
            <div className="img-content" key={i}>
              {m.type === "image" ? (
                <img
                  src={m.url}
                  loading="lazy"
                  width="486"
                  height="324"
                  alt="slide_image"
                />
              ) : (
                <VideoPlayer
                  videoUrl={m.url}
                  showControls={showControls}
                  startPlay={startPlay}
                />
              )}
            </div>
          );
        })}
      </Slider>
    </SlickWrapper>
  );
};

const SlickWrapper = styled.div`
    width: 100%;

    margin: auto;
    /* display: none; */

   .img-content{
      /* width: 20%; */
      padding: 10px;

   }

   .slick-initialized .slick-slide {
      padding: 10px;
      margin: 0;
      width: 100%;
      height: 100%;
        justify-content: center;
    }

    .slick-slider .slick-track {
      display: flex;
      
    }

    .slick-slide img {
      width: 100%;
    }

    @media (max-width: 480px) {
     .slick-slider {
    width: 100% !important;

}
    }

    @media (min-width: 600px) {
      .slick-initialized .slick-slide {
        display: flex;
        background-color
      }
    }
  `;
export default Slideshow;
