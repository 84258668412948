import React from "react";
import styled from "styled-components";
import { requestPasswordReset } from "../persistence/redux";
import { Formik, Form, Field } from "formik";
import { ErrorLabel } from "../shared/ErrorLabel";
import * as Yup from "yup";
import { useSelector } from "react-redux";

const ResetPasswordWrapper = styled.div`
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  /* margin: auto; */
  min-width: 100%;
  /* max-width:45%; */
  background-color: var(--color-white);
  position: absolute;
  left: 50%;
  bottom: -118px;
  padding: 2rem 2rem;
  transform: translate(-50%, -50%);
  h1 {
    font-size: 1.8rem;
    margin-top: 0;
    margin-bottom: 0.7rem;
    color: var(--color-primary);
  }
  p {
    color: var(--color-grey-darker);
    font-size: 1.5rem;
    width: 80%;
    margin-bottom: 1.4rem;
  }

  @media screen and (min-width: 600px) {
    min-width: 70.5%;
    height: 300px;
    top: 50%;
    border-radius: 30px;
    bottom: 0;

    .btn-hero {
      margin-top: 2rem;
    }
  }
  @media screen and (min-width: 960px) {
    min-width: 50.5%;
  }
  @media screen and (min-width: 1200px) {
    min-width: 25.5%;
  }
`;
const ResetPassword = ({ dispatch }) => {
  const { errorMsg } = useSelector((state) => state.forgot_password);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(requestPasswordReset(values));
    setSubmitting(false);
  };
  return (
    <ResetPasswordWrapper onClick={(e) => e.stopPropagation()}>
      <h1>Reset Password</h1>
      <p>Enter your email address for a link to reset your password</p>
      <div>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={handleSubmit}
          validationSchema={ForgotPasswordSchema}
        >
          {({ errors, touched }) => {
            return (
              <Form>
                <div className="form-group">
                  <Field
                    className="form-control"
                    type="text"
                    placeholder="Email"
                    name="email"
                    // validate={validatePassword}
                  />

                  {errors.email && touched.email ? (
                    <ErrorLabel message={errors.email} />
                  ) : null}

                  {errorMsg !== "" ? <ErrorLabel message={errorMsg} /> : null}
                </div>

                <button type="submit" className="btn btn-hero">
                  Reset
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ResetPasswordWrapper>
  );
};

export default ResetPassword;
