import React from "react";
import styled from "styled-components";

const UserStyle = styled.div`
  background-color: var(--color-grey-dark);
  margin-bottom: 0.625rem;
  padding: 0.7rem;
  display: flex;
  align-items: center;
  .img-container {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
  }

  p {
    font-size: 1.2rem;
    color: var(--color-primary);
  }
  @media (min-width: 600px) {
  }
`;
const User = ({ result, action = () => {} }) => {
  return (
    <UserStyle
      onClick={(e) => {
        e.preventDefault();
        action();
      }}
    >
      <div className="img-container">
        <img src={result.profilePhoto} alt="" />
      </div>
      <p>{result.username}</p>
    </UserStyle>
  );
};

export default User;
