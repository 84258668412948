//  ***** Disclaimer *****
//  This file contains the style stettings for use in the project.
//  You can create more of these files and link them in the themeSetup.js
//  uses f => factor values that can change but is ussually set on
//  *********************

const generic = {
  color: {
    primary: {
      bg: "#0f6987", //blue
      fg: [
        "#ffffff", //white
        "#cccccc", //middle_grey
      ],
    },
    secondary: {
      bg: "#4C4C4C", //dark grey
      fg: [
        "#4C4C4C", //white
      ],
    },
    accent: {
      bg: "#ffcc00", //yellow
      fg: [
        "#333333", //grey
        "#0f6987", //blue
        "#0F6987", //disabled
        "#cccccc", //middle_grey
        "#093A4A", //deep_blue
        "#0D6583", //light_blue
        "#053241", //bonus_blue
      ],
    },
    light: {
      bg: "#ffffff", //white
      fg: [
        "#333333", //grey
        "#4C4C4C", //dark grey
      ],
    },
    light_grey: {
      bg: "#E5E5E5", //light grey
      fg: [
        "#333333", //dark grey
      ],
    },
    grey: {
      bg: "#EEEEEE", //grey
      fg: [
        "#333333", //dark grey
      ],
    },
    middle_grey: {
      bg: "#cccccc", //middle grey
      fg: [
        "#0f6987", //blue
        "#333333", //dark grey
      ],
    },
    dark_grey: {
      bg: "#4C4C4C", //dark grey
      fg: [
        "#ffffff", //white
      ],
    },
    error: {
      bg: "#ffffff", //white
      fg: [
        "#BE1E50", //red
      ],
    },
  },
  size: {
    f0: "0px",
    f1: "8px",
    f2: "10px",
    f3: "12px",
    f4: "14px",
    f5: "16px",
    f6: "18px",
    f7: "20px",
    f8: "24px",
    f9: "28.72px",
    f10: "32px",
    f11: "40px",
    f12: "64px",
  },
  weight: {
    f0: 0,
    f1: 100,
    f2: 200,
    f3: 300,
    f4: 400,
    f5: 500,
    f6: 600,
    f7: 700,
    f8: 800,
    f9: 900,
    f10: 1000,
  },
  radius: {
    f0: "0px",
    f1: "2px",
    f2: "6px",
    f3: "8px",
    f4: "10px",
    f5: "15px",
    f6: "20px",
    f7: "32px",
    f8: "40px",
    f9: "50px",
    f10: "100px",
  },
  spacing: {
    f0: "0px",
    f1: "2px",
    f2: "4px",
    f3: "8px",
    f4: "16px",
    f5: "24px",
    f6: "32px",
    f7: "42px",
    f8: "60px",
    f9: "80px",
    f10: "100px",
    f11: "12px",
  },
  image: {},
};

export default generic;
