import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  isOpen: false,
  componentName: null,
  extras: {},
  isClose: true,
  selectedTimeline: {},
};

const modalSlice = createSlice({
  name: REDUCER.MODAL,
  initialState,
  reducers: {
    MODAL_SAVE(state, action) {
      state.isOpen = action.payload.isOpen;
      state.isClose = action.payload.isClose;
      state.componentName = action.payload.componentName;
      state.extras = action.payload.extras;
    },

    ADD_SELECTED_TIMELINE(state, action) {
      state.selectedTimeline = action.payload;
    },
    REMOVE_SELECTED_TIMELINE(state) {
      state.selectedTimeline = {};
    },
    UPDATE_SELECTED_TIMELINE(state, action) {
      state.selectedTimeline.hasliked = action.payload.hasliked;
      state.selectedTimeline.totalLikes = action.payload.totalLikes;
      state.selectedTimeline.totalComments = action.payload.totalComments;
    },
    DISMISS_MODAL(state) {
      state.isOpen = false;
      state.isClose = true;
      state.componentName = null;
      state.extras = {};
    },
  },
});

export const modalActions = modalSlice.actions;

export default modalSlice;
