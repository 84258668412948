import { suggestedTimelineActions } from "./suggestedTimelineSlice";

export const getSuggestedTimeline = (limit, page, auth) => {
  const userId = auth?.user_payload?.id;

  return async (dispatch) => {
    dispatch(suggestedTimelineActions.SUGGESTED_TIMELINE_REQUEST());

    const response = await fetch(
      `https://us-central1-cheers2heroes-245a3.cloudfunctions.net/api/suggestedTimelineStories?limit=${limit}&page=${page}&userId=${userId}`
    );

    response
      .json()
      .then((success) => {
        dispatch(
          suggestedTimelineActions.SUGGESTED_TIMELINE_SUCCESS(success.data)
        );
      })
      .catch((error) => {
        dispatch(
          suggestedTimelineActions.SUGGESTED_TIMELINE_FAILED(
            "Couldn't load suggested timeline..."
          )
        );
      });
  };
};
