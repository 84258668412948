import React from "react";
import styled from "styled-components";
import { FLAG_OPTIONS } from "../constants/appConstants";
import { reportTimeline } from "../persistence/redux";

const InappropriateWrapper = styled.div`
  background: white;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* height: 200px; */
  border-radius: 0.9rem;
  h1 {
    text-align: center;
    color: var(--color-primary);
  }
  a {
    padding: 2rem;
    color: black;
    border: 1px solid var(--color-grey-dark);
    /* border: 1px solid red; */
  }

  a:first-child {
    border-top: none;
  }

  a:last-child {
    border: none;
    /* border: 1px solid red; */
  }

  @media (max-width: 550px) {
    max-width: 80%;
  }
  @media (min-width: 600px) {
    max-width: 40%;
  }
  @media (min-width: 1200px) {
    max-width: 20%;
  }
`;

const InappropriatePostModal = ({ dispatch, extras }) => {
  return (
    <InappropriateWrapper onClick={(e) => e.stopPropagation()}>
      <h1 className="heading">Report Post</h1>
      {FLAG_OPTIONS.map((flag, index) => {
        return (
          <a
            onClick={() => dispatch(reportTimeline(extras.storyId, flag))}
            href="#"
            key={index}
          >
            {flag}
          </a>
        );
      })}
    </InappropriateWrapper>
  );
};

export default InappropriatePostModal;
