import { categoryActions } from "./categorySlice";

export const getCategories = () => {
  return async (dispatch) => {
    dispatch(categoryActions.CATEGORY_REQUEST());

    const payloadData = await fetch(
      "https://us-central1-cheers2heroes-245a3.cloudfunctions.net/api/categories"
    );

    const jsonValue = await payloadData.json();

    if (payloadData.status === 200) {
      dispatch(categoryActions.CATEGORY_SUCCESS(jsonValue.data));
    } else {
      dispatch(
        categoryActions.CATEGORY_FAILED("Cannot load categories data...")
      );
    }
  };
};
