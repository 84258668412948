import React, { useEffect } from "react";
import "./Login.css";
import C2H from "../assets/icons/C2H_logo.png";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { loginUser, updateThirdPartyLoginUser } from "../persistence/redux";
import { ErrorLabel } from "../shared/ErrorLabel";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { app } from "../utils/firebase";
import { THIRD_PARTY_PROVIDERS } from "../constants/appConstants";
import { modalActions } from "../persistence/redux/modal/modalSlice";
import * as Yup from "yup";
import { SpinnerSVG } from "./svgs/SpinnerSVG";
import { useSelector } from "react-redux";
import { loginActions } from "../persistence/redux/login/loginSlice";
import { FaFacebookF, FaGoogle } from "react-icons/fa";
import { categoryActions } from "../persistence/redux/category/categorySlice";
// ../../persistence/redux/category/categorySlice
const Login = ({ dispatch }) => {
  const user_login = useSelector((state) => state.login);

  useEffect(() => {
    dispatch(loginActions.CLEAR_LOGIN());
  }, []);

  useEffect(() => {}, [user_login]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),

    password: Yup.string().required("Password can't be empty!"),
  });
  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(loginUser(values));

    dispatch(categoryActions.CLEAR_TIMELINEFILTER());
  };

  const handleGoogleLogin = () => {
    const googleProvider = new GoogleAuthProvider();
    const f_auth = getAuth(app);
    signInWithPopup(f_auth, googleProvider)
      .then((googleUser) => {
        dispatch(
          updateThirdPartyLoginUser(googleUser, THIRD_PARTY_PROVIDERS.GOOGLE)
        );
      })
      .catch((error) => {});
  };

  const handleFacebookLogin = () => {
    const facebookProvider = new FacebookAuthProvider();
    const f_auth = getAuth();

    signInWithPopup(f_auth, facebookProvider)
      .then((result) => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        // const credential = FacebookAuthProvider.credentialFromResult(result);
        // const accessToken = credential.accessToken;
        dispatch(
          updateThirdPartyLoginUser(result, THIRD_PARTY_PROVIDERS.FACEBOOK)
        );
      })
      .catch((error) => {});
  };

  const handleAppleLogin = () => {
    const appleProvider = new OAuthProvider("apple.com");
    const f_auth = getAuth();

    signInWithPopup(f_auth, appleProvider)
      .then((fbUser) => {
        dispatch(
          updateThirdPartyLoginUser(fbUser, THIRD_PARTY_PROVIDERS.FACEBOOK)
        );
      })
      .catch((error) => {});
  };

  return (
    <div className="login-overview" onClick={(e) => e.stopPropagation()}>
      <div className="container">
        <div className="login__logo_container">
          <img src={C2H} alt="app logo" />
        </div>

        <div className="login-section">
          <h1 className="headertxt">Login</h1>
          <p className="p-txt">Log in with </p>

          <div className="auth-btn">
            <button className="btn-google icon" onClick={handleGoogleLogin}>
              <FaGoogle style={{ fontSize: "1.8rem" }} />
            </button>
            <button className="btn-fb icon" onClick={handleFacebookLogin}>
              <FaFacebookF style={{ fontSize: "1.8rem" }} />
            </button>
          </div>

          <section className="section">
            <Formik
              initialValues={{ email: "", password: "" }}
              onSubmit={handleSubmit}
              validationSchema={LoginSchema}
            >
              {({ errors, isSubmitting, touched }) => {
                return (
                  <Form>
                    <div className="form-group">
                      <Field
                        autoComplete="off"
                        className="form-control"
                        type="text"
                        placeholder="Email"
                        name="email"
                      />
                      {errors.email && touched.email ? (
                        <ErrorLabel message={errors.email} />
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Field
                        autoComplete="off"
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        name="password"
                      />
                      {errors.password && touched.password ? (
                        <ErrorLabel message={errors.password} />
                      ) : null}
                    </div>

                    <Link
                      onClick={() => {
                        const payload = {
                          componentName: "ResetPassword",
                          isOpen: true,
                          isClose: false,
                        };
                        dispatch(modalActions.MODAL_SAVE(payload));
                      }}
                    >
                      Forgot password
                    </Link>
                    <ErrorLabel message={user_login.errorMsg} />
                    <div className="logon-section">
                      <button type="submit" className="btn btn-hero">
                        {user_login.loading ? (
                          <SpinnerSVG
                            color="var(--color-secondary)"
                            style={{
                              margin: "auto",
                              height: "25px",
                              width: "25px",
                            }}
                          />
                        ) : (
                          "Log in"
                        )}
                      </button>
                      <a
                        onClick={() => {
                          const payload = {
                            componentName: "Signup",
                            isOpen: true,
                            isClose: false,
                          };
                          dispatch(modalActions.MODAL_SAVE(payload));
                        }}
                        className="account"
                      >
                        Sign up
                      </a>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Login;
