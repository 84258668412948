import React from "react";
import TimelineList from "./TimelineList";
import * as tl from "./timelinelist.module.css";
import styled from "styled-components";
import { getTimelineDateFormat } from "../../component/utils/dateUtil";

const TimelinePageStyle = styled.div`
  width: 100%;
  overflow: auto;
  margin-top: 16px;
  /* border: 1px solid red; */
  /* @media screen and (min-width: 960px) { */
  @media screen and (min-width: 1100px) {
    width: 50%;

    .date p {
      font-size: 1.2rem;
      margin-bottom: 0rem;
    }
  }
`;

const TimelinePage = ({ dispatch, timelineData, onPageScroll }) => {
  return (
    <TimelinePageStyle onScroll={onPageScroll}>
      {timelineData.map((timelineListItems, i) => {
        return (
          <div key={i}>
            <div className={tl.date}>
              <p>
                {timelineListItems.time === "Now"
                  ? timelineListItems.time
                  : getTimelineDateFormat(timelineListItems.time)}
              </p>
              <h2>
                {timelineListItems.time === "Now"
                  ? ""
                  : getTimelineDateFormat(
                      timelineListItems.time,
                      "eee d",
                      true
                    )}
              </h2>
            </div>
            <TimelineList
              dispatch={dispatch}
              timelineListItems={timelineListItems.stories}
            />
          </div>
        );
      })}
    </TimelinePageStyle>
  );
};

export default TimelinePage;
