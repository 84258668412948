import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: true,
  suggested_timeline_payload: [],
  errorMsg: null,
};

const suggestedTimelineSlice = createSlice({
  name: REDUCER.SUGGESTEDTIMELINE,
  initialState,
  reducers: {
    SUGGESTED_TIMELINE_REQUEST(state) {
      state.loading = true;
    },
    SUGGESTED_TIMELINE_SUCCESS(state, action) {
      state.loading = false;
      state.suggested_timeline_payload = action.payload;
      state.errorMsg = null;
    },
    SUGGESTED_TIMELINE_FAILED(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
    },
  },
});

export const suggestedTimelineActions = suggestedTimelineSlice.actions;

export default suggestedTimelineSlice;
