import React from "react";
import styled from "styled-components";
import Avatar from "../../assets/images/image5-cropped.jpg";

const NotificationBound = styled.div`
  display: flex;
  background-color: white;
  border-radius: 0.4rem;
  margin: 1rem 0;

  @media (min-width: 600px) {
    background-color: #efefef;
  }
  h2 {
    color: var(--color-primary);
  }
  p {
    font-size: 1.4rem;
  }
`;
const ImageOuterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100px;
  margin: 0.7rem;
  flex-shrink: 0;
`;
const ImageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ImageWrapper = styled.div`
  margin: 0.3rem;
  border-radius: 2rem;
`;

const Notification = ({ notification }) => {
  let images = notification.story.media.filter((item) => item.type === "image");

  return (
    <NotificationBound>
      <ImageOuterWrapper>
        <ImageWrapper>
          {images.length > 0 ? (
            <img src={images[0].url} width="40" height="40" />
          ) : (
            <img src={Avatar} width="40" height="40" />
          )}
        </ImageWrapper>
      </ImageOuterWrapper>

      <ImageContent>
        <h2>{notification.title}</h2>
        <p>{notification.body}</p>
      </ImageContent>
    </NotificationBound>
  );
};

export default Notification;
