import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: true,
  profile_update_payload: null,
  errorMsg: null,
};

const profileUpdateSlice = createSlice({
  name: REDUCER.PROFILEUPDATE,
  initialState,
  reducers: {
    PROFILEUPDATE_REQUEST(state) {
      state.loading = true;
    },

    PROFILEUPDATE_FAIL(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
    },
  },
});

export const profileUpdateActions = profileUpdateSlice.actions;

export default profileUpdateSlice;
