/* istanbul ignore file */
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import { REDUCER } from "../../constants/appConstants";
import loginSlice from "./login/loginSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import timelineSlice from "./timeline/timelineSlice";
import singleTimelineSlice from "./timeline/singleTimelineSlice";
import popularTimelineSlice from "./timeline/popularTimelineSlice";
import suggestedTimelineSlice from "./timeline/suggestedTimelineSlice";
import categorySlice from "./category/categorySlice";
import commentSlice from "./comment/commentSlice";
import modalSlice from "./modal/modalSlice";
import signupSlice from "./login/signupSlice";
import forgotPasswordSlice from "./login/forgotPasswordSlice";
import authSlice from "./auth/authSlice";
import notificationSlice from "./notifications/notificationSlice";
import liketimelineSlice from "./timeline/likedtimelineSlice";
import profileUpdateSlice from "./profile/profileUpdateSlice";
import recognitionSlice from "./profile/recognitionSlice";
import sharedSlice from "./profile/sharedSlice";

const rootReducer = combineReducers({
  [REDUCER.AUTH]: authSlice.reducer,
  [REDUCER.SIGNUP]: signupSlice.reducer,
  [REDUCER.LOGIN]: loginSlice.reducer,
  [REDUCER.FORGOT_PASSWORD]: forgotPasswordSlice.reducer,
  [REDUCER.TIMELINE]: timelineSlice.reducer,
  [REDUCER.SINGLE_TIMELINE]: singleTimelineSlice.reducer,
  [REDUCER.LIKED_TIMELINE]: liketimelineSlice.reducer,
  [REDUCER.PROFILEUPDATE]: profileUpdateSlice.reducer,
  [REDUCER.POPULARTIMELINE]: popularTimelineSlice.reducer,
  [REDUCER.COMMENT]: commentSlice.reducer,
  [REDUCER.SUGGESTEDTIMELINE]: suggestedTimelineSlice.reducer,
  [REDUCER.CATEGORY]: categorySlice.reducer,
  [REDUCER.MODAL]: modalSlice.reducer,
  [REDUCER.NOTIFICATION]: notificationSlice.reducer,
  [REDUCER.RECOGNITION]: recognitionSlice.reducer,
  [REDUCER.SHARED]: sharedSlice.reducer,
});
const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [[REDUCER.LOGIN]],
  //whitelist: [REDUCER.AUTH],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
export default store;
export const persistor = persistStore(store);
