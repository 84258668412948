import React from "react";
import styled from "styled-components";

const SearchTimeline = styled.div`
  h1 span {
    color: black;
    font-weight: 300;
  }
`;

const SearchedTimeline = ({ searchTerm }) => {
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    900: 2,
  };

  return (
    <SearchTimeline>
      <h1>
        <span>Search results for:</span>
        {searchTerm}
      </h1>
    </SearchTimeline>
  );
};

export default SearchedTimeline;
