import React from "react";
import styled from "styled-components";
import { FaWhatsapp, FaFacebookF, FaTwitter } from "react-icons/fa";
import { HiLink } from "react-icons/hi";
import { SOCIAL_LINKS } from "../constants/appConstants";
import { toast } from "react-toastify";

const ShareWrapper = styled.div`
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  /* margin: auto; */
  min-width: 100%;
  /* max-width:45%; */
  background-color: var(--color-white);
  position: absolute;
  left: 50%;
  bottom: -118px;
  padding: 1rem 2rem;
  transform: translate(-50%, -50%);
  h1 {
    text-align: center;
    font-size: 1.4rem;
    margin-top: 0;
    color: var(--color-primary);
  }
  ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin-bottom: 12rem;
    li {
      a {
        display: block;
        padding: 2rem;
        border-radius: 50%;
        /* border: 1px solid red; */
        text-align: center;
        /* width: 60px;
        height: 60px; */
        width: 80%;
        height: 80%;
        overflow: hidden;
      }
      .whatsapp {
        background-color: #43d57e;
        display: flex;
        justify-content: center;
        flex: 1;
        align-items: center;
        svg {
          font-size: 3rem;
          fill: white;
        }
      }
      .instagram {
        background-color: #d46300;
        display: flex;
        justify-content: center;
        flex: 1;
        align-items: center;
        svg {
          font-size: 3rem;
          fill: white;
        }
      }
      .facebook {
        background-color: #077cd5;
        display: flex;
        justify-content: center;
        flex: 1;
        align-items: center;
        svg {
          font-size: 3rem;
          fill: white;
        }
      }
      .copy-link {
        background-color: var(--color-grey-dark);
        display: flex;
        justify-content: center;
        flex: 1;
        align-items: center;
        svg {
          font-size: 3rem;
          fill: var(--color-primary);
        }
      }

      .twitter {
        background-color: #00acee;
        display: flex;
        justify-content: center;
        flex: 1;
        align-items: center;
        svg {
          font-size: 3rem;
          fill: var(--color-white);
        }
      }
    }
  }

  @media (min-width: 600px) {
    min-width: 50%;
    max-width: 50%;
    padding: 1rem 2rem;
    ul {
      margin-bottom: 8rem;
    }
    a {
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 1024px) {
    min-width: 30%;
  }
`;

const handleCopyToClipboard = (id) => {
  const url = `http://www.cheers2heroes.com/?id=${id}`;
  navigator.clipboard.writeText(url).then(
    function () {
      toast.success("Link copied to clipboard!", {
        position: "bottom-left",
        autoClose: 10000,
      });
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
};
const SocialShare = ({ dispatch, extras }) => {
  return (
    <ShareWrapper style>
      <h1>Shared</h1>
      <ul className="share__social">
        <li
          className="social__item copy-link"
          onClick={() => handleCopyToClipboard(extras.id)}
        >
          <a href="#" className="copy-link">
            <HiLink />
          </a>
        </li>
        <li className="social__item">
          <a
            target="_blank"
            href={
              SOCIAL_LINKS.whatsapp +
              `https://www.cheers2heroes.com/?id=${extras.id}`
            }
            data-action="share/whatsapp/share"
            className="whatsapp"
            rel="noreferrer"
          >
            <FaWhatsapp />
          </a>
        </li>
        <li className="social__item">
          <a
            target="_blank"
            href={
              SOCIAL_LINKS.facebook +
              `https://www.cheers2heroes.com/?id=${extras.id}`
            }
            className="facebook"
            rel="noreferrer"
          >
            <FaFacebookF />
          </a>
        </li>
        <li className="social__item">
          <a
            href={
              SOCIAL_LINKS.twitter +
              `https://www.cheers2heroes.com/?id=${extras.id}`
            }
            target="_blank"
            rel="noreferrer"
            className="twitter"
          >
            <FaTwitter />
          </a>
        </li>
      </ul>
    </ShareWrapper>
  );
};

export default SocialShare;
