import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  errorMsg: null,
  detailsTimeline: null,
  users: [],
  selectedUser: {},
};

const singleTimelineSlice = createSlice({
  name: REDUCER.TIMELINE,
  initialState,
  reducers: {
    SINGLETIMELINE_REQUEST(state) {
      state.loading = true;
    },

    SINGLETIMELINE_SUCCESS(state, action) {
      state.loading = false;
      state.errorMsg = null;
    },

    SEARCH_USER_SUCCESS(state, action) {
      state.loading = false;
      state.users = action.payload;
    },
    SET_SELECTED_USER(state, action) {
      state.loading = false;
      state.selectedUser = action.payload;
    },
    CLEAR_SELECTED_USER(state) {
      state.loading = false;
      state.selectedUser = {};
    },
    CLEAR_USER_SEARCH(state) {
      state.loading = false;
      state.users = [];
    },

    SET_SINGLETIMELINE(state, action) {
      state.loading = false;
      state.errorMsg = null;
      state.detailsTimeline = action.payload;
    },
    REMOVE_SINGLETIMELINE(state) {
      state.loading = false;
      state.errorMsg = null;
      state.detailsTimeline = null;
    },

    LIKE_SINGLETIMELINE(state, action) {
      state.loading = false;
      state.errorMsg = null;
      state.selectedTimeline.hasLiked = !state.selectedTimeline.hasLiked;
      state.selectedTimeline = !state.selectedTimeline.hasLiked;
    },

    SINGLETIMELINE_FAILED(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
    },
  },
});

export const singleTimelineActions = singleTimelineSlice.actions;

export default singleTimelineSlice;
