import { BASEURL, api } from "../../../services/api_routes";
import { FETCH_OPTIONS, API_CONST } from "../../../constants/appConstants";
import { liketimelineActions } from "./likedtimelineSlice";

export const getLikedTimelines = (userId) => {
  return async (dispatch) => {
    dispatch(liketimelineActions.LIKE_TIMELINE_REQUEST());
    const response = await fetch(
      `${BASEURL}${api.userLikedTimelines}?userId=${userId}`,
      FETCH_OPTIONS.getData(API_CONST.GET)
    );

    response
      .json()
      .then((success) => {
        dispatch(liketimelineActions.LIKE_TIMELINE_SUCCESS(success.data));
      })
      .catch((error) => {
        dispatch(
          liketimelineActions.LIKE_TIMELINE_FAILED(
            "Error occurred",
            error.message
          )
        );
      });
  };
};
