import React from "react";

const SearchSVG = ({
  width = 40,
  height = 40,
  colorFill = "white",
  circleFill = "#efefef",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 33 33"
    >
      <circle
        id="Ellipse_127"
        data-name="Ellipse 127"
        cx="16.5"
        cy="16.5"
        r="16.5"
        fill={circleFill}
        className={props.className}
      />
      <path
        id="Path_4023"
        data-name="Path 4023"
        d="M-2405.238-603.854l-.157-.157.127-.182a5.336,5.336,0,0,0,.966-3.068,5.337,5.337,0,0,0-.422-2.09,5.354,5.354,0,0,0-1.151-1.707,5.352,5.352,0,0,0-1.707-1.151,5.339,5.339,0,0,0-2.09-.422,5.337,5.337,0,0,0-2.09.422,5.347,5.347,0,0,0-1.707,1.151,5.346,5.346,0,0,0-1.151,1.707,5.329,5.329,0,0,0-.422,2.09,5.328,5.328,0,0,0,.422,2.09,5.347,5.347,0,0,0,1.151,1.707,5.348,5.348,0,0,0,1.707,1.151,5.336,5.336,0,0,0,2.09.422,5.336,5.336,0,0,0,3.068-.966l.181-.127.157.157,2.324,2.324a.722.722,0,0,0,.514.213.722.722,0,0,0,.514-.213.727.727,0,0,0,0-1.027Zm-1.4-.374a4.272,4.272,0,0,1-1.363.919,4.259,4.259,0,0,1-1.669.337,4.256,4.256,0,0,1-1.669-.337,4.274,4.274,0,0,1-1.363-.919,4.272,4.272,0,0,1-.918-1.363,4.254,4.254,0,0,1-.337-1.669,4.254,4.254,0,0,1,.337-1.669,4.273,4.273,0,0,1,.918-1.363,4.271,4.271,0,0,1,1.363-.919,4.257,4.257,0,0,1,1.669-.337,4.263,4.263,0,0,1,1.669.337,4.269,4.269,0,0,1,1.363.919,4.266,4.266,0,0,1,.919,1.363,4.262,4.262,0,0,1,.337,1.669,4.262,4.262,0,0,1-.337,1.669A4.266,4.266,0,0,1-2406.639-604.228Z"
        transform="translate(2425.326 623.335)"
        fill={colorFill}
      />
    </svg>
  );
};

export default SearchSVG;
