export const BASEURL =
  "https://us-central1-cheers2heroes-245a3.cloudfunctions.net/api";

export const api = {
  createNewUser: "/users/createNewUser",
  users: "/users",
  userLikedTimelines: "/users/myFavoriteStories",
  timeline: "/timelineStories",
  popularTimeline: "/popularTimelineStories",
  comment: "/comments",
  shine: "/timelineStories/shineUnshine",
  updateProfile: "/updateProfile",
  updateProfilePhoto: "/updateProfilePhoto",
  notification: "/getGeneralNotifications",
  reportTimeline: "/timelineStories/report",
  recognitions: "/users/myRecognitions",
  shared: "/users/mySharedStories",
  searchTimelineStories: "/search",
};

// "https://us-central1-cheers2heroes-245a3.cloudfunctions.net/api/users/createNewUser",
