import React from "react";
import pill from "./pill.module.css";

const Pill = ({ badgeData, isSelected, action = () => {} }) => {
  return (
    <div
      className={`${pill.pill} ${isSelected ? pill.active : ""}`}
      onClick={(e) => {
        e.preventDefault();
        action();
      }}
    >
      <a>{badgeData.name}</a>
    </div>
  );
};

export default Pill;
