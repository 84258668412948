import React, { useEffect, useState } from "react";
import * as style from "./comment.module.css";
import PostImg from "../../assets/images/image5-cropped.jpg";
import { FaCommentAlt } from "react-icons/fa";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  createComment,
  getComments,
} from "../../persistence/redux/comment/commentActions";
import { Formik, Field, Form } from "formik";
import SpinnerLoader from "../../shared/animations/SpinnerLoader";

const CommentHolder = styled.div`
  width: 50%;
  margin: 2rem auto;
  /* border: 1px solid; */
  text-align: center;
`;

const LoaderWrapper = styled.div`
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Comment = ({ timeline, _dispatch }) => {
  const reduxStore = useSelector(({ comment, auth }) => {
    return { comments: comment, auth };
  });
  const { user_payload, isAuthenticated } = reduxStore.auth;

  let commentorId;
  if (user_payload) {
    commentorId = user_payload.id;
  }
  const handleComments = (values, { setSubmitting, resetForm }) => {
    const commentPayload = {
      storyId: timeline.id,
      commentorId,
      ...values,
    };
    _dispatch(createComment(commentPayload));
    resetForm({ values: "" });
    setSubmitting(false);
  };

  useEffect(() => {
    _dispatch(getComments(timeline.id));
  }, [_dispatch, timeline.id]);

  const renderedComments = () => {
    if (reduxStore?.comments?.loading) {
      return (
        <LoaderWrapper>
          <SpinnerLoader text="Loading comments..." />
        </LoaderWrapper>
      );
    }

    return reduxStore?.comments?.comment_payload?.length > 0 ? (
      reduxStore.comments.comment_payload.map((d) => {
        return (
          <div key={d.createdAt}>
            <div
              className={
                timeline.hasCommentented
                  ? style.user_comments
                  : style.user_comments__incoming
              }
            >
              <div className={style.user_comments__imgcontainer}>
                <img
                  src={d.user.profilePhoto ? d.user.profilePhoto : PostImg}
                  alt="logged in user"
                  width="40"
                  height="40"
                />
              </div>
              <p className="user-content" style={{ flex: "1" }}>
                {d.commentMessage}
              </p>
            </div>
          </div>
        );
      })
    ) : (
      <CommentHolder>
        <FaCommentAlt
          style={{
            fontSize: "7rem",
            fill: "var(--color-grey-darker)",
            margin: "1rem 0",
          }}
        />
        <p style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
          There are currently no comments!
        </p>
        <p>Be the first to comment on this timeline</p>
      </CommentHolder>
    );
  };
  return (
    <div>
      <section className={style["comment-section"]}>
        <div className="container__main">
          <h1 className="">Comments</h1>
          {renderedComments()}

          {isAuthenticated ? (
            <section className={style.message}>
              <div className="container__main">
                <Formik
                  initialValues={{ commentMessage: "" }}
                  onSubmit={handleComments}
                >
                  {({ errors, isSubmitting, touched, isValid, dirty }) => {
                    return (
                      <Form className={style.form}>
                        <div className={style["form-group"]}>
                          <Field
                            className={style["form-control"]}
                            type="text"
                            placeholder="Type your comments here..."
                            name="commentMessage"
                          />
                        </div>
                        <div className={style["btn-wrapper"]}>
                          <button
                            className={style["btn-variant"]}
                            type="submit"
                            disabled={!(isValid && dirty)}
                          >
                            {isSubmitting ? "Loading" : "Send"}
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </section>
          ) : null}
        </div>
      </section>
    </div>
  );
};

export default Comment;
