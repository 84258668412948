import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import EllipseSVG from "../svgs/EllipseSVG";
import { likeTimeline } from "../../persistence/redux";
import { modalActions } from "../../persistence/redux/modal/modalSlice";
import Interactivities from "../../shared/timeline/Interactivities";

const TimelineWrapper = styled.div`
  .timeline {
    background-color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.5rem;
    box-shadow: 0px 10px 10px #0000000d;
    margin-bottom: 2rem;
    position: relative;
  }
  .asset__wrapper.active {
    background-color: var(--color-primary);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .timelineContentWrapper {
    /* display: flex; */
    /* align-items: center; */
    justify-content: space-between;
    height: 30% !important;
  }
  .imgContainer {
    width: 100%;
    height: 120px;
    overflow: hidden;
    border-radius: 2rem;
    margin-right: 0.9rem;
  }

  .header__container {
    align-items: center;
    justify-content: space-between;
    border: 1px solid;
    text-align: center;
    background-color: #795252;
    color: var(--color-white);
    border-radius: 0.5rem;
    padding: 2rem 1rem;

    h1 {
      color: var(--color-white) !important;
      font-size: 2.3rem !important;
      margin-bottom: 0.5rem !important;
      text-transform: capitalize;
    }
    p {
      width: 100%;
      margin: auto;
    }
  }
  .contentContainer h1 {
    font-size: 1.8rem;
    margin: 0rem;
  }

  .asset__wrapper {
    background-color: var(--color-grey-dark);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .asset__wrapper--sponsored {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 9px;
    top: 15%;
    padding: 0.7rem;
    background: rgba(255, 255, 255, 0.2);
    /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
  }
  span.asset__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 30px;
    top: 15%;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .asset__wrapper:last-child img {
    width: 100%;
    height: 100%;
  }
  .content__asset__items:not(:nth-child(4)) img {
    width: 15px;
    height: 15px;
  }
  .contentContainer p {
    font-size: 1.8rem;
    margin-bottom: 0rem;
    font-size: 1.2rem;
  }

  .content__assets {
    display: flex;
    justify-content: space-evenly;
    margin-top: 1.3rem;
  }
  .content__asset__items:not(:nth-child(4)) {
    display: flex;
    align-items: center;
    border-right: 1px solid var(--color-grey-dark);
    padding-right: 0.6rem;
  }

  .content__asset__items:nth-child(4) span {
    display: none;
  }

  .content__asset__items .asset__wrapper {
    margin-right: 1rem;
  }

  @media screen and (min-width: 600px) {
    .timelineContentWrapper {
      flex-direction: column;
      /* align-items: center; */
      justify-content: space-between;
      height: 70% !important;
    }

    .header__container {
      padding: 3rem 0.6rem;

      h1 {
        color: var(--color-white) !important;
        font-size: 1.6rem !important;
      }
      p {
        width: 100%;
        margin: auto;
      }
    }
    span.asset__wrapper {
      top: 15%;
      z-index: 999;
      /* display: flex;
      align-items: center;
      justify-content: center; */
    }
    .imgContainer {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 0;
      margin-right: 0.9rem;
    }
    .content__asset__items:nth-child(4) {
      margin-left: auto;
      display: flex;
      align-items: center;
    }
    .content__asset__items:nth-child(4) span {
      font-weight: 800;
      font-size: 1.3rem;
      color: var(--color-primary);
      display: block;
    }
    .asset__wrapper {
      margin-left: 1rem;
    }
    .content__asset__items:nth-child(3) {
      border-right: none;
    }
  }
`;
const TimelineVariant = ({ data, _dispatch }) => {
  const [shineToggle, setShineToggle] = useState(data.hasLiked);
  const [shineCount, setShineCount] = useState(data.totalLikes);
  const [commentCount] = useState(data.totalComments);
  const { user_payload, isAuthenticated } = useSelector((store) => store.auth);

  let userId;
  if (user_payload) {
    userId = user_payload.id;
  }
  let params = {
    shortFormat: true,
  };
  const shineHandler = () => {
    if (!isAuthenticated) {
      const payload = {
        componentName: "Signup",
        isOpen: true,
        isClose: false,
        extras: {
          timeline: data,
        },
      };
      _dispatch(modalActions.MODAL_SAVE(payload));
    }

    if (isAuthenticated) {
      setShineToggle(!shineToggle);
      if (shineToggle) {
        setShineCount(shineCount - 1);
      } else {
        setShineCount(shineCount + 1);
      }
      const likeTimelinePayload = {
        userId,
        storyId: data.id,
      };

      _dispatch(likeTimeline(likeTimelinePayload));
    }
  };

  return (
    <TimelineWrapper>
      <div className="timeline">
        <div className="timelineContent">
          <span
            className="asset__wrapper"
            onClick={() => {
              const payload = {
                componentName: "InappropriatePost",
                isOpen: true,
                isClose: false,
                extras: {
                  storyId: data.id,
                },
              };
              _dispatch(modalActions.MODAL_SAVE(payload));
            }}
          >
            <EllipseSVG />
          </span>
          <div
            className="timelineContentWrapper"
            onClick={() => {
              const payload = {
                componentName: "PostDetail",
                isOpen: true,
                isClose: false,
                extras: {
                  timeline: data,
                  user_liked: shineToggle,
                  shineToggleFn: setShineToggle,
                },
              };
              _dispatch(modalActions.ADD_SELECTED_TIMELINE(data));

              _dispatch(modalActions.MODAL_SAVE(payload));
            }}
          >
            <div className="contentContainer">
              <div className="header__container">
                <h1>{data.title}</h1>
                <p>{data.description}</p>

                {data.isAD && (
                  <span className="asset__wrapper--sponsored">
                    Sponsored / AD
                  </span>
                )}
              </div>
            </div>
          </div>

          <Interactivities
            data={data}
            shineHandler={shineHandler}
            isAuthenticated={isAuthenticated}
            shineToggle={shineToggle}
            shineCount={shineCount}
            commentCount={commentCount}
            userPayload={user_payload}
          />
        </div>
      </div>
    </TimelineWrapper>
  );
};

export default TimelineVariant;
