import { BASEURL, api } from "../../../services/api_routes";
import { API_CONST, FETCH_OPTIONS } from "../../../constants/appConstants";
import { reportTimelineActions } from "./reportTimelineSlice";
import { modalActions } from "../modal/modalSlice";

export const reportTimeline = (storyId, flag) => {
  return async (dispatch) => {
    dispatch(reportTimelineActions.REPORT_TIMELINE_REQUEST());

    try {
      const response = await fetch(
        `${BASEURL}${api.reportTimeline}?storyId=${storyId}&flag=${flag}`,
        FETCH_OPTIONS.postData({}, null, API_CONST.PUT)
      );

      response.json().then((res) => {
        if (res.data.reported === true) {
          dispatch(modalActions.DISMISS_MODAL());
        }
      });
    } catch (error) {
      dispatch(reportTimelineActions.REPORT_TIMELINE_FAILED(error));
    }
  };
};
