import React, { useState } from "react";
import styled from "styled-components";
import SearchSVG from "../component/svgs/SearchSVG";
import { Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { searchTimeline } from "../persistence/redux/timeline/timelineAction";
import SearchUserAndTimeline from "./SearchUserAndTimeline";

const SearchWrapper = styled.div`
  margin-top: 30px;
  position: relative;
  form {
    width: 90%;
    margin: 0 auto 2rem;
    height: 48px;
    position: relative;
    border-radius: 3rem;
  }

  input {
    width: 100%;
    height: 100%;
    display: inline-block;
    outline: 0;
    border: none;
    border-radius: 3rem;
    padding-right: 4.5rem;
    padding-left: 3rem;
    color: var(--color-primary);
  }
  input::placeholder {
    color: var(--color-primary);
  }
  button {
    position: absolute;
    right: 6px;
    border: none;
    border-radius: 50%;
    top: 12%;
    cursor: pointer;
  }
  @media (min-width: 600px) {
    form {
      width: 60%;
    }
  }
`;
const Search = ({
  searchUserAndTimeline,
  showSearchPopup,
  searchTerm,
  setSearchTerm,
}) => {
  const dispatch = useDispatch();
  const [showSearchComponent, setShowSearchComponent] = useState(false);

  const searchHandler = (values, { resetForm }) => {
    // dispatch(searchTimeline(values));
    resetForm({ values: "" });
  };
  return (
    <SearchWrapper>
      <Formik initialValues={{ searchTerm: "" }}>
        {({ handleReset, handleChange, errors }) => (
          <Form>
            <Field
              name="searchTerm"
              type="text"
              placeholder="Search"
              // onBlur={() => {
              //   setShowSearchComponent((value) => !value);
              // }}
              onChange={(e) => {
                handleChange(e);
                setSearchTerm(e.target.value);
                if (e.target.value && e.target.value.length > 3) {
                  // dispatch(timelineActions.CLEAR_SEARCH_TIMELINE());
                  setShowSearchComponent(true);
                  dispatch(searchTimeline(e.target.value));
                } else {
                  setShowSearchComponent(false);
                }
              }}
            />
            <button type="submit">
              <SearchSVG
                width="33px"
                style={{ borderRadius: "50%" }}
                colorFill={"var(--color-primary)"}
              />
            </button>
          </Form>
        )}
      </Formik>
      {showSearchComponent && (
        <SearchUserAndTimeline
          showSearchPopup={setShowSearchComponent}
          searchUserAndTimeline={searchUserAndTimeline}
        />
      )}
    </SearchWrapper>
  );
};

export default Search;
