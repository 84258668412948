import React, { useEffect, useState } from "react";
import "./postdetail.css";
import Comment from "../post/Comment";
import { useSelector, useDispatch } from "react-redux";
import Slideshow from "../../shared/Slideshow";
import { SpinnerSVG } from "../../component/svgs/SpinnerSVG";
import { modalActions } from "../../persistence/redux/modal/modalSlice";
import { likeTimeline } from "../../persistence/redux";
import Interactivities from "../../shared/timeline/Interactivities";
import styled from "styled-components";
import BackButton from "../backButton/BackButton";

const PostDetail = ({ dispatch }) => {
  const _dispatch = useDispatch();

  const { selectedTimeline, loading } = useSelector((state) => state.modal);

  const { detailsTimeline } = useSelector((state) => state.single_timeline);

  const [shineToggle, setShineToggle] = useState(selectedTimeline?.hasLiked);
  const { user_payload, isAuthenticated } = useSelector((store) => store.auth);
  const [shineCount, setShineCount] = useState(selectedTimeline?.totalLikes);
  const [commentCount, setCommentCount] = useState(
    selectedTimeline?.totalComments
  );
  let userId;

  if (user_payload) {
    userId = user_payload?.id;
  }
  useEffect(() => {
    setShineToggle(selectedTimeline.hasLiked);
    setShineCount(selectedTimeline.totalLikes);
    setCommentCount(selectedTimeline.totalComments);
    if (detailsTimeline) {
      if (selectedTimeline.id === detailsTimeline.id) {
        const pl = {
          hasliked: detailsTimeline.hasLiked,
          totalLikes: detailsTimeline.totalLikes,
          totalComments: detailsTimeline.totalComments,
        };

        _dispatch(modalActions.UPDATE_SELECTED_TIMELINE(pl));
        setShineToggle(detailsTimeline.hasLiked);
        setShineCount(detailsTimeline.totalLikes);
        setCommentCount(detailsTimeline.totalComments);
      }
    }
  }, [detailsTimeline]);

  const shineHandler = () => {
    if (!isAuthenticated) {
      const payload = {
        componentName: "Signup",
        isOpen: true,
        isClose: false,
        extras: {
          timeline: selectedTimeline,
        },
      };

      dispatch(modalActions.MODAL_SAVE(payload));
    }

    if (isAuthenticated) {
      if (shineToggle) {
        const pl = {
          hasliked: false,
          totalLikes: shineCount - 1,
          totalComments: commentCount,
        };

        dispatch(modalActions.UPDATE_SELECTED_TIMELINE(pl));
        setShineToggle(false);

        setShineCount(shineCount - 1);
      } else {
        const pl = {
          hasliked: true,
          totalLikes: shineCount + 1,
          totalComments: commentCount,
        };

        dispatch(modalActions.UPDATE_SELECTED_TIMELINE(pl));
        setShineToggle(true);

        setShineCount(shineCount + 1);
      }
      const likeTimelinePayload = {
        userId: user_payload?.id,
        storyId: selectedTimeline?.id,
      };

      dispatch(likeTimeline(likeTimelinePayload));
    }
  };

  let params = {
    shortFormat: true,
  };

  return (
    <div
      className="post-details__overview"
      onClick={(e) => e.stopPropagation()}
    >
      <BackButton _dispatch={_dispatch} />

      <div className="header"></div>
      <div className="post-details__container">
        <div className="container__main">
          {selectedTimeline?.media.length === 0 ? (
            <PostWrapper>
              <div>
                <h1>{selectedTimeline?.title}</h1>
                <p className="paragraph">{selectedTimeline?.description}</p>
              </div>
            </PostWrapper>
          ) : (
            <div className="post-image__container">
              <Slideshow
                media={selectedTimeline?.media}
                showControls={true}
                startPlay={true}
              />
            </div>
          )}

          <div style={{ width: "85%", margin: "0 auto" }}>
            <h1>{selectedTimeline?.title}</h1>
            <p className="paragraph">{selectedTimeline?.description}</p>
          </div>

          <Interactivities
            userPayload={user_payload}
            data={selectedTimeline}
            shineHandler={shineHandler}
            isAuthenticated={isAuthenticated}
            shineToggle={shineToggle}
            shineCount={shineCount}
            commentCount={commentCount}
          />

          <div className="liner"></div>
          {loading ? (
            <div
              style={{
                margin: "auto",
                width: "20rem",
                height: "20rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SpinnerSVG style={{ width: "5rem" }} />
              <div
                style={{
                  fontSize: "1.3rem",
                  marginTop: "1.5rem",
                }}
              >
                Loading comments...
              </div>
            </div>
          ) : (
            <Comment timeline={selectedTimeline} _dispatch={dispatch} />
          )}
        </div>
      </div>
    </div>
  );
};
const PostWrapper = styled.div`
  width: 100%;
  height: 400px;
  /* background-color: var(--color-primary); */
  border-radius: 20px;
  margin-top: 2rem;
  overflow: hidden;
  padding: 1.2rem;
  background-color: #795252;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);

  h1 {
    font-size: 3.2rem;
    color: var(--color-white);
  }
`;
export default PostDetail;
