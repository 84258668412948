import React from "react";
import { NoNotification } from "../../component/svgs/icons";
import styled from "styled-components";

const NoNotificationWrapper = styled.div`
  display: flex;
  margin-top: 10rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const NoNotificationFound = () => {
  return (
    <NoNotificationWrapper>
      <h2 style={{ color: "var(--color-primary)" }}>No notification yet!</h2>
      <NoNotification />
      <p style={{ color: "#868686", fontSize: "1.2rem" }}>
        We'll notify you when something arrives.
      </p>
    </NoNotificationWrapper>
  );
};

export default NoNotificationFound;
