export const API_CONST = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
  ACCEPT: "*/*",
  CONNECTION: "keep-alive",
  AUTHORIZATION: (accessToken) => {
    return { Authorization: `${accessToken}` };
  },
};

export const SOCIAL_LINKS = {
  facebook: "https://www.facebook.com/sharer/sharer.php?u=",
  twitter: "https://www.twitter.com/intent/tweet/?url=",
  whatsapp: "https://api.whatsapp.com/send?text=",
};

export const FETCH_OPTIONS_BASE = {
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached,
  //mode: "cors",
  method: API_CONST.GET,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
};

export const FETCH_OPTIONS = {
  getData: (_headers) => {
    return {
      ...FETCH_OPTIONS_BASE,
      method: API_CONST.GET,
      headers: { ...FETCH_OPTIONS_BASE.headers, ..._headers },
    };
  },
  postData: (_body, extras, methodType) => {
    return {
      ...FETCH_OPTIONS_BASE,
      method: methodType ?? API_CONST.POST,
      headers: { ...FETCH_OPTIONS_BASE.headers, ...extras },
      body: JSON.stringify(_body),
    };
  },
};

export const DATE_FORMAT = {
  LONG_YEAR_FORMAT: "YYYY",
};

export const GENERAL = {};

export const APP_ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  HOME: "/home",
  SIGNUP: "/signup",
  PROFILE: "/profile",
  PROFILE_UPDATE: "/profile/update",
  TIMELINE: "/timeline",
  WILD_CARD: "*",
  ONBOARDING: "/onboarding",
  NOTIFICATION: "/notification",
  NOTIFICATION_PAGE_MOBILE: "/notification",
  EXPLORE: "/explore",
};

export const MODAL_ROUTES = {
  LOGIN: "Login",
  SIGNUP: "Signup",
  FORGOTPASSWORD: "ForgotPassword",
  POSTDETAIL: "PostDetail",
  POST: "Post",
  NOTIFICATION: "NotificationPage",
  NOTIFICATION_MENU: "Notificationmenu",
  SOCIAL_SHARE: "SocialShare",
  INAPPROPRIATE_POST: "InappropriatePost",
  RESET_PASSWORD: "ResetPassword",
  CROPPER: "ImageCropper",
};

export const REDUCER = {
  AUTH: "auth",
  TIMELINE: "timeline",
  SINGLE_TIMELINE: "single_timeline",
  LIKED_TIMELINE: "liked_timeline",
  POPULARTIMELINE: "popular_timeline",
  SUGGESTEDTIMELINE: "suggested_timeline",
  REPORT_TIMELINE: "report_timeline",
  COMMENT: "comment",
  CATEGORY: "category",
  PROFILE: "profile",
  PROFILEUPDATE: "profile_update",
  LOGIN: "login",
  SIGNUP: "signup",
  USER: "user_profile",
  FORGOT_PASSWORD: "forgot_password",
  RECOGNITION: "recognition",
  SHARED: "shared",
  //others
  MODAL: "modal",
  NOTIFICATION: "notification",
};

export const THIRD_PARTY_PROVIDERS = {
  GOOGLE: "Google",
  APPLE: "Apple",
  FACEBOOK: "Facebook",
};

export const DateFormats = {
  longYearFormat: "YYYY",
  fullDate: "MMM Do YY",
};
export const AUTH_CONST = {
  WRONG_CREDENTIALS_LOGIN: "Login Failed. Please enter correct credentials.",
  WRONG_CREDENTIALS_SIGNUP: "Signup Failed. Please enter correct credentials.",
};

export const FLAG_OPTIONS = [
  "Inappropriate Language",
  "Sexual Content",
  "Self Promotion",
];
