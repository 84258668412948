import React from "react";
import { BsFillFlagFill } from "react-icons/bs";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

function Badge({ flagged, badgeId }) {
  return (
    <>
      <BadgeStyle data-tip data-for={badgeId}>
        <BsFillFlagFill
          style={{ fontSize: "12px", fill: "var(--color-secondary)" }}
        />
      </BadgeStyle>

      <StyledReactTooltip id={badgeId} effect="solid">
        <span>{flagged}</span>
      </StyledReactTooltip>
    </>
  );
}
const StyledReactTooltip = styled(ReactTooltip)`
  background-color: var(--color-secondary) !important;
  padding: 8px 8px;
  color: black !important;
  box-shadow: 0px 0.5px 2px lightgray;
  &:after {
    border-top-color: var(--color-secondary) !important;
  }
`;
const BadgeStyle = styled.div`
  width: 30px;
  height: 30px;
  background-color: var(--color-primary);
  border-radius: 50%;
  display: flex;

  justify-content: center;
  align-items: center;
`;

export default Badge;
