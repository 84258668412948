import { Fragment } from "react";
import User from "./User";

const UserList = ({ userResult }) =>
  userResult?.map((result, i) => (
    <Fragment key={i}>
      <User result={result} />
    </Fragment>
  ));

export default UserList;
