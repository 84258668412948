import React, { useEffect } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import NotificationList from "../../component/timeline/NotificationList";
import { getNotifications } from "../../persistence/redux";

const NotificationBound = styled.div`
  background: #efefef;
  position: absolute;
  bottom: 0;
  padding: 2rem;
  width: 100%;
  overflow-y: scroll;
  height: 90vh;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;

  @media (min-width: 600px) {
    border-radius: 0;
    width: 45%;
    position: absolute;
    right: 0;
    background-color: white;
    height: 100vh;
  }
  @media (min-width: 1024px) {
    border-radius: 0;
    width: 25%;
    position: absolute;
    right: 0;
    background-color: white;
    height: 100vh;
  }
`;
const BackButton = ({ history }) => {
  return (
    <StyledButtonWrapper onClick={history.goBack}>
      <LeftIcon />
      <span>Back</span>
    </StyledButtonWrapper>
  );
};
export default function NotificationPageMobile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const notificationDta = useSelector((store) => store.notification);
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    isAuthenticated && dispatch(getNotifications());
  }, [isAuthenticated, dispatch]);

  return (
    <div>
      <BackButton history={history} />
      <NotificationBound onClick={(e) => e.stopPropagation()}>
        <div
          style={{
            backgroundColor: "#efefef",
            width: "25px",
            height: "25px",
            borderRadius: ".2rem",
            marginBottom: "1rem",
            marginLeft: "auto",
            cursor: "pointer",
          }}
          onClick={() => {}}
        ></div>
        {notificationDta.notificationPayload?.map((payload, index) => (
          <div key={index}>
            <h2>{payload.time}</h2>
            <NotificationList notificationsData={payload.notifications} />
          </div>
        ))}
      </NotificationBound>
    </div>
  );
}

const LeftIcon = styled(FaChevronLeft)``;
const StyledButtonWrapper = styled.button`
  display: flex;

  justify-content: center;
  align-items: center;
  list-style: none;
  width: 100px;
  height: 50px !important;
  background-color: transparent;
  border: none;
  align-items: center;
  cursor: pointer;
  align-self: flex-end;
  color: var(--color-secondary);
  position: absolute;
  top: 2%;
  left: 0;
  line-height: 2;

  span {
    margin-top: 2px;
    margin-left: 8px;
  }
`;
