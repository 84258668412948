import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/home/Home";
import Profile from "./pages/profile/Profile";
import Modal from "./shared/Modal";
import { APP_ROUTES } from "./constants/appConstants";
import Header from "./shared/header/Header";
import { useSelector, useDispatch } from "react-redux";
import ExplorePage from "./component/timeline/ExplorePage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProtectedRoute } from "./utils/navUtils";
import ProfileUpdate from "./component/ProfileUpdate";
import PageNotFound from "./pages/404/PageNotFound";
import NotificationPageMobile from "./pages/notification/NotificationPageMobile";

const AppRoutes = () => {
  const { isOpen, componentName, extras } = useSelector((store) => store.modal);
  const pages = [
    {
      path: APP_ROUTES.ROOT,
      component: <Home />,
      isProtected: false,
    },
    {
      path: APP_ROUTES.EXPLORE,
      component: <ExplorePage />,
      isProtected: false,
    },
    {
      path: APP_ROUTES.PROFILE,
      component: <Profile />,
      isProtected: true,
    },
    {
      path: APP_ROUTES.PROFILE_UPDATE,
      component: <ProfileUpdate />,
      isProtected: true,
    },
    {
      path: APP_ROUTES.NOTIFICATION_PAGE_MOBILE,
      component: <NotificationPageMobile />,
      isProtected: true,
    },
  ];
  const dispatch = useDispatch();
  return (
    <>
      <Router>
        <Switch>
          {pages.map((e, index) => {
            if (e.isProtected) {
              return (
                <ProtectedRoute key={index} exact path={e.path}>
                  <div style={{ minHeight: "100%" }}>
                    <Header />
                    {e.component}
                  </div>
                </ProtectedRoute>
              );
            }
            return (
              <Route key={index} exact path={e.path}>
                <div style={{ minHeight: "100%" }}>
                  <Header />
                  {e.component}
                </div>
              </Route>
            );
          })}
          <Route exact path={APP_ROUTES.WILD_CARD} component={PageNotFound} />
        </Switch>
      </Router>

      <Modal
        isOpen={isOpen}
        isClose
        componentName={componentName}
        _dispatch={dispatch}
        extras={extras}
      />
      <ToastContainer hideProgressBar={true} />
    </>
  );
};

export default AppRoutes;
