import React, { useContext, Suspense } from "react";
import * as tl from "./timelinelist.module.css";
import Timeline from "./Timeline";
import TimelineVariant from "./TimelineVariant";

import TimelineListShimmer from "./TimelineListShimmer";
import ThemeContext from "../../context/themeContext";
import TimelineListMobileComponent from "./TimelineListMobileComponent";

const TimelineList = ({ timelineListItems, open, dispatch, loading }) => {
  const { theme: t, isDesktop } = useContext(ThemeContext);

  return (
    <div className={tl.timelinelist}>
      <div className="container__main">
        <div className={tl.timeline__wrapper}>
          <Suspense
            fallback={
              isDesktop ? (
                <TimelineListShimmer />
              ) : (
                <TimelineListMobileComponent />
              )
            }
          >
            {timelineListItems?.map((tm, index) => {
              return (
                <div key={index}>
                  {tm.media.length === 0 ? (
                    <TimelineVariant
                      key={tm.timelineId}
                      data={tm}
                      open={open}
                      _dispatch={dispatch}
                    />
                  ) : (
                    <Timeline
                      _dispatch={dispatch}
                      key={tm.timelineId}
                      data={tm}
                    />
                  )}
                </div>
              );
            })}
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default TimelineList;
