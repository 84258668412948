import moment from "moment";
import { DateFormats } from "../constants/appConstants";

export const getChatTimeStamp = (date) => {
  const currentDate = moment().format(DateFormats.fullDate);
  const chatDate = moment(date).format(DateFormats.fullDate);

  let yesterdayDate = new Date();
  yesterdayDate.setDate(new Date().getDate() - 1);

  const yesterday = moment(yesterdayDate).format(DateFormats.fullDate);

  if (chatDate === currentDate) {
    return "Today";
  } else if (chatDate === yesterday) {
    return "Yesterday";
  } else {
    return chatDate;
  }
};
