import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { SpinnerSVG } from "../../component/svgs/SpinnerSVG";

const SpinnerWrapper = styled.div`
  color: var(--color-primary);
  font-size: ${(props) => props.fontSize || "1.3rem"};
  text-align: center;
  align-items: center;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
`;
const SpinnerLoader = ({ text = "Loading more content..." }) => {
  return (
    <SpinnerWrapper fontSize="30px">
      <SpinnerSVG />
      <motion.div
        animate={{ scale: 0.8, opacity: 0.5 }}
        transition={{ repeat: Infinity, duration: 0.8, repeatType: "reverse" }}
      >
        {text}
      </motion.div>
    </SpinnerWrapper>
  );
};

export default SpinnerLoader;
