import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TimelineSearch from "./TimelineSearch";
import UserList from "./UserList";
import { modalActions } from "../persistence/redux/modal/modalSlice";

const SearchUserAndTimeline = ({ showSearchPopup }) => {
  const { postsResults, usersResults } = useSelector(
    (state) => state.timeline.searchedTimeline_payload
  );
  const dispatch = useDispatch();

  return (
    <SearchFeatureStyle>
      <h3>Users</h3>
      <UserList userResult={usersResults} />

      <h3>Posts</h3>
      {postsResults?.map((result, index) => {
        return (
          <Link
            onClick={() => {
              const payload = {
                componentName: "PostDetail",
                isOpen: true,
                isClose: false,
                extras: {
                  timeline: result,
                },
              };
              dispatch(modalActions.ADD_SELECTED_TIMELINE(result));
              showSearchPopup((value) => !value);
              dispatch(modalActions.MODAL_SAVE(payload));
            }}
          >
            <TimelineSearch index={index} postResult={result} />
          </Link>
        );
      })}
    </SearchFeatureStyle>
  );
};

const SearchFeatureStyle = styled.div`
  position: absolute;
  left: 50%;
  top: 150%;
  transform: translateX(-50%);
  z-index: 1;
  background-color: var(--color-white);
  border-radius: 7px;
  padding: 1.5rem 1rem;
  width: 90%;
  overflow: scroll;
  margin: auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.5);
  h3 {
    color: var(--color-primary);
    margin-bottom: 0.625rem;
  }
  @media (min-width: 600px) {
    width: 60%;
  }
`;

export default SearchUserAndTimeline;
