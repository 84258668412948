import { forgotPasswordActions } from "./forgotPasswordSlice";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { app } from "../../../utils/firebase";
import { modalActions } from "../modal/modalSlice";
import { toast } from "react-toastify";

export const requestPasswordReset = (userData) => {
  const notify = toast.loading(
    "Sending Email For Password Reset. Please wait..."
  );

  return async (dispatch) => {
    dispatch(forgotPasswordActions.FORGOT_PASSWORD_REQUEST());

    const auth = getAuth(app);

    sendPasswordResetEmail(auth, userData.email)
      .then(async () => {
        dispatch(forgotPasswordActions.FORGOT_PASSWORD_SUCCESS());
        const payload = {
          componentName: "Login",
          isOpen: true,
          isClose: false,
        };
        dispatch(modalActions.MODAL_SAVE(payload));

        toast.update(notify, {
          render: "Password Reset Email Sent Successfully!",
          type: "success",
          isLoading: false,
          autoClose: 7000,
        });
      })
      .catch((error) => {
        toast.update(notify, {
          render: "Password Reset Failed! Check Email and Try Again",
          type: "error",
          isLoading: false,
          autoClose: 7000,
        });

        dispatch(forgotPasswordActions.FORGOT_PASSWORD_FAILED(error.message));
      });
  };
};

export const clearForgotPassword = () => {
  return async (dispatch) => {
    dispatch(forgotPasswordActions.CLEAR_FORGOT_PASSWORD());
  };
};
