import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  isAuthenticated: false,
  user_payload: null,
  errorMsg: "",
};

const authSlice = createSlice({
  name: REDUCER.AUTH,
  initialState,
  reducers: {
    AUTH_REQUEST(state) {
      state.loading = true;
    },
    AUTH_SUCCESS(state, action) {
      state.loading = false;
      state.user_payload = action.payload;
      state.isAuthenticated = true;
    },
    AUTH_UPDATE(state, action) {
      state.loading = false;
      state.user_payload = action.payload;
      state.isAuthenticated = true;
    },
    AUTH_FAILED(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
      state.isAuthenticated = false;
    },
    CLEAR_AUTH(state) {
      state.loading = false;
      state.user_payload = null;
      state.errorMsg = "";
      state.isAuthenticated = false;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
