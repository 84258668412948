import React from "react";
import { useSelector } from "react-redux";
import Profile from "../ProfileSummary";
import * as l from "./leftsidenav.module.css";
import Filters from "../filters/Filters";

const LeftSideNav = ({ categoryData }) => {
  const { user_payload } = useSelector((state) => state.auth);
  return (
    <div className={l.leftSide}>
      {user_payload !== null ? <Profile user={user_payload} /> : null}

      <Filters badgeData={categoryData} />
    </div>
  );
};

export default LeftSideNav;
