// React imports
import React, { useState } from "react";

// Constants
import THEME from "../constants/themeConstants";
// Theme
import Theme from "../styles/theme";

import useWindowSize from "../utils/useWindowSize";

const ThemeContext = React.createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(Theme.set(THEME.GENERIC));
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 600 ? true : false;
  const isDesktop = windowSize.width > 600 ? true : false;

  const switchTheme = (theme) => {
    setTheme(Theme.set(theme));
  };

  return (
    <ThemeContext.Provider value={{ theme, switchTheme, isMobile, isDesktop }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
