import Header from "../../shared/header/Header";
import image from "../../assets/images/404.PNG";
import styled from "styled-components";

const PageNotFound = () => {
  return (
    <>
      <Header />
      <div className="app-container">
        <Container>
          <img src={image} alt="" />
        </Container>
      </div>
    </>
  );
};

export default PageNotFound;

const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  img {
    width: 80%;
    height: 70%;
    object-fit: contain;
  }
`;
