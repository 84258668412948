import React, { Suspense } from "react";
import filters from "./filters.module.css";
import Pill from "./Pill";
import { useDispatch } from "react-redux";
import { getTimelineByCategory, getTimeline } from "../../persistence/redux";
import { categoryActions } from "../../persistence/redux/category/categorySlice";
import SortByShimmer from "../timeline/SortByShimmer";

const Filters = ({ badgeData }) => {
  const dispatch = useDispatch();
  // const auth = useSelector((state) => state.auth);

  let userId;

  return (
    <Suspense fallback={<SortByShimmer />}>
      <div className={filters.filters}>
        <h3>Sort By</h3>
        <div className={filters.pill__container}>
          {badgeData?.category_payload?.map((badge) => (
            <Pill
              key={badge.id}
              badgeData={badge}
              isSelected={badgeData.selectedSortedBy === badge.name}
              action={() => {
                dispatch(categoryActions.SET_TIMELINEFILTER(badge.name));
                dispatch(getTimelineByCategory(10, 1, userId, badge.name));
              }}
            />
          ))}
          {badgeData.selectedSortedBy ? (
            <button
              type="button"
              style={{
                margin: "20px auto",
                width: "100%",
                padding: "1rem",
                color: "var(--color-primary)",
                backgroundColor: "transparent",
                border: "1px solid var(--color-primary)",
                cursor: "pointer",
                gridColumn: "1 / -1",
              }}
              onClick={() => {
                dispatch(categoryActions.CLEAR_TIMELINEFILTER());
                dispatch(getTimeline(10, 1, userId));
              }}
            >
              Clear filter
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </Suspense>
  );
};

export default Filters;
