import { api, BASEURL } from "../../../services/api_routes";
import { recognitionActions } from "./recognitionSlice";

export const getRecognitions = (userId) => {
  return async (dispatch) => {
    dispatch(recognitionActions.RECOGNITION_REQUEST());

    try {
      const response = await fetch(
        `${BASEURL}${api.recognitions}?userId=${userId}`
      );

      response.json().then((success) => {
        dispatch(recognitionActions.RECOGNITION_SUCCESS(success.data));
      });
    } catch (error) {
      dispatch(recognitionActions.RECOGNITION_FAILED(error.message));
    }
  };
};
