import React, { useState, useContext, useEffect } from "react";
import * as line from "./timeline.module.css";
import { useSelector } from "react-redux";
import { modalActions } from "../../persistence/redux/modal/modalSlice";
import { getSingleTimeline, likeTimeline } from "../../persistence/redux";
import Slideshow from "../../shared/Slideshow";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ThemeContext from "../../context/themeContext";
import SingleImage from "../../shared/SingleImage";
import Interactivities from "../../shared/timeline/Interactivities";
import EllipseSVG from "../svgs/EllipseSVG";
import { singleTimelineActions } from "../../persistence/redux/timeline/singleTimelineSlice";

const Timeline = ({ data, _dispatch }) => {
  const { isDesktop } = useContext(ThemeContext);
  const [shineToggle, setShineToggle] = useState(data.hasLiked);
  const [shineCount, setShineCount] = useState(data.totalLikes);
  const [commentCount, setCommentCount] = useState(data.totalComments);
  const { user_payload, isAuthenticated } = useSelector((store) => store.auth);
  const { detailsTimeline } = useSelector((state) => state.single_timeline);

  useEffect(() => {
    _dispatch(modalActions.REMOVE_SELECTED_TIMELINE());
    _dispatch(singleTimelineActions.REMOVE_SINGLETIMELINE());

    setShineToggle(data.hasLiked);
    setShineCount(data.totalLikes);
    setCommentCount(data.totalComments);
  }, [data]);

  useEffect(() => {
    const { id } = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if (id) {
      _dispatch(getSingleTimeline(id, null));

      const payload = {
        componentName: "PostDetail",
        isOpen: true,
        isClose: false,
        extras: {
          timeline: data,
          user_liked: shineToggle,
          shineToggleFn: setShineToggle,
        },
      };
      _dispatch(modalActions.ADD_SELECTED_TIMELINE(detailsTimeline));
      _dispatch(modalActions.MODAL_SAVE(payload));
    }
  }, [_dispatch, shineToggle, setShineToggle, data]);

  useEffect(() => {
    if (detailsTimeline) {
      if (data.id === detailsTimeline.id) {
        const pl = {
          hasliked: detailsTimeline.hasLiked,
          totalLikes: detailsTimeline.totalLikes,
          totalComments: detailsTimeline.totalComments,
        };

        _dispatch(modalActions.UPDATE_SELECTED_TIMELINE(pl));
        setShineToggle(detailsTimeline.hasLiked);
        setShineCount(detailsTimeline.totalLikes);
        setCommentCount(detailsTimeline.totalComments);
      }
    }
  }, [detailsTimeline]);

  const timelineShortener = () => {
    return data?.description?.length > 250
      ? data?.description?.substring(0, 200) + "..."
      : data?.description;
  };

  const shineHandler = () => {
    if (!isAuthenticated) {
      const payload = {
        componentName: "Signup",
        isOpen: true,
        isClose: false,
        extras: {
          timeline: data,
        },
      };
      _dispatch(modalActions.MODAL_SAVE(payload));
    }

    if (isAuthenticated) {
      if (shineToggle) {
        const pl = {
          hasliked: false,
          totalLikes: shineCount - 1,
          totalComments: commentCount,
        };

        _dispatch(modalActions.UPDATE_SELECTED_TIMELINE(pl));

        setShineToggle(false);
        setShineCount(shineCount - 1);
      } else {
        const pl = {
          hasliked: true,
          totalLikes: shineCount + 1,
          totalComments: commentCount,
        };
        _dispatch(modalActions.UPDATE_SELECTED_TIMELINE(pl));

        setShineToggle(true);
        setShineCount(shineCount + 1);
      }
      const likeTimelinePayload = {
        userId: user_payload?.id,
        storyId: data.id,
      };

      _dispatch(likeTimeline(likeTimelinePayload));
    }
  };

  return (
    <div className={line.timeline}>
      <div className={line.timelineContent}>
        <div className={line.header__container}>
          <h1>{data.title}</h1>
          <div
            className={line.asset__wrapper}
            onClick={() => {
              const payload = {
                componentName: "InappropriatePost",
                isOpen: true,
                isClose: false,
                extras: {
                  storyId: data.id,
                },
              };
              _dispatch(modalActions.MODAL_SAVE(payload));
            }}
          >
            <EllipseSVG />
          </div>
        </div>

        <div
          // On Modal popup goes here...
          onClick={() => {
            const payload = {
              componentName: "PostDetail",
              isOpen: true,
              isClose: false,
              extras: {
                timeline: data,
                user_liked: shineToggle,
                shineToggleFn: setShineToggle,
              },
            };
            _dispatch(modalActions.ADD_SELECTED_TIMELINE(data));

            _dispatch(modalActions.MODAL_SAVE(payload));
          }}
          className={line.timelineContentWrapper}
        >
          <div className={line.imgContainer}>
            {isDesktop ? (
              <Slideshow media={data.media} showControls={false} />
            ) : (
              <SingleImage media={data.media} />
            )}
          </div>
          <div className={line.contentContainer}>
            <div className={line.header__container_wide}>
              <h1>{data.title}</h1>
            </div>
            <p>{timelineShortener()}</p>
          </div>
        </div>

        <Interactivities
          data={data}
          shineHandler={shineHandler}
          isAuthenticated={isAuthenticated}
          shineToggle={shineToggle}
          shineCount={shineCount}
          commentCount={commentCount}
          userPayload={user_payload}
        />
      </div>
    </div>
  );
};

export default Timeline;
