import React, { useState, useEffect, Suspense } from "react";
import * as r from "./rightsidenav.module.css";
import TimelineMobile from "../timeline/TimelineMobile";
import SuggestedPostShimmer from "../timeline/SuggestedPostShimmer";

const RightSideNav = ({ suggestedTimelineData, dispatch, authenticate }) => {
  return (
    <Suspense
      fallback={
        <div className={r.rightSide}>
          <div className={r.sug}>
            <p>Suggested Posts</p>
            {/* <p>See all</p> */}
          </div>
          <SuggestedPostShimmer />
          <SuggestedPostShimmer />
        </div>
      }
    >
      <div className={r.rightSide}>
        <div className={r.sug}>
          <p>Suggested Posts</p>
          {/* <p>See all</p> */}
        </div>
        {suggestedTimelineData.map((stm, i) => {
          return (
            <TimelineMobile
              dispatch={dispatch}
              authenticate={authenticate}
              key={i}
              suggestedTimeline={stm}
            />
          );
        })}
      </div>
    </Suspense>
  );
};

export default RightSideNav;
