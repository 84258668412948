import React from "react";
import styled from "styled-components";

const EllipseSVG = () => {
  return (
    <svg
      id="Group_10340"
      data-name="Group 10340"
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="3"
      viewBox="0 0 13 3"
    >
      <circle
        id="Ellipse_256"
        data-name="Ellipse 256"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(0 3) rotate(-90)"
        fill="#fff"
      />
      <circle
        id="Ellipse_257"
        data-name="Ellipse 257"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(5 3) rotate(-90)"
        fill="#fff"
      />
      <circle
        id="Ellipse_258"
        data-name="Ellipse 258"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(10 3) rotate(-90)"
        fill="#fff"
      />
    </svg>
  );
};

export default EllipseSVG;
