import { BASEURL, api } from "../../../services/api_routes";
import { notificationActions } from "./notificationSlice";
import { getChatTimeStamp } from "../../../utils/DateUtils";

export const getNotifications = () => {
  return async (dispatch) => {
    dispatch(notificationActions.NOTIFICATION_REQUEST());

    try {
      const response = await fetch(`${BASEURL}${api.notification}`);

      response
        .json()
        .then((success) => {
          let timeStamps = success.data.reduce((acc, currV) => {
            acc.push(getChatTimeStamp(currV.dateCreated));
            return [...new Set(acc)];
          }, []);

          const newArr = timeStamps.map((timeSt) => {
            let groupedNotifications = [];
            success.data.forEach((message) => {
              if (getChatTimeStamp(message.dateCreated) === timeSt) {
                groupedNotifications.push(message);
              }
            });
            return {
              time: timeSt,
              notifications: groupedNotifications.reverse(),
            };
          });

          dispatch(notificationActions.NOTIFICATION_SUCCESS(newArr));
        })
        .catch((error) => {
          dispatch(notificationActions.NOTIFICATION_FAILED(error.message));
        });
    } catch (error) {
      dispatch(notificationActions.NOTIFICATION_FAILED(error.message));
    }
  };
};
