import React, { useState, useContext, useEffect } from "react";
import Tabs from "react-simply-tabs";
import styled from "styled-components";
import TabTiles from "../shared/TabTiles";
import Placeholder from "../assets/icons/no-upload.svg";
import ThemeContext from "../context/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { getLikedTimelines } from "../persistence/redux/timeline/likedtimelineAction";
import { getRecognitions } from "../persistence/redux/profile/recognitionAction";
import { getShared } from "../persistence/redux";

const TabWrapper = styled.div`
  width: 100%;
  > div {
    width: 100%;
    div {
      text-align: center;
    }
  }
  .tabControlClassName {
    border-bottom: 2px solid "#efefef";
  }
`;

const TabsComponent = ({ user_payload }) => {
  const dispatch = useDispatch();

  const [activeTabIndex, setTabIndex] = useState(0);
  const {} = useContext(ThemeContext);

  const {} = useSelector(({ popular_timeline, timeline }) => {
    return {
      popular: popular_timeline,
      searchTimeline: timeline.searchedTimeline_payload,
    };
  });

  const { liked_timeline_payload } = useSelector(
    (state) => state.liked_timeline
  );

  const { recognition, shared } = useSelector(({ recognition, shared }) => {
    return { recognition, shared };
  });

  useEffect(() => {
    dispatch(getLikedTimelines(user_payload.id));
    dispatch(getRecognitions(user_payload.id));
    dispatch(getShared(user_payload.id));
  }, []);

  return (
    <TabWrapper>
      <Tabs
        style={{
          backgroundColor: "red",
        }}
        className="inner-tab-header"
        activeTabIndex={activeTabIndex}
        activeTabProps={{
          className: "active",
          style: {
            marginTop: "20px",
            color: "white",
          },
        }}
        activeControlProps={{
          className: "active",
          style: {
            borderBottom: "3px solid black",
          },
        }}
        onRequestChange={setTabIndex}
        controls={[
          <button
            type="button"
            title="My Shared"
            className="tabControlClassName"
            style={{
              padding: "1rem",
              fontWeight: "100",
              border: "none",
              borderBottom: "#efefef",
              marginRight: "16px",
              fontSize: "1.4rem",
              backgroundColor: "white",
            }}
          >
            My Shared
            <p
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                marginTop: ".6rem",
              }}
            >
              {shared?.sharedTimelines?.length}
            </p>
          </button>,
          <button
            type="button"
            title="My Accolades"
            className="tabControlClassName"
            style={{
              padding: "1rem",
              borderBottom: "#efefef",
              fontSize: "1.4rem",
              border: "none",
              backgroundColor: "white",
              marginRight: "16px",
            }}
          >
            My Recognitions
            <p
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                marginTop: ".6rem",
              }}
            >
              {recognition?.recognition_payload?.length}
            </p>
          </button>,
          <button
            type="button"
            title="My Likes"
            className="tabControlClassName"
            style={{
              padding: "1rem",
              borderBottom: "#efefef",
              fontSize: "1.4rem",
              border: "none",
              backgroundColor: "white",
              marginRight: "16px",
            }}
          >
            My Shines
            <p
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                marginTop: ".6rem",
              }}
            >
              {liked_timeline_payload?.length}
            </p>
          </button>,
        ]}
      >
        <div className="tabClassName">
          {shared?.sharedTimelines?.length > 0 ? (
            <TabTiles dataList={shared.sharedTimelines} />
          ) : (
            <>
              <img
                style={{
                  width: "10rem",
                  height: "10rem",
                  marginTop: "3rem",
                }}
                src={Placeholder}
                alt="profile display"
              />
              <p
                style={{
                  fontSize: "2rem",
                  color: "#efefef",
                  marginTop: ".9rem",
                }}
              >
                No shared timelines
              </p>
            </>
          )}
        </div>

        <div className="tabClassName">
          {recognition?.recognition_payload?.length > 0 ? (
            <TabTiles dataList={recognition.recognition_payload} />
          ) : (
            <>
              <img
                style={{
                  width: "10rem",
                  height: "10rem",
                  marginTop: "3rem",
                }}
                src={Placeholder}
                alt="profile display"
              />
              <p
                style={{
                  fontSize: "2rem",
                  color: "#efefef",
                  marginTop: ".9rem",
                }}
              >
                No uploads for recognitions
              </p>
            </>
          )}
        </div>

        <div className="tabClassName">
          {liked_timeline_payload?.length > 0 ? (
            <TabTiles dataList={liked_timeline_payload} />
          ) : (
            <>
              <img
                style={{
                  width: "10rem",
                  height: "10rem",
                  marginTop: "3rem",
                }}
                src={Placeholder}
                alt="profile display"
              />
              <p
                style={{
                  fontSize: "2rem",
                  color: "#efefef",
                  marginTop: ".9rem",
                }}
              >
                No Shines
              </p>
            </>
          )}
        </div>
      </Tabs>
    </TabWrapper>
  );
};

export default TabsComponent;
