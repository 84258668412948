/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from "react";
import * as asset from "./header.module.css";

import styled from "styled-components";
import {
  ExploreIcon,
  ExploreActiveIcon,
  ExploreMobile,
  ExploreActiveMobile,
  HomeActiveIconDesktop,
  HomeIconDesktop,
  HomeActiveIconMobile,
  ProfileIcon,
  ProfileActiveDesktop,
  ProfileActiveMobile,
  ProfileIconMobile,
  AddIcon,
  AddMobileIcon,
  NotificationMobileIcon,
  NotificationBellSVG,
} from "../../component/svgs/icons";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../persistence/redux/modal/modalSlice";
import { signupActions } from "../../persistence/redux/login/signupSlice";
import { loginActions } from "../../persistence/redux/login/loginSlice";
import { Link, useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../constants/appConstants";
import { authActions } from "../../persistence/redux/auth/authSlice";
import { getAuth, signOut } from "firebase/auth";
import cheers2Heroes from "../../assets/images/cheers2heroes_logo.png";
import { app } from "../../utils/firebase";
import ThemeContext from "../../context/themeContext";
import { FaChevronLeft } from "react-icons/fa";
import { notificationActions } from "../../persistence/redux/notifications/notificationSlice";
import { categoryActions } from "../../persistence/redux/category/categorySlice";

const Header = () => {
  const dispatch = useDispatch();
  const { isDesktop, isMobile } = useContext(ThemeContext);
  const history = useHistory();
  const { isAuthenticated, user_payload } = useSelector((state) => state.auth);
  const notificationData = useSelector((store) => store.notification);

  const handleSignupClick = () => {
    dispatch(signupActions.CLEAR_SIGNUP());

    const payload = {
      componentName: "Signup",
      isOpen: true,
      isClose: false,
    };
    dispatch(modalActions.MODAL_SAVE(payload));
  };
  const handleLogoutClick = () => {
    const _auth = getAuth(app);
    signOut(_auth)
      .then(() => {
        dispatch(loginActions.CLEAR_LOGIN());

        dispatch(signupActions.CLEAR_SIGNUP());

        dispatch(authActions.CLEAR_AUTH());

        dispatch(categoryActions.CLEAR_TIMELINEFILTER());

        //clear notifications
        dispatch(notificationActions.NOTIFICATION_CLEAR());
        history.replace(APP_ROUTES.ROOT);
      })
      .catch((error) => {
        alert("Couldn't log out!");
      });
  };

  const AppTitle = ({ pathname }) => {
    let title = "cheers2Heroes";
    if (pathname !== "" && pathname !== "/") {
      let parsed = pathname.substring(1);
      if (parsed.includes("/")) {
        parsed = parsed.split("/")[0];
      }
      title = parsed.charAt(0).toUpperCase() + parsed.slice(1);
      return <StyledAppTitle>{title}</StyledAppTitle>;
    }

    return <StyledAppTitle>{title}</StyledAppTitle>;
  };

  return (
    <StyledHeaderMain>
      <StyledMainContainer>
        <div className="img-container">
          <Link to="/">
            <img src={cheers2Heroes} alt="" />
          </Link>
        </div>
        <div className="nav-container">
          <BackButton history={history} isDesktop={isDesktop} />
          <AppTitle pathname={history.location.pathname} />

          {isDesktop ? (
            <StyledNav>
              <ul>
                <li title="Home" className={asset.nav__item}>
                  <HomeLink history={history} to="/" />
                </li>
                <li title="Explore" className={asset.nav__item}>
                  <ExploreLink to="/explore" history={history} />
                </li>
                <li title="Add post" className={asset.nav__item}>
                  <AddAnchor
                    onClick={() => {
                      const payload = isAuthenticated
                        ? {
                            componentName: "Post",
                            isOpen: true,
                            isClose: false,
                          }
                        : {
                            componentName: "Signup",
                            isOpen: true,
                            isClose: false,
                          };

                      dispatch(modalActions.MODAL_SAVE(payload));
                    }}
                  />
                </li>
                <li title="Profile" className={asset.nav__item}>
                  {isAuthenticated ? (
                    <ProfileLink to="/profile" history={history} />
                  ) : (
                    <AuthenticateBtn
                      onClick={() => {
                        const payload = {
                          componentName: "Signup",
                          isOpen: true,
                          isClose: false,
                        };
                        dispatch(modalActions.MODAL_SAVE(payload));
                      }}
                    />
                  )}
                </li>
                <li title="Notification" className="notification_container">
                  {notificationData?.notificationPayload?.length > 0 &&
                  isAuthenticated ? (
                    <div className="badge"></div>
                  ) : null}
                  {isAuthenticated ? (
                    <NotificationAnchor
                      href="#"
                      onClick={
                        isDesktop
                          ? () => {
                              const payload = {
                                componentName: "NotificationPage",
                                isOpen: true,
                                isClose: false,
                              };
                              dispatch(modalActions.MODAL_SAVE(payload));
                            }
                          : () => {
                              const payload = {
                                componentName: "Notificationmenu",
                                isOpen: true,
                                isClose: false,
                              };
                              dispatch(modalActions.MODAL_SAVE(payload));
                            }
                      }
                    />
                  ) : (
                    <NotificationAnchor
                      onClick={() => {
                        const payload = {
                          componentName: "Signup",
                          isOpen: true,
                          isClose: false,
                        };
                        dispatch(modalActions.MODAL_SAVE(payload));
                      }}
                    />
                  )}
                </li>
              </ul>
            </StyledNav>
          ) : null}
        </div>
        <div
          style={{
            width: "150px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            onClick={
              user_payload !== null ? handleLogoutClick : handleSignupClick
            }
            className={asset.accounts_container}
          >
            {user_payload !== null ? "Logout" : "Signup"}
          </button>
        </div>
      </StyledMainContainer>
    </StyledHeaderMain>
  );
};

export default Header;

const AddAnchor = ({ onClick }) => {
  const { isDesktop } = useContext(ThemeContext);
  return (
    <a href="#" onClick={onClick}>
      {isDesktop ? <AddIcon /> : <AddMobileIcon />}
    </a>
  );
};
const NotificationAnchor = ({ onClick }) => {
  const { isDesktop } = useContext(ThemeContext);
  return (
    <a href="#" onClick={onClick}>
      {isDesktop ? <NotificationBellSVG /> : <NotificationMobileIcon />}
    </a>
  );
};

const ExploreLink = ({ to, history }) => {
  const { isDesktop } = useContext(ThemeContext);
  if (isDesktop) {
    return (
      <Link to={to} href="#">
        {history.location.pathname === "/explore" ? (
          <ExploreActiveIcon />
        ) : (
          <ExploreIcon />
        )}
      </Link>
    );
  } else {
    return (
      <Link to={to} href="#">
        {history.location.pathname === "/explore" ? (
          <ExploreActiveMobile />
        ) : (
          <ExploreMobile />
        )}
      </Link>
    );
  }
};
const HomeLink = ({ to, history }) => {
  const { isDesktop } = useContext(ThemeContext);

  if (isDesktop) {
    return (
      <Link to={to}>
        {history.location.pathname === "/" ? (
          <HomeActiveIconDesktop />
        ) : (
          <HomeIconDesktop />
        )}
      </Link>
    );
  } else {
    return (
      <Link to={to}>
        {history.location.pathname === "/" ? (
          <HomeActiveIconMobile />
        ) : (
          <HomeActiveIconDesktop />
        )}
      </Link>
    );
  }
};
const ProfileLink = ({ to, history }) => {
  const { isDesktop } = useContext(ThemeContext);

  if (isDesktop) {
    return (
      <Link to={to}>
        {history.location.pathname === "/profile" ? (
          <ProfileActiveDesktop />
        ) : (
          <ProfileIcon />
        )}
      </Link>
    );
  } else {
    return (
      <Link to={to}>
        {history.location.pathname === "/profile" ? (
          <ProfileActiveMobile />
        ) : (
          <ProfileIconMobile />
        )}
      </Link>
    );
  }
};
const AuthenticateBtn = ({ onClick }) => {
  const { isDesktop } = useContext(ThemeContext);
  return (
    <a href="#" onClick={onClick}>
      {isDesktop ? <ProfileIcon /> : <ProfileIconMobile />}
    </a>
  );
};
const BackButton = ({ history, isDesktop }) => {
  if (!isDesktop) {
    return (
      history.location.pathname === "/profile/update" && (
        <StyledButtonWrapper onClick={history.goBack}>
          <LeftIcon />
          <span>Back</span>
        </StyledButtonWrapper>
      )
    );
  } else {
    return null;
  }
};

const StyledButtonWrapper = styled.button`
  display: flex;

  justify-content: center;
  align-items: center;
  list-style: none;
  width: 100px;
  height: 50px !important;
  background-color: transparent;
  border: none;
  align-items: center;
  cursor: pointer;
  align-self: flex-end;
  color: var(--color-secondary);
  position: absolute;
  top: 2%;
  left: 0;
  line-height: 2;

  span {
    margin-top: 2px;
    margin-left: 8px;
  }
`;

const LeftIcon = styled(FaChevronLeft)``;

const StyledHeaderMain = styled.div`
  display: flex;

  @media screen and (min-width: 600px) {
    margin-bottom: 2rem;
    display: block;
    width: 100%;
    margin: auto;
    z-index: 1;
    position: relative;
  }

  @media screen and (min-width: 2000px) {
    width: 80%;
  }
`;

const StyledMainContainer = styled.div`
  align-items: center;
  width: 100%;
  margin: auto;
  padding-bottom: 1rem;
  justify-content: space-between;

  .nav-container {
    flex: 1;
  }
  /* background-color: purple; */
  .img-container {
    display: none;
  }
  @media screen and (min-width: 600px) {
    align-items: center;
    display: flex;
    width: 75%; /*was 84% and was working just fine ... if you find any problems ... come back and change this. */
    margin: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: space-between;

    .img-container {
      display: block;
      width: 150px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
`;

const StyledAppTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: var(--color-primary);
  text-align: center;
  margin-bottom: 1.3rem;
  position: absolute;
  left: 50%;
  top: 5%;
  color: var(--color-secondary);
  transform: translate(-50%, -50%);

  @media screen and (min-width: 600px) {
    font-size: 1.4rem;
    font-weight: 800;
    color: var(--color-primary);
    text-align: center;
    margin-top: 20px;
    position: static;
    transform: translate(0%, 0%);
    margin-bottom: 1.3rem;
    display: none;
  }
`;

const StyledNav = styled.nav`
  justify-content: space-between;
  align-self: flex-end;
  position: absolute;
  z-index: 2;
  bottom: 5rem;
  width: 100%;
  padding: 1rem 0;

  ul {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    width: 94%;
    margin: auto;
    height: 75px !important;      */
    background-color: var(--color-primary);
    border-radius: 5rem;
    align-items: center;

    li {
      &:nth-child(3) {
        a {
          background-color: var(--color-secondary);
          border-radius: 50%;
          transform: scale(1.2);
          width: 40px;
          height: 40px;

          img {
            width: 15px;
            height: 15px;
          }
        }
      }
      a {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media screen and (min-width: 600px) {
    justify-content: space-between;
    align-self: flex-end;
    position: static;
    z-index: 2;
    padding: 0;

    ul {
      list-style: none;
      display: flex;
      justify-content: space-evenly;
      width: 260px;
      margin: auto;
      height: 60px !important;
      background-color: var(--color-white);
      border-radius: 5rem;
      align-items: center;

      li[title="Notification"].notification_container {
        position: relative;
      }
      li[title="Notification"].notification_container .badge {
        background-color: #ff4949;
        text-align: center;
        position: absolute;
        right: 8px;
        top: 7px;
        padding: 7px 7px;
        font-size: 1rem;
        color: white;
        border-radius: 50%;
        
      }

      li {
        &:nth-child(3) {
          a {
            width: 40px;
            aspect-ratio: 1;
            background-color: var(--color-white);
            img {
            }
          }
        }
        a {
          img {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
`;
