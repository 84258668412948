import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import user from "../../assets/icons/user.svg";
import * as s from "../../styles/globalStyles";
import ThemeContext from "../../context/themeContext";

import { useSelector } from "react-redux";
import TabsComponent from "../../component/TabComponent";
import UserProfileSummary from "../../component/UserProfileSummary";
import BottomNavBar from "../../component/bottom_navbar/BottomNavBar";

const Profile = () => {
  const { theme: t, isDesktop, isMobile } = useContext(ThemeContext);
  const { user_payload } = useSelector((state) => state.auth);

  const history = useHistory();
  return (
    <>
      <s.Screen
        t={t}
        style={{
          height: isMobile ? "90vh" : "90%",
          bottom: isMobile ? "0rem" : "2rem",
          position: "absolute",
        }}
      >
        <s.Container
          ai={"center"}
          jc={"center"}
          flex={1}
          style={{
            maxWidth: isDesktop ? "60%" : isMobile ? "100%" : "70%",
            margin: "auto",
            marginTop: isDesktop ? "3rem" : isMobile ? "0rem" : "3rem",
            backgroundColor: "white",
            overflowY: "scroll",
            // paddingTop: "8rem",
            borderTopRightRadius: isDesktop
              ? "1rem"
              : isMobile
              ? "3rem"
              : "2rem",
            borderTopLeftRadius: isDesktop
              ? "1rem"
              : isMobile
              ? "3rem"
              : "2rem",
          }}
        >
          <s.Container ai={"center"} jc={""}>
            <s.SpacerXLarge t={t} />
            {isDesktop ? (
              <>
                <UserProfileSummary
                  data={user_payload}
                  t={t}
                  onEditPressed={() => {
                    history.push("/profile/update", {
                      userId: user_payload.id,
                      profession: user_payload.profession,
                      summary: user_payload.summary,
                      profilePhoto: user_payload.profilePhoto,
                      email: user_payload.email,
                      username: user_payload.username,
                    });
                  }}
                />
                <s.SpacerLarge t={t} />
              </>
            ) : (
              <>
                <s.Container fd={"row"} ai={"center"} style={{ width: "85%" }}>
                  <s.Container
                    style={{
                      width: "25%",
                      borderRadius: "50%",
                      overflow: "hidden",
                      boxShadow: " 2px 2px 20px rgb(0 0 0 / 22%)",
                      border: "3px solid white",
                      flex: "0 auto",
                    }}
                  >
                    {user_payload.profilePhoto ? (
                      <img
                        src={user_payload?.profilePhoto}
                        alt="user profile display"
                      />
                    ) : (
                      <img src={user} alt="user profile display" />
                    )}
                  </s.Container>

                  <s.SpacerSmall t={t} />

                  <s.TextHero style={{ marginRight: "auto" }} t={t}>
                    {user_payload?.firstName !== "" &&
                    user_payload?.lastName !== ""
                      ? `${user_payload?.firstName} ${user_payload?.lastName}`
                      : `${user_payload?.username}`}

                    <s.TextCaption style={{ fontWeight: "300" }} t={t}>
                      {user_payload?.profession}
                    </s.TextCaption>
                  </s.TextHero>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/profile/update", {
                        userId: user_payload.id,
                        profession: user_payload.profession,
                        summary: user_payload.summary,
                        profilePhoto: user_payload.profilePhoto,
                        email: user_payload.email,
                        username: user_payload.username,
                      });
                    }}
                    style={{
                      width: "25%",
                      padding: " 11px 9px",
                      textAlign: "center",
                      backgroundColor: "#efefef",
                      color: "var(--color-primary)",
                      borderRadius: "0.9rem",
                      border: "1px",
                    }}
                  >
                    Edit
                  </button>
                </s.Container>
                <s.SpacerXSmall t={t} />
                <s.Container fd={"column"}>
                  <s.TextDescription
                    style={{
                      fontWeight: "300",
                      width: "80%",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    t={t}
                  >
                    <p style={{ fontWeight: "600" }}>{user_payload?.summary}</p>
                  </s.TextDescription>
                </s.Container>
                <s.SpacerLarge t={t} />
              </>
            )}
          </s.Container>
          <s.Container ai={"center"} flex={7}>
            <TabsComponent user_payload={user_payload} />
          </s.Container>
        </s.Container>
      </s.Screen>
      {isMobile && <BottomNavBar />}
    </>
  );
};

export default Profile;
