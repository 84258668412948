import React, { useState, useRef, useEffect, Fragment } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import "./post.css";
import add from "../../assets/icons/add.svg";
import Pill from "../filters/Pill";
import { createNewTimeline, searchUsers } from "../../persistence/redux";
import { useSelector } from "react-redux";
import { ErrorLabel } from "../../shared/ErrorLabel";
import TextAreaInput from "../../shared/TextAreaInput";
import { categoryActions } from "../../persistence/redux/category/categorySlice";
import { storage } from "../../utils/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ProgressBar from "@ramonak/react-progress-bar";
import { SpinnerSVG } from "../svgs/SpinnerSVG";
import User from "../../shared/User";
import { singleTimelineActions } from "../../persistence/redux/timeline/singleTimelineSlice";
import styled from "styled-components";
import { modalActions } from "../../persistence/redux/modal/modalSlice";
import Compressor from "compressorjs";

const ButtonContainer = styled.div`
  display: flex;
  z-index: 999;
  position: absolute;
  top: -7%;
  left: 45%;
  width: 100px;
  transform: translateX(-30%);
`;

const Button = styled.button`
  padding: 10px;
  width: 95px;
  background-color: transparent;
  color: #fff;
  border-radius: 30px;
  border: 1px solid white;
  cursor: pointer;

  span {
    color: white;
    font-size: 1.4rem;
  }
`;

const Post = ({ dispatch }) => {
  const { category_payload, selectedCategory } = useSelector(
    (state) => state.category
  );

  const { users, selectedUser, loading, errorMsg } = useSelector(
    (state) => state.single_timeline
  );

  const { user_payload } = useSelector((state) => state.auth);

  const [imageUploadLimit, setImageUpLoadLimit] = useState(0);
  const [videoUploadLimit, setVideoUpLoadLimit] = useState(0);
  const [mediaItems, setMediaItems] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showProgress, setshowProgress] = useState(false);
  const [allowUpload, setAllowUpload] = useState(true);
  const inputFile = useRef(null);

  useEffect(() => {
    dispatch(singleTimelineActions.CLEAR_USER_SEARCH());
    dispatch(singleTimelineActions.CLEAR_SELECTED_USER());
  }, []);

  useEffect(() => {
    if (imageUploadLimit >= 3 && videoUploadLimit >= 1) {
      setAllowUpload(false);
    }
  }, [imageUploadLimit, videoUploadLimit]);

  const onSearchReceipient = (e) => {
    dispatch(singleTimelineActions.CLEAR_SELECTED_USER());

    if (e.target.value && e.target.value.length > 3) {
      dispatch(searchUsers(e.target.value));
    }
  };

  const upload = (e) => {
    const selectedMedia = e.target.files[0];

    if (selectedMedia === null) return;

    const fileType = selectedMedia.type.split("/")[0];

    if (fileType === "image" && imageUploadLimit >= 3) return;

    if (fileType === "video" && videoUploadLimit >= 1) return;

    let selectedFile = {
      type: fileType,
      content: selectedMedia,
    };

    if (fileType === "image") {
      const quality = selectedMedia.size > 1000000 ? 0.3 : 1;

      new Compressor(selectedMedia, {
        quality,
        success: (result) => {
          selectedFile = {
            type: fileType,
            content: result,
          };
        },
      });
    }

    setFiles((items) => [...items, selectedFile]);

    const mediaName = Date.now() + "_" + selectedMedia.name;

    const imagesRef = ref(storage, `/timelineMedia/${mediaName}`);

    const uploadTask = uploadBytesResumable(imagesRef, selectedMedia);

    setshowProgress(true);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        setUploadProgress(parseInt(progress));
        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;
          default:
            break;
        }
      },
      (error) => {
        setshowProgress(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setshowProgress(false);

          if (fileType === "video") {
            setVideoUpLoadLimit(videoUploadLimit + 1);
          } else {
            setImageUpLoadLimit(imageUploadLimit + 1);
          }
          const media = {
            thumbnailURL: "",
            type: fileType,
            url: downloadURL,
          };
          setMediaItems((items) => [...items, media]);
        });
      }
    );
  };

  const handlePostSubmit = (values, { setSubmitting, resetForm }) => {
    if (values !== null && user_payload !== null) {
      const timeline = {
        userId: user_payload.id,
        title: values.title,
        description: values.description,
        receipient: selectedUser?.id ?? values.receipient,
        media: mediaItems,
        category: selectedCategory,
        isAD: false,
      };
      dispatch(createNewTimeline(timeline));
      resetForm({ values: { receipient: "", title: "", description: "" } });
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    receipient: Yup.string()
      .min(4, "Receipient name too short!")
      .max(50, "Receipient name too long!")
      .required("Add a receipient name for this timeline"),

    title: Yup.string()
      .min(4, "Title name too short!")
      .max(50, "Title name too long!")
      .required("Add a title for this timeline"),

    description: Yup.string()
      .min(4, "Description must not be less than 4 characters!")
      .max(2000, "Description must not exceed 2000 characters!")
      .required("Add a description for this timeline"),
  });
  return (
    <div className="post__overview" onClick={(e) => e.stopPropagation()}>
      <ButtonContainer>
        <Button
          onClick={() => {
            const payload = {
              isClose: true,
              isOpen: false,
              componentName: null,
            };
            dispatch(modalActions.MODAL_SAVE(payload));
          }}
        >
          <span>Back</span>
        </Button>
      </ButtonContainer>
      <div className="container__main">
        <h1>Add Media</h1>
        <div className="upload-text">
          You can upload a maximum number of <span className="number"> 3 </span>
          images and
          <span className="number"> 1</span> video
        </div>
        <div>
          <section className="media">
            {files.map((file, i) => (
              <div className="media-container" key={i}>
                {file.type === "image" ? (
                  <img
                    src={URL.createObjectURL(file.content)}
                    alt="content box"
                    width="150px"
                    height="100px"
                  />
                ) : (
                  <video width="350px" height="100px" controls>
                    <source src={URL.createObjectURL(file.content)} />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ))}

            <div>
              <input
                type="file"
                id="file"
                accept="video/*,image/*"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={upload}
              />
            </div>
            <div
              className="media-container add-button"
              onClick={() => inputFile.current.click()}
              disabled={!allowUpload}
            >
              <img src={add} alt="content box" className="add-button-img" />
            </div>
          </section>
          {showProgress && (
            <div>
              <ProgressBar
                completed={uploadProgress}
                bgColor="var(--color-primary)"
                labelColor="var(--color-secondary)"
              />
            </div>
          )}
        </div>

        <section className="badges">
          <h1>Select your tag</h1>

          <div className="pills">
            {category_payload.map((badgeData, i) => {
              return (
                <Pill
                  badgeData={badgeData}
                  key={i}
                  isSelected={selectedCategory === badgeData.name}
                  action={() => {
                    dispatch(categoryActions.setCategory(badgeData.name));
                  }}
                />
              );
            })}
          </div>
        </section>

        <section className="post">
          <Formik
            onSubmit={handlePostSubmit}
            initialValues={{ receipient: "", title: "", description: "" }}
            validationSchema={validationSchema}
          >
            {({ errors, isSubmitting, handleChange, touched }) => {
              return (
                <Form className="form-group-variant">
                  <div className="form-group-variant">
                    <label className="form-label">Receipient Name</label>
                    <input
                      className="form-control form-control-variant"
                      type="text"
                      name="receipient"
                      placeholder="Search"
                      value={selectedUser?.username}
                      onChange={(e) => {
                        handleChange(e);
                        onSearchReceipient(e);
                      }}
                    />

                    {errors.receipient && touched.receipient ? (
                      <ErrorLabel message={errors.receipient} />
                    ) : null}
                  </div>
                  <div className="form-group-variant">
                    {users?.map((result, i) => (
                      <Fragment key={i}>
                        <User
                          result={result}
                          action={() => {
                            dispatch(
                              singleTimelineActions.SET_SELECTED_USER(result)
                            );
                            dispatch(singleTimelineActions.CLEAR_USER_SEARCH());
                          }}
                        />
                      </Fragment>
                    ))}
                  </div>

                  <div className="form-group-variant">
                    <label className="form-label">Topic / Subject</label>
                    <Field
                      className="form-control form-control-variant"
                      type="text"
                      name="title"
                    />
                    {errors.title && touched.title ? (
                      <ErrorLabel
                        style={{ paddingLeft: 0 }}
                        message={errors.title}
                      />
                    ) : null}
                  </div>
                  <div className="form-group-variant">
                    <TextAreaInput
                      rows="12"
                      className="form-control form-control-variant"
                      type="text"
                      name="description"
                      errors={errors.description}
                      label="Type your message"
                    />
                  </div>
                  {errorMsg !== "" && (
                    <ErrorLabel style={{ paddingLeft: 0 }} message={errorMsg} />
                  )}

                  <button className="btn-alt btn-hero-alt" type="submit">
                    {loading ? (
                      <div>
                        <SpinnerSVG
                          color="var(--color-secondary)"
                          style={{
                            margin: "auto",
                            height: "25px",
                            width: "25px",
                          }}
                        />
                      </div>
                    ) : (
                      "Post"
                    )}
                  </button>
                </Form>
              );
            }}
          </Formik>
        </section>
      </div>
    </div>
  );
};

export default Post;
