import styled from "styled-components";

const TextAreaStyles = styled.div`
  padding: 0;
  margin-bottom: 1.8rem;
  .form-label {
    font-size: 1.2rem;
    margin-bottom: 0.6rem;
    font-weight: bold;
  }

  .form-control {
    border-radius: 0.4rem;
    text-align: left;
  }
  textarea.form-control {
    border-radius: 0.4rem;
    padding-top: 1.5rem;
    height: 15rem;
    font-family: "Lato";
  }
`;
const TextAreaInput = ({ field, ...props }) => (
  <TextAreaStyles>
    <label className="form-label" style={{ marginBottom: "0.6rem" }}>
      About me
    </label>
    <textarea className="form-control" type="text" {...props} {...field} />
  </TextAreaStyles>
);

export default TextAreaInput;
