import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  user_payload: null,
  errorMsg: "",
};

const forgotPasswordSlice = createSlice({
  name: REDUCER.FORGOT_PASSWORD,
  initialState,
  reducers: {
    FORGOT_PASSWORD_REQUEST(state) {
      state.loading = true;
    },
    FORGOT_PASSWORD_SUCCESS(state) {
      state.loading = false;
      state.errorMsg = "";
    },

    FORGOT_PASSWORD_FAILED(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
    },
    CLEAR_FORGOT_PASSWORD(state) {
      state.loading = false;
      state.errorMsg = "";
      state.user_payload = null;
    },
  },
});

export const forgotPasswordActions = forgotPasswordSlice.actions;

export default forgotPasswordSlice;
