import { profileUpdateActions } from "./profileUpdateSlice";
import { authActions } from "../auth/authSlice";
import { BASEURL, api } from "../../../services/api_routes";
import { FETCH_OPTIONS, API_CONST } from "../../../constants/appConstants";

export const updateProfile = (updateValues) => {
  console.log(updateValues);
  return async (dispatch) => {
    dispatch(authActions.AUTH_REQUEST());

    const response = await fetch(
      `${BASEURL}${api.users}${api.updateProfile}`,
      FETCH_OPTIONS.postData(updateValues, null, API_CONST.PUT)
    );

    response
      .json()
      .then((res) => {
        if (res.success) {
          console.log(res.data);
          dispatch(authActions.AUTH_UPDATE(res.data));
        }
      })
      .catch((error) => {
        dispatch(profileUpdateActions.PROFILEUPDATE_FAIL(error.message));
      });
  };
};

export const updateProfilePhoto = (updateValues) => {
  return async (dispatch) => {
    dispatch(profileUpdateActions.PROFILEUPDATE_REQUEST());

    const response = await fetch(
      `${BASEURL}${api.users}${api.updateProfilePhoto}`,
      FETCH_OPTIONS.postData(
        { id: updateValues.id, profilePhoto: updateValues.profilePhoto },
        null,
        API_CONST.PUT
      )
    );

    response
      .json()
      .then((sucess) => {
        dispatch(authActions.AUTH_UPDATE(sucess.data));
      })
      .catch((error) => {
        dispatch(profileUpdateActions.PROFILEUPDATE_FAIL(error.message));
      });
  };
};
