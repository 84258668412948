import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { FaPlay, FaPause, FaExpand } from "react-icons/fa";

import { useSelector } from "react-redux";

const VideoWrapper = styled.div`
  margin: auto;
  height: 150px;
  position: relative;

  .videoplayer {
    width: 100%;
    /* height: 100%; */
    background: linear-gradient(black, black);
  }
  /* .videoplayer:hover {
		background: grey;
	} */

  .controls {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: -130px;
    padding: 0 1.2rem;
    align-items: center;
    transition: opacity 0.2s;

    .timer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: white;
      p.current {
        font-size: 1.5rem;
      }
      p.duration {
        font-size: 1.5rem;
      }
    }
    .timeline {
      width: 100%;
    }

    .slider-elapse-wrapper {
      display: flex;
      width: 90%;
      flex-direction: column;
    }

    input[type="range"] {
      -webkit-appearance: none;
      background: var(--color-secondary);
      cursor: pointer;
      height: 4px;
      border-radius: 30px;
      position: relative;
      width: 100%;
    }
    input[type="range"]::before {
      -webkit-appearance: none;
      content: "";
      background: var(--color-secondary);
      transform: translateY(-35%);
      display: block;
      position: absolute;
      cursor: pointer;
      height: 12px;
      border-radius: 20px;
      width: 4px;
    }
    input[type="range"]::after {
      content: "";
      background: var(--color-secondary);
      transform: translateY(-35%);
      display: block;
      position: absolute;
      cursor: pointer;
      right: 0;
      height: 12px;
      width: 4px;
      border-radius: 20px;
    }
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      background: var(--color-secondary);
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }
  }

  .icon-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    border-radius: 50%;
    position: absolute;
    width: 80px;
    height: 80px;
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid;
    display: block;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg.icon-play {
      font-size: 3rem !important;
    }
  }
  /* .icon-container:hover {
		background: black;
	} */
  .svg-container:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`;

const VideoPlayer = ({ videoUrl, showControls, startPlay }) => {
  const reduxData = useSelector(({ timeline }) => timeline.timeline_payload);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoInfo, setVideoInfo] = useState({
    currentTime: 0,
    duration: 0,
  });

  const [isControlDisplay, setControlDisplay] = useState(false);

  const videoRef = useRef(null);

  const timeUpdateHandler = (e) => {
    const current = e.target.currentTime;
    const duration = e.target.duration;
    setVideoInfo({ ...videoInfo, currentTime: current, duration });
  };

  const startInitialPlay = () => {
    if (startPlay) {
      videoplayerHandler();
    }
  };

  useEffect(() => {
    startInitialPlay();
  }, []);

  const videoplayerHandler = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(!isPlaying);
    } else {
      videoRef.current.play();
      setIsPlaying(!isPlaying);
    }
  };

  const videoTimeFormat = (time) => {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  };

  const dragHandler = (e) => {
    videoRef.current.currentTime = e.target.value;
    setVideoInfo({ ...videoInfo, currentTime: e.target.value });
  };

  const toggleFullScreen = () => {
    let elem = videoRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  return (
    <VideoWrapper
      onMouseOver={(e) => {
        e.preventDefault();
        setControlDisplay(true);
      }}
      onMouseOut={(e) => {
        e.preventDefault();
        setControlDisplay(false);
      }}
    >
      <video
        ref={videoRef}
        className="videoplayer"
        onTimeUpdate={timeUpdateHandler}
        onLoadedMetadata={timeUpdateHandler}
        // onMouseOver={() => {
        // 	console.log("hovering");
        // 	setControlDisplay(!isControlDisplay);
        // }}
        width="486"
        height="324"
      >
        <source src={videoUrl} />
      </video>
      {showControls ? (
        <div
          onClick={videoplayerHandler}
          className="icon-container"
          style={{
            display: isControlDisplay ? "flex" : "none",
          }}
        >
          {isPlaying ? (
            <FaPause
              className="icon-play"
              style={{ fill: "white", fontSize: "2rem" }}
            />
          ) : (
            <FaPlay
              className="icon-play"
              style={{ fill: "white", fontSize: "2rem" }}
            />
          )}
        </div>
      ) : (
        <div className="icon-container">
          <FaPlay
            className="icon-play"
            style={{ fill: "white", fontSize: "2rem" }}
          />
        </div>
      )}
      {showControls ? (
        <div className="controls">
          <div className="slider-elapse-wrapper">
            <div className="timer">
              <p className="current">
                {videoTimeFormat(videoInfo.currentTime)}
              </p>
              <p className="duration">{videoTimeFormat(videoInfo.duration)}</p>
            </div>
            <div className="timeline">
              <input
                onChange={dragHandler}
                type="range"
                value={videoInfo.currentTime}
                min={0}
                max={videoInfo.duration}
              />
            </div>
          </div>
          <div className="img-container">
            <FaExpand
              style={{
                fill: "var(--color-secondary)",
                fontSize: "1.9rem",
                marginTop: "2.4rem",
                marginLeft: "1.2rem",
              }}
              onClick={toggleFullScreen}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </VideoWrapper>
  );
};

export default VideoPlayer;
