import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC09qKFH6tebcuBysh9pKMgVgF5uDOaem4",
  authDomain: "cheers2heroes-245a3.firebaseapp.com",
  databaseURL: "https://cheers2heroes-245a3-default-rtdb.firebaseio.com",
  projectId: "cheers2heroes-245a3",
  storageBucket: "cheers2heroes-245a3.appspot.com",
  messagingSenderId: "843988000748",
  appId: "1:843988000748:web:4d0029d2b075213c5beac9",
  measurementId: "G-WX1L46K36M",
};

export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
// export const db = firebase();

// export default firebase;
