import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  sharedTimelines: [],
  errorMsg: "",
};

const sharedSlice = createSlice({
  name: REDUCER.SHARED,
  initialState,
  reducers: {
    SHARED_REQUEST(state) {
      state.loading = true;
    },
    SHARED_SUCCESS(state, action) {
      state.loading = false;
      state.sharedTimelines = action.payload;
    },
    SHARED_FAILED(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
    },
  },
});

export const sharedActions = sharedSlice.actions;

export default sharedSlice;
