import React from "react";
import { Field } from "formik";
import { ErrorLabel } from "./ErrorLabel";

const TextAreaInput = (props) => {
  const { label, errors, name, ...rest } = props;
  return (
    <div className="form-group-variant">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <div>
        <Field as="textarea" id={name} name={name} {...rest} />
      </div>
      <ErrorLabel message={errors} />
    </div>
  );
};

export default TextAreaInput;
