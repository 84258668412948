import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  errorMsg: null,
};

const reportTimelineSlice = createSlice({
  name: REDUCER.REPORT_TIMELINE,
  initialState,
  reducers: {
    REPORT_TIMELINE_REQUEST(state) {
      state.loading = true;
    },
    REPORT_TIMELINE_SUCCESS(state, action) {
      state.loading = false;
    },
    REPORT_TIMELINE_FAILED(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
    },
  },
});

export const reportTimelineActions = reportTimelineSlice.actions;

export default reportTimelineSlice;
