import styled from "styled-components";

import { UserPlaceholder } from "../component/svgs/icons";
const UserProfileSummary = ({ data, onEditPressed }) => {
  return (
    <ProfileImageSection>
      <ImageContainer>
        {data.profilePhoto ? (
          <img src={data.profilePhoto} alt="profile_photo" />
        ) : (
          <UserPlaceholder />
        )}
        <div></div>
      </ImageContainer>
      <button
        onClick={(e) => {
          e.preventDefault();
          onEditPressed();
        }}
        type="button"
        className="btn edit-btn"
      >
        Edit
      </button>
      <h1 className="heading">{data.username}</h1>
      <h3>{data.profession ? data.profession : ""}</h3>

      <Summary>
        <p>{data.summary ? data.summary : ""}</p>
      </Summary>
    </ProfileImageSection>
  );
};

const Summary = styled.div`
  p {
    margin-top: 15px;
    text-align: center;
  }
`;

const ProfileImageSection = styled.div`
  width: 85%;
  margin: auto;
  .heading {
    text-align: center;
    font-size: 1.7rem;
    margin: 0;
    margin-top: 1rem;
  }
  h3 {
    text-align: center;
    font-weight: 300;
  }

  button.edit-btn {
    color: var(--color-primary);
    background-color: #efefef;
    margin: auto;
    display: block;
    margin-top: -2rem;
    z-index: 2;
    position: relative;
  }
`;

const ImageContainer = styled.div`
  text-align: "center";
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 22%;
  max-width: 45%;
  /* height: 60px; */
  margin: auto;
  /* border-radius: 50%; */
  padding: 5px;

  img {
    background: white;
    object-fit: contain;
    /* padding: 5px; */
    border-radius: 50%;
    border: 5px solid white;
    box-shadow: 0 4px 6px 2px #bbb;
  }
`;
export default UserProfileSummary;
