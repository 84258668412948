import { loginActions } from "./loginSlice";
import { authActions } from "../auth/authSlice";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "../../../utils/firebase";
import { BASEURL, api } from "../../../services/api_routes";
import { dismissModal } from "..";
import { AUTH_CONST } from "../../../constants/appConstants";

export const loginUser = (userData) => {
  return async (dispatch) => {
    dispatch(loginActions.CLEAR_LOGIN());

    dispatch(loginActions.LOGIN_REQUEST());

    const auth = getAuth(app);

    signInWithEmailAndPassword(auth, userData.email, userData.password)
      .then(async (userCredential) => {
        // Signed in

        // dispatch()
        const user = userCredential.user;

        const currentUser = {
          accessToken: user.accessToken,
          id: user.uid,
          expirationTime: user.stsTokenManager.expirationTime,
          refreshToken: user.stsTokenManager.refreshToken,
          isAnonymous: user.isAnonymous,
          username: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          photoURL: user.photoURL,
          providerId: user.providerId,
        };
        const response = await fetch(
          `${BASEURL}${api.users}/${currentUser.id}`
        );
        response
          .json()
          .then((data) => {
            const _userData = {
              ...currentUser,
              ...data.data,
            };
            dispatch(loginActions.LOGIN_SUCCESS(_userData));
            dispatch(authActions.AUTH_SUCCESS(_userData));

            dispatch(dismissModal());
          })
          .catch((error) => {
            dispatch(
              loginActions.LOGIN_FAILED(AUTH_CONST.WRONG_CREDENTIALS_LOGIN)
            );
          });
      })
      .catch((error) => {
        dispatch(loginActions.LOGIN_FAILED(AUTH_CONST.WRONG_CREDENTIALS_LOGIN));
      });
  };
};

export const clearLogin = () => {
  return async (dispatch) => {
    dispatch(loginActions.CLEAR_LOGIN());
  };
};
