import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import profile from "./profile.module.css";
import ProfileSummaryShimmer from "./timeline/ProfileSummaryShimmer";
import { SpinnerSVG } from "./svgs/SpinnerSVG";

const ProfileSummary = () => {
  const { user_payload } = useSelector((state) => state.auth);
  const reduxStore = useSelector(({ recognition, shared, liked_timeline }) => {
    return { recognition, liked_timeline, shared };
  });

  return (
    <Suspense fallback={<ProfileSummaryShimmer />}>
      <div className={profile.outerContainer}>
        <div className={profile.img__container}>
          <img src={user_payload?.profilePhoto} alt="" />
        </div>

        <h2 className={profile.header}>
          {user_payload?.firstName !== "" && user_payload?.lastName !== ""
            ? `${user_payload?.firstName} ${user_payload?.lastName}`
            : `${user_payload?.username}`}
        </h2>
        <p>{user_payload?.summary}</p>
        <div className={profile.shares}>
          <div className={profile.social_tags}>
            <p>My Shared</p>
            <p className={profile.shared_num}>
              {reduxStore.shared.sharedTimelines?.length}
            </p>
          </div>
          <div className={profile.social_tags}>
            <p>My Recognition</p>
            <p className={profile.shared_num}>
              {reduxStore.recognition.recognition_payload.length}
            </p>
          </div>
          <div className={profile.social_tags}>
            <p>My Shines</p>
            {reduxStore.liked_timeline.loading ? (
              <SpinnerSVG
                color="var(--color-primary)"
                style={{
                  marginLeft: "auto",
                  height: "18px",
                  width: "18px",
                }}
              />
            ) : (
              <p className={profile.shared_num}>
                {reduxStore.liked_timeline.liked_timeline_payload?.length}
              </p>
            )}
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default ProfileSummary;
