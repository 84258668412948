import React from "react";
import styled from "styled-components";

export default function TimelineListShimmer() {
  return (
    <Shimmer>
      <ShimmerImageHollow />
      <ShimmerContainer>
        <ShimmerTitle />
        <ShimmerDescription />
        <ShimmerInterativities>
          <div>
            <ShimmerButton />
            <ShimmerButton />
            <ShimmerButton />
          </div>
          <ShimmerButtonNoBar />
        </ShimmerInterativities>
      </ShimmerContainer>
    </Shimmer>
  );
}

const Shimmer = styled.div`
  background-color: var(--color-white);
  width: 95%;
  /* height: 200px; */
  padding: 8px;
`;
const ShimmerImageHollow = styled.div`
  /* 482x334 */
  width: 100%;
  height: 334px;
  background: var(--color-grey-dark);
`;
const ShimmerContainer = styled.div`
  margin-top: 8px;
`;
const ShimmerTitle = styled.div`
  background-color: var(--color-grey-dark);
  height: 33px;
  width: 45%;
  margin-bottom: 5px;
`;
const ShimmerDescription = styled.div`
  background-color: var(--color-grey-dark);
  height: 33px;
  width: 100%;
  margin-bottom: 5px;
`;

const ShimmerInterativities = styled.div`
  display: flex;
  padding-top: 16px;

  div {
    /* border: 1px solid grey; */
    margin-right: auto;
    display: flex;
    width: 50%;
    justify-content: space-between;
  }
`;

const ShimmerButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  /* outline: none; */
  border: none;

  &::after {
    /* display: block; */
    position: absolute;
    content: "";
    width: 2px;
    height: 22px;
    background-color: var(--color-grey-dark);
    right: -10px;
    top: 20%;
  }
  &:nth-child(4) {
    border: none;
    background: purple;
  }
`;

const ShimmerButtonNoBar = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  /* outline: none; */
  border: none;
`;
