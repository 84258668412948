import { modalActions } from "../../persistence/redux/modal/modalSlice";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Icons from "../../assets";
import NumericLabel from "react-pretty-numbers";
import comment from "../../assets/icons/comment.svg";
import download from "../../assets/icons/download.svg";
import { UserPlaceholder } from "../../component/svgs/icons";
import Badge from "../custom/Badge";

const TimelineInteractivitiesStyles = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-around !important;
  align-items: center;
  cursor: pointer;
  justify-content: space-evenly;
  gap: 2rem;
  width: 90%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  .active {
    background-color: blue;
  }

  .asset__wrapper {
    background-color: var(--color-grey-dark);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .active {
    background-color: var(--color-primary);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .asset__wrapper:last-child img {
    width: 100%;
    height: 100%;
  }
  .content__asset__items:not(:nth-child(4)) {
    display: flex;
    align-items: center;
    border-right: 1px solid var(--color-grey-dark);
    padding-right: 0.6rem;
  }
  .content__asset__items:not(:nth-child(4)) img {
    width: 15px;
    height: 15px;
  }

  .content__asset__items:nth-child(4) span {
    display: none;
  }

  .content__asset__items .asset__wrapper {
    margin-right: 1rem;
  }

  @media screen and (min-width: 600px) {
    .timelineContentWrapper {
      flex-direction: column;
      /* align-items: center; */
      justify-content: space-between;
      height: 70% !important;
    }

    .content__asset__items:nth-child(4) {
      margin-left: auto;
      display: flex;
      align-items: center;
    }
    .content__asset__items:nth-child(4) span {
      font-weight: 800;
      font-size: 1.3rem;
      color: var(--color-primary);
      display: block;
    }
    .asset__wrapper {
      margin-left: 1rem;
    }
    .content__asset__items:nth-child(3) {
      border-right: none;
    }
  }
`;
const Interactivities = ({
  shineHandler,
  isAuthenticated,
  shineToggle,
  shineCount,
  commentCount,
  data,
  userPayload,
}) => {
  const dispatch = useDispatch();
  let params = {
    shortFormat: true,
  };
  return (
    <TimelineInteractivitiesStyles>
      <div className="content__asset__items">
        <div
          onClick={shineHandler}
          className={shineToggle ? "asset__wrapper active" : "asset__wrapper"}
        >
          {shineToggle ? (
            <img src={Icons.shineActive} alt="" />
          ) : (
            <img src={Icons.shine} alt="" />
          )}
        </div>
        <NumericLabel params={params}>{shineCount}</NumericLabel>
      </div>
      <div
        className="content__asset__items"
        onClick={() => {
          const payload = {
            componentName: "PostDetail",
            isOpen: true,
            isClose: false,
            extras: {
              timeline: data,
            },
          };
          dispatch(modalActions.ADD_SELECTED_TIMELINE(data));
          dispatch(modalActions.MODAL_SAVE(payload));
        }}
      >
        <div className="asset__wrapper">
          <img src={comment} alt="" />
        </div>
        <NumericLabel params={params}>{commentCount}</NumericLabel>
      </div>
      <div className="content__asset__items">
        <div
          onClick={() => {
            const payload = {
              componentName: "SocialShare",
              isOpen: true,
              isClose: false,
              extras: {
                id: data.id,
              },
            };

            dispatch(modalActions.MODAL_SAVE(payload));
          }}
          className="asset__wrapper"
        >
          <img src={download} alt="" />
        </div>
      </div>

      <div>
        {data?.isReported && userPayload?.isAdmin ? (
          <Badge badgeId={data.id} flagged={`${data.flag}`} />
        ) : null}
      </div>

      <div className="content__asset__items" style={{ borderRight: "none" }}>
        <span>{data?.user?.username ? data.user.username : "Anonymous"}</span>
        <div className="asset__wrapper">
          {data?.user?.profilePhoto ? (
            <img
              src={data?.user?.profilePhoto}
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          ) : (
            <UserPlaceholder />
          )}
        </div>
      </div>
    </TimelineInteractivitiesStyles>
  );
};

export default Interactivities;
