import React, { useEffect, useState } from "react";
import styled from "styled-components";
import download from "../../assets/icons/download.svg";
import { modalActions } from "../../persistence/redux/modal/modalSlice";
import NumericLabel from "react-pretty-numbers";
import Icons from "../../assets";
import { getSingleTimeline, likeTimeline } from "../../persistence/redux";
import comment from "../../assets/icons/comment.svg";
import C2H from "../../assets/icons/C2H_logo.png";
import { singleTimelineActions } from "../../persistence/redux/timeline/singleTimelineSlice";
import { useSelector } from "react-redux";
import EllipseSVG from "../svgs/EllipseSVG";

const TimelineMobileWrapper = styled.div`
  .timeline {
    background-color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.5rem;
    box-shadow: 0px 10px 10px #0000000d;
    margin-bottom: 2rem;
  }

  .timelineContentWrapper {
    display: flex;

    align-items: center;
    gap: 1.2rem;
  }
  .imgContainer {
    max-width: 30%;
    height: auto;
    overflow: hidden;
    flex-shrink: 0;
    border-radius: 0.5rem;
  }

  .asset__wrapper.active {
    background-color: var(--color-primary);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.3rem;
  }

  .header__container h1 {
    font-size: 1.8rem;
    word-wrap: break-word;
    line-height: 1;
    margin: 0;
    flex: 3;
  }
  .contentContainer h1 {
    font-size: 1.8rem;
    margin: 0rem;
  }

  .asset__wrapper {
    background-color: var(--color-grey-dark);
    height: 30px;
    width: 30px;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .asset__wrapper:last-child img {
    width: 100%;
    height: 100%;
  }
  .contentContainer p {
    font-size: 1.8rem;
    margin-bottom: 0rem;
    font-size: 1.2rem;
  }

  .content__assets {
    display: flex;
    justify-content: space-evenly;
    margin-top: 1.3rem;
  }
  .content__asset__items:not(:nth-child(4)) {
    display: flex;
    align-items: center;
    border-right: 1px solid var(--color-grey-dark);
    padding-right: 0.6rem;
  }
  .content__asset__items:not(:nth-child(4)) img {
    width: 15px;
    height: 15px;
  }

  .content__asset__items:nth-child(4) span {
    display: none;
  }

  .content__asset__items .asset__wrapper {
    margin-right: 1rem;
  }

  @media screen and (min-width: 1200px) {
    .timeline {
    }
    .imgContainer {
      width: 70%;
      height: auto;
      overflow: hidden;
      border-radius: 0.7rem;
    }

    .timelineContentWrapper {
      display: flex;
    }
    .header__container h1 {
      flex-wrap: wrap;
    }
  }
`;

const TimelineMobile = ({
  suggestedTimeline,
  dispatch,
  authenticate,
  data,
}) => {
  const { user_payload, isAuthenticated } = authenticate;
  const [shineToggle, setShineToggle] = useState(suggestedTimeline.hasLiked);
  const [shineCount, setShineCount] = useState(suggestedTimeline.totalLikes);
  // const [commentCount] = useState(suggestedTimeline.totalComments);
  const [commentCount, setCommentCount] = useState(
    suggestedTimeline.totalComments
  );
  const { detailsTimeline } = useSelector((state) => state.single_timeline);

  let params = {
    shortFormat: true,
  };

  useEffect(() => {
    dispatch(modalActions.REMOVE_SELECTED_TIMELINE());
    dispatch(singleTimelineActions.REMOVE_SINGLETIMELINE());
    setShineToggle(suggestedTimeline.hasLiked);
    setShineCount(suggestedTimeline.totalLikes);
    setCommentCount(suggestedTimeline.totalComments);
  }, [suggestedTimeline]);

  useEffect(() => {
    const { id } = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (id) {
      dispatch(getSingleTimeline(id, null));

      dispatch(modalActions.ADD_SELECTED_TIMELINE(detailsTimeline));
    }
  }, [dispatch, shineToggle, setShineToggle, suggestedTimeline]);

  useEffect(() => {
    if (detailsTimeline) {
      if (suggestedTimeline.id === detailsTimeline.id) {
        const pl = {
          hasliked: detailsTimeline.hasLiked,
          totalLikes: detailsTimeline.totalLikes,
          totalComments: detailsTimeline.totalComments,
        };

        dispatch(modalActions.UPDATE_SELECTED_TIMELINE(pl));
        setShineToggle(detailsTimeline.hasLiked);
        setShineCount(detailsTimeline.totalLikes);
        setCommentCount(detailsTimeline.totalComments);
      }
    }
  }, [detailsTimeline]);

  const shineHandler = () => {
    if (!isAuthenticated) {
      const payload = {
        componentName: "Signup",
        isOpen: true,
        isClose: false,
        extras: {
          timeline: suggestedTimeline,
        },
      };
      dispatch(modalActions.MODAL_SAVE(payload));
    }

    if (isAuthenticated) {
      if (shineToggle) {
        const pl = {
          hasliked: false,
          totalLikes: shineCount - 1,
          totalComments: commentCount,
        };

        dispatch(modalActions.UPDATE_SELECTED_TIMELINE(pl));

        setShineToggle(false);
        setShineCount(shineCount - 1);
      } else {
        const pl = {
          hasliked: true,
          totalLikes: shineCount + 1,
          totalComments: commentCount,
        };
        dispatch(modalActions.UPDATE_SELECTED_TIMELINE(pl));

        setShineToggle(true);
        setShineCount(shineCount + 1);
      }
      const likeTimelinePayload = {
        userId: user_payload?.id,
        storyId: suggestedTimeline.id,
      };

      dispatch(likeTimeline(likeTimelinePayload));
    }
  };

  const timelineShortener = () => {
    return suggestedTimeline?.description?.length > 100
      ? suggestedTimeline?.description?.substring(0, 95) + "..."
      : suggestedTimeline?.description;
  };

  return (
    <TimelineMobileWrapper>
      <div className="timeline">
        <div className="timelineContent">
          <div className="header__container">
            <h1>{suggestedTimeline?.title}</h1>
            <div
              className="asset__wrapper"
              onClick={() => {
                const payload = {
                  componentName: "InappropriatePost",
                  isOpen: true,
                  isClose: false,
                };
                dispatch(modalActions.MODAL_SAVE(payload));
              }}
            >
              <EllipseSVG />
            </div>
          </div>
          <div className="timelineContentWrapper">
            <div style={{ height: 100, objectFit: "cover" }}>
              {suggestedTimeline?.media.length === 0 ? (
                <div></div>
              ) : (
                <div style={{ width: 100, height: 100 }}>
                  {suggestedTimeline?.media[0].type === "image" ? (
                    <img
                      src={suggestedTimeline?.media[0].url}
                      style={{ height: 100, objectFit: "cover" }}
                      alt=""
                    />
                  ) : (
                    <img src={C2H} alt="" />
                  )}
                </div>
              )}
            </div>
            <div className="contentContainer">
              <p>{timelineShortener()}</p>
            </div>
          </div>

          <div className="content__assets">
            <div className="content__asset__items">
              <div
                onClick={shineHandler}
                className={
                  shineToggle && isAuthenticated
                    ? `asset__wrapper active`
                    : `asset__wrapper`
                }
              >
                {shineToggle && isAuthenticated ? (
                  <img src={Icons.shineActive} alt="" />
                ) : (
                  <img src={Icons.shine} alt="" />
                )}
              </div>
              <NumericLabel params={params}>{shineCount}</NumericLabel>
            </div>
            <div
              className="content__asset__items"
              onClick={() => {
                const payload = {
                  componentName: "PostDetail",
                  isOpen: true,
                  isClose: false,
                  extras: {
                    timeline: suggestedTimeline,
                  },
                };
                dispatch(modalActions.MODAL_SAVE(payload));
                dispatch(modalActions.ADD_SELECTED_TIMELINE(suggestedTimeline));
              }}
            >
              <div className="asset__wrapper">
                <img src={comment} alt="" />
              </div>
              <NumericLabel params={params}>
                {suggestedTimeline.totalComments}
              </NumericLabel>
            </div>
            <div className="content__asset__items">
              <div
                onClick={() => {
                  const payload = {
                    componentName: "SocialShare",
                    isOpen: true,
                    isClose: false,
                    extras: {
                      timeline: data,
                    },
                  };
                  // dispatch(modalActions.ADD_SELECTED_TIMELINE(data));
                  dispatch(modalActions.MODAL_SAVE(payload));
                }}
                className="asset__wrapper"
              >
                <img src={download} alt="" />
              </div>
            </div>

            <div className="content__asset__items">
              <span>{suggestedTimeline?.createdBy}</span>
              <div className="asset__wrapper">
                <img src={suggestedTimeline?.user.profilePhoto} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </TimelineMobileWrapper>
  );
};

export default TimelineMobile;
