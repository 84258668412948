import React, { useContext } from "react";
import * as asset from "../../shared/header/header.module.css";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ExploreIcon,
  ExploreActiveIcon,
  ExploreMobile,
  ExploreActiveMobile,
  HomeActiveIconDesktop,
  HomeIconDesktop,
  HomeActiveIconMobile,
  ProfileIcon,
  ProfileActiveDesktop,
  ProfileActiveMobile,
  ProfileIconMobile,
  AddIcon,
  AddMobileIcon,
  NotificationIcon,
  NotificationMobileIcon,
} from "../../component/svgs/icons";

import ThemeContext from "../../context/themeContext";
import { FaChevronLeft } from "react-icons/fa";
import { signupActions } from "../../persistence/redux/login/signupSlice";
import { loginActions } from "../../persistence/redux/login/loginSlice";
import { modalActions } from "../../persistence/redux/modal/modalSlice";
import { getAuth, signOut } from "firebase/auth";
import { app } from "../../utils/firebase";
import { authActions } from "../../persistence/redux/auth/authSlice";
import { APP_ROUTES } from "../../constants/appConstants";

function BottomNavBar() {
  const dispatch = useDispatch();
  const { isDesktop } = useContext(ThemeContext);
  const history = useHistory();
  const { isAuthenticated } = useSelector((state) => state.auth);

  const handleSignupClick = () => {
    dispatch(signupActions.CLEAR_SIGNUP());

    const payload = {
      componentName: "Signup",
      isOpen: true,
      isClose: false,
    };
    dispatch(modalActions.MODAL_SAVE(payload));
  };
  const handleLogoutClick = () => {
    const _auth = getAuth(app);
    signOut(_auth)
      .then(() => {
        dispatch(loginActions.CLEAR_LOGIN());

        dispatch(signupActions.CLEAR_SIGNUP());

        dispatch(authActions.CLEAR_AUTH());
        history.replace(APP_ROUTES.ROOT);
      })
      .catch((error) => {
        alert("Couldn't log out!");
      });
  };
  return (
    <StyledBottomNavWrapper>
      <StyledNav>
        <ul>
          <li className={asset.nav__item}>
            <HomeLink history={history} to="/" />
          </li>
          <li className={asset.nav__item}>
            <ExploreLink to="/explore" history={history} />
          </li>
          <li className={asset.nav__item}>
            <AddAnchor
              onClick={() => {
                const payload = isAuthenticated
                  ? {
                      componentName: "Post",
                      isOpen: true,
                      isClose: false,
                    }
                  : {
                      componentName: "Signup",
                      isOpen: true,
                      isClose: false,
                    };

                dispatch(modalActions.MODAL_SAVE(payload));
              }}
            />
          </li>
          <li className={asset.nav__item}>
            {isAuthenticated && <ProfileLink to="/profile" history={history} />}
          </li>
          <li>
            <NotificationAnchor
              href="#"
              onClick={
                isDesktop
                  ? () => {
                      const payload = {
                        componentName: "NotificationPage",
                        isOpen: true,
                        isClose: false,
                      };
                      dispatch(modalActions.MODAL_SAVE(payload));
                    }
                  : () => {
                      const payload = {
                        componentName: "Notificationmenu",
                        isOpen: true,
                        isClose: false,
                      };
                      dispatch(modalActions.MODAL_SAVE(payload));
                    }
              }
            />
          </li>
        </ul>
      </StyledNav>
    </StyledBottomNavWrapper>
  );
}
const AddAnchor = ({ onClick }) => {
  const { isDesktop } = useContext(ThemeContext);
  return (
    <a href="#" onClick={onClick}>
      {isDesktop ? <AddIcon /> : <AddMobileIcon />}
    </a>
  );
};
const NotificationAnchor = ({ onClick }) => {
  const { isDesktop } = useContext(ThemeContext);
  const dispatch = useDispatch();
  return (
    <a href="#" onClick={onClick}>
      {isDesktop ? <NotificationIcon /> : <NotificationMobileIcon />}
    </a>
  );
};

const ExploreLink = ({ to, history }) => {
  const { isDesktop } = useContext(ThemeContext);
  if (isDesktop) {
    return (
      <Link to={to} href="#">
        {history.location.pathname === "/explore" ? (
          <ExploreActiveIcon />
        ) : (
          <ExploreIcon />
        )}
      </Link>
    );
  } else {
    return (
      <Link to={to} href="#">
        {history.location.pathname === "/explore" ? (
          <ExploreActiveMobile />
        ) : (
          <ExploreMobile />
        )}
      </Link>
    );
  }
};
const HomeLink = ({ to, history }) => {
  const { isDesktop } = useContext(ThemeContext);

  if (isDesktop) {
    return (
      <Link to={to}>
        {history.location.pathname === "/" ? (
          <HomeActiveIconDesktop />
        ) : (
          <HomeIconDesktop />
        )}
      </Link>
    );
  } else {
    return (
      <Link to={to}>
        {history.location.pathname === "/" ? (
          <HomeActiveIconMobile />
        ) : (
          <HomeActiveIconDesktop />
        )}
      </Link>
    );
  }
};
const ProfileLink = ({ to, history }) => {
  const { isDesktop } = useContext(ThemeContext);

  if (isDesktop) {
    return (
      <Link to={to}>
        {history.location.pathname === "/profile" ? (
          <ProfileActiveDesktop />
        ) : (
          <ProfileIcon />
        )}
      </Link>
    );
  } else {
    return (
      <Link to={to}>
        {history.location.pathname === "/profile" ? (
          <ProfileActiveMobile />
        ) : (
          <ProfileIconMobile />
        )}
      </Link>
    );
  }
};

const BackButton = ({ history }) => {
  return (
    history.location.pathname === "/profile/update" && (
      <StyledButtonWrapper onClick={history.goBack}>
        <LeftIcon />
        <span>Back</span>
      </StyledButtonWrapper>
    )
  );
};
const LeftIcon = styled(FaChevronLeft)``;
const StyledButtonWrapper = styled.button`
  display: flex;

  justify-content: center;
  align-items: center;
  list-style: none;
  width: 100px;
  height: 50px !important;
  background-color: transparent;
  border: none;
  align-items: center;
  cursor: pointer;
  align-self: flex-end;
  color: var(--color-secondary);
  position: absolute;
  top: 2%;
  left: 0;
  line-height: 2;

  span {
    margin-top: 2px;
    margin-left: 8px;
  }
`;

const StyledBottomNavWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 8px;
  z-index: 1;
`;
const StyledNav = styled.nav`
  justify-content: space-between;
  align-self: flex-end;
  /* background-color: blue; */
  position: absolute;
  z-index: 2;
  bottom: 5rem;
  width: 100%;
  /* height: 80%; */
  padding: 1rem 0;

  ul {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    width: 94%;
    margin: auto;
    height: 75px !important;
    /* background-color: var(--color-white); */
    /* background-color: red;           */
    background-color: var(--color-primary);
    border-radius: 5rem;
    align-items: center;

    li {
      &:nth-child(3) {
        a {
          background-color: var(--color-secondary);
          border-radius: 50%;
          transform: scale(1.1);
          width: 40px;
          height: 40px;

          img {
            width: 15px;
            height: 15px;
          }
        }
      }
      a {
        border-radius: 50%;
        width: 44px;
        height: 44px;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media screen and (min-width: 600px) {
    justify-content: space-between;
    align-self: flex-end;
    position: static;
    z-index: 2;
    padding: 0;

    ul {
      list-style: none;
      display: flex;
      justify-content: space-evenly;
      width: 260px;
      margin: auto;
      height: 60px !important;
      background-color: var(--color-white);
      border-radius: 5rem;
      align-items: center;

      li {
        &:nth-child(3) {
          a {
            background-color: var(--color-white);
            img {
            }
          }
        }
        a {
          img {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
`;

export default BottomNavBar;
